import React, { Component } from "react";

class HomeAppBottom extends Component {
  componentWillMount() {}
  render() {
    return (
      <div className={this.props.bgclass}>
        <section className="app_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="white_text head_h1">
                  There’s More to Love in the App
                </h2>
                <a
                  className=""
                  href="https://itunes.apple.com/in/app/whip-car-sharing/id1393803514?mt=8"
                  title=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/app_store.svg" alt="" />
                </a>
                <a
                  className=""
                  href="https://play.google.com/store/apps/details?id=com.carshare"
                  title=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/google_store.svg" alt="" />
                </a>
              </div>
              <div className="col-md-6 text-right">
                <img src="/images/app.svg" alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default HomeAppBottom;
