import React,{Component} from 'react';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import FAQ from '../blocks/FAQ';
import Title from '../templates/Title';
import HomeAppBottom from '../blocks/HomeAppBottom';
var Constants = require("../config/Constants");

class Faq extends Component{
	
	componentDidMount(){
		document.title = Constants.SITE_TITLE + " | Faq";
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="faq"/>
		)
	}	
	
	render(){
		return(
			<div className="faq_page">
				{this.renderMeta()}
				<Title title="Questions & Answers" />
				<FAQ />
				<HomeAppBottom bgclass="bg-white" />
			</div>
		)
	}
}
export default Faq;