import React, { Component } from "react";
import { Link } from "react-router-dom";
import DiaAxios from "../config/DiaAxios";
import { connect } from "react-redux";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import HomeAppBottom from "../blocks/HomeAppBottom";
import SimilarCars from "../blocks/SimilarCars";
import { FixedLoader, FullPageLoader } from "../blocks/Loaders";
import { getCarDetails, getPreAuthCarDetails } from "../services/CarService";
import { setCarDetails } from "../store/actions/CarAction";
import {
  showDrivingLicenseModal,
  toggleLoginModal,
} from "../store/actions/GlobalActions";
import { DrivingLicenseModal, LoginModal } from "../blocks/Modals";

var Constants = require("../config/Constants");

class CarDetails extends Component {
  state = {
    carid: this.props.params.id,
    car: {},
    media: {},
    loading: true,
    textloading: true,
    vehicleText: {},
    isUserLoggedIn: this.props.userDetails,
    userLoggedIn:this.props.userLoggedIn
  };

  componentDidMount() {
    this.getCar();
    this.getVehicleDetailText();
  }
  componentDidUpdate(nextProps, nextState) {
    window.scrollTo(0, 0);
    if (this.state.carid !== nextState.carid) {
      this.getCar();
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.params.id !== this.state.carid) {
      this.setState({
        carid: nextProps.params.id,
        car: {},
        media: {},
        loading: true,
        textloading: true,
        vehicleText: {},
      });
    }
  }

  closePage = () => {
    if (window.history.length > 0) return window.history.back();
    return (window.location.href = "/");
  };

  getCar = async () => {
    var self = this;
    // Get Cars
    const data = {
      list_id: this.state.carid,
      program: "rideshare",
      financing: "pto",
    };

    let res = null;
    try {
      if (this.state.isUserLoggedIn) res = await getCarDetails(data);
      else res = await getPreAuthCarDetails(data);
      if (res && res.data) {
        this.props.setCardDetailsToStore(res.data.result);
        self.setState({
          car: res.data.result,
          media: res.data.result.images,
          loading: false,
        });
        window.productPageSlider();
      } else {
        this.closePage();
      }
    } catch (err) {
      console.log(err);
      this.closePage();
    }
  };

  getVehicleDetailText = async () => {
    var self = this;
    // Get Cars detail page TEXT
    DiaAxios.post("getVehicleDetailText")
      .then(function(response) {
        if (response.data.status) {
          self.setState({
            vehicleText: response.data.result,
            textloading: false,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  renderSliderImages = (key) => {
    var self = this;
    if (!Object.keys(self.state.media).length || self.state.loading) {
      return (
        <div className="carousel-item">
          <img alt="" src="/images/plasma.jpg" />
        </div>
      );
    }
    return self.state.media.map((m, i) => {
      return (
        <li className={i === 0 ? "flex-active-slide" : ""} key={key + "-" + i}>
          <img src={m} alt={self.state.car.vehicle_name} />
        </li>
      );
    });
  };
  rentalTabDetail = () => {
    var self = this;
    if (
      self.state.loading ||
      !Object.keys(self.state.car.rental_details).length
    ) {
      return;
    }
    return self.state.car.rental_details.map((m, i) => {
      return (
        <li className="col-xl-12 col-sm-12 pull-left pl-1" key={"ren-" + i}>
          <span className="blue_text mb-0">{m}</span>
        </li>
      );
    });
  };
  vehicleDetailText = () => {
    var self = this;
    if (self.state.textloading || !Object.keys(self.state.vehicleText).length) {
      return <FullPageLoader />;
    }
    return self.state.vehicleText.map((m, i) => {
      return (
        <div className="col-sm-6" key={"det-" + i}>
          <div className="owner_box">
            <h5 className="blue_text head_h5">
              <img src={"/images/" + m.icon} alt="" />
              {m.title}
            </h5>
            <p>{m.content}</p>
          </div>
        </div>
      );
    });
  };
  equipmentTabDetail = () => {
    var self = this;
    if (
      self.state.loading ||
      (self.state.car && !Object.keys(self.state.car.equipment).length)
    ) {
      return;
    }
    return self.state.car.equipment.map((m, i) => {
      return (
        <li className="col-xl-4 col-sm-4 pull-left pl-1" key={"ren-" + i}>
          <span className="blue_text mb-0">{m}</span>
        </li>
      );
    });
  };
  renderMeta = () => {
    if (!Object.keys(this.state.car).length) return;

    var metas = {
      metatitle: this.state.car.vehicle_name,
    };
    return (
      <MetaTagsInfo metas={metas} pageTitle={this.state.car.vehicle_name} />
    );
  };
  formatPrice = (price) => {
    return (
      Constants.PREFIX_CURRENCY_SYMBLE +
      new Intl.NumberFormat("en-US", { syle: "decimal" }).format(price)
    );
  };
  formatNumber = (num) => {
    return new Intl.NumberFormat("en-US", { syle: "number" }).format(num);
  };
  getSimilarCars = () => {
    if (!Object.keys(this.state.car).length) return null;
    return <SimilarCars car={this.state.car} />;
  };

  goToBooking = () => {
    if (this.props.userLoggedIn) {
      if (
        !this.props.userDetails.is_driver ||
        this.props.userDetails.is_driver === "0"
      ) {
        this.props.openDrivingLicenseModal();
      } else window.location.href = "/booking";
    } else this.props.toggleLoginModal();
  };

  onPrequalifyResult = (message) => {
    if (message) window.location.href = "/booking";
  };

  render() {
    return (
      <div className="product-page">
        {this.renderMeta()}
        <section className="product_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 carousel">
                <div id="slider" className="flexslider">
                  <ul className="slides">{this.renderSliderImages("big")}</ul>
                </div>
                <div id="carousel" className="flexslider">
                  <ul className="slides">{this.renderSliderImages("thumb")}</ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="buy_car product_dtl">
                  <h2 className="black_text head_h2 m-0">
                    {this.state.car.make} {this.state.car.model}{" "}
                    {this.state.car.year}
                  </h2>
                  <p>{this.state.car.pickup_address}</p>
                  <div className="row">
                    <div className="col-6">
                      <div className="media">
                        <div className="media-left d-flex align-items-center justify-content-center">
                          <img src="/images/product_dtl.png" alt="" />
                        </div>
                        <div className="media-body">
                          <h5 className="head_h5">{this.state.car.distance_unit}(s)</h5>
                          <h5 className="blue_text head_h5 m-0">
                            {this.state.car.odometer}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <div className="media-left d-flex align-items-center justify-content-center">
                          <img src="/images/product_dtl2.png" alt="" />
                        </div>
                        <div className="media-body">
                          <h5 className="head_h5">Doors</h5>
                          <h5 className="blue_text head_h5 m-0">
                            {this.state.car.doors}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <div className="media-left d-flex align-items-center justify-content-center">
                          <img src="/images/product_dtl3.png" alt="" />
                        </div>
                        <div className="media-body">
                          <h5 className="head_h5">Gearbox</h5>
                          <h5 className="blue_text head_h5 m-0">
                            {this.state.car.transmition_type}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="media">
                        <div className="media-left d-flex align-items-center justify-content-center">
                          <img src="/images/product_dtl4.png" alt="" />
                        </div>
                        <div className="media-body">
                          <h5 className="head_h5">Engine Type</h5>
                          <h5 className="blue_text head_h5 m-0">
                            {this.state.car.engine}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="low_price">
                  <h5 className="head_h5 blue_text">Daily</h5>
                  <h4 className="pink_text head_h4 m-0">
                    As low as {this.state.car.fare_des}
                  </h4>
                </div>
                <button
                  type="submit"
                  className="btn pricing_btn"
                  onClick={this.goToBooking}
                >
                  Personalize Pricing
                </button>
              </div>
              <div className="col-sm-12 col-lg-4 product_dtl">
                <div className="row engine_row">
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Engine Type</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.engine}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">MPG</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.mpg_city} City/{this.state.car.mpg_hwy}{" "}
                        Hightway
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Exterior Color</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.color}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Interior Color</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.interior_color}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Transmission</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.transmition_type}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Door</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.doors}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">VIN</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.vin_no}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Stock#</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.stock_no}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-4 col-lg-6">
                    <div className="engine_type">
                      <div className="d-flex align-items-center">
                        <div className="engine_left">
                          <h5 className="head_h5 mb-0">Selling Price</h5>
                        </div>
                        <div className="engine_line" />
                      </div>
                      <h5 className="blue_text head_h5 mb-0">
                        {this.state.car.msrp}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ownerShip">
              <div className="row gap_14">{this.vehicleDetailText()}</div>
            </div>
            <div className="tab_box">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="quipment-tab"
                    data-toggle="tab"
                    href="#quipment"
                    role="tab"
                    aria-controls="quipment"
                    aria-selected="true"
                  >
                    Equipment
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="rentaldetail-tab"
                    data-toggle="tab"
                    href="#rentaldetail"
                    role="tab"
                    aria-controls="rentaldetail"
                    aria-selected="false"
                  >
                    Rental Details
                  </a>
                </li>
              </ul>
              <div className="tab-content product_dtl" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="quipment"
                  role="tabpanel"
                  aria-labelledby="quipment-tab"
                >
                  <div className="row gap_14 pl-lg-2 pr-lg-2">
                    <ul className="col-sm-12">{this.equipmentTabDetail()}</ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="rentaldetail"
                  role="tabpanel"
                  aria-labelledby="rentaldetail-tab"
                >
                  <div className="row gap_14 pl-lg-2 pr-lg-2">
                    <ul className="col-sm-12">{this.rentalTabDetail()}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {this.getSimilarCars()} */}
        {this.state.loading && <FixedLoader />}
        <HomeAppBottom bgclass="bg-white" />
        <LoginModal />
        <DrivingLicenseModal onResult={this.onPrequalifyResult} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userLoggedIn, userDetails } = state.auth;
  return { userLoggedIn, userDetails };
};

const mapDispatcherToProps = (dispatch) => {
  return {
    setCardDetailsToStore: (data) => dispatch(setCarDetails(data)),
    openDrivingLicenseModal: () => dispatch(showDrivingLicenseModal()),
    toggleLoginModal: () => dispatch(toggleLoginModal()),
  };
};

export default connect(mapStateToProps, mapDispatcherToProps)(CarDetails);
