import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginMobile from "../blocks/LoginBlocks/LoginMobile";
import LoginPassword from "../blocks/LoginBlocks/LoginPassword";
import LoginOTP from "../blocks/LoginBlocks/LoginOTP";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../store/actions/AuthAction";
import { ErrorDialog } from "../blocks/Dialogs";

const Login = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSuccess = (data) => {
    dispatch(loginSuccess(data.auth_token, data.result));
    if (window.history.length > 0) window.history.back();
    else window.location.href = "/";
  };

  const handleUserLogin = (next_step, user_id) => {
    setStep(next_step);
    if (user_id) {
      setUserId(user_id);
    }
  };

  const onError = (message) => {
    setErrorMessage(message);
    setError(true);
  };

  const onDialogClose = () => {
    setError(false);
    setErrorMessage("");
  };

  return (
    <section className="reg_sec container-fluid">
      <div className="row position-relative">
        <div className="col-lg-7 col-md-6 regLogo_box d-flex align-items-center justify-content-center">
          <Link className="reg_logo" to="/">
            <img src="/images/logo_white.png" alt="" />
          </Link>
          <div className="copyRight position-absolute">
            <p className="white_text">© Copyright All Rights Reserved</p>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 reg_form">
          {step === 1 && (
            <LoginMobile onSuccess={handleUserLogin} onError={onError} />
          )}
          {step === 2 && (
            <LoginPassword
              onSuccess={onSuccess}
              userId={userId}
              onError={onError}
            />
          )}
          {step === 3 && <LoginOTP onSuccess={onSuccess} onError={onError} />}
          
        </div>
      </div>
      {error && <ErrorDialog message={errorMessage} onClose={onDialogClose} />}
      
      
    </section>
  );
};

export default Login;
