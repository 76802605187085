import React, { Component } from "react";
import HomeAppBottom from "../blocks/HomeAppBottom";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import { Link } from "react-router-dom";
import '../evCars.css';
var Constants = require("../config/Constants");


class EvCars extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = Constants.DEFAULT_PAGE_TITLE;

    const script = document.createElement("script");
    script.src = "https://widgets.nrel.gov/afdc/station-locator/assets/embed.js";
    script.async = true;
    script.differ = true;
    document.body.appendChild(script);
    
    window.afdcStationsOptions = { "country": "all", "localeCountry": "US", "path": "/find/nearest", "query": { "fuel": ["ELEC"], "ev_levels": ["all"], "country": "US" } }
  }
  renderMeta = () => {
    var metas = {
      metatitle: Constants.DEFAULT_PAGE_TITLE,
      metadescription: "DriveItAway",
    };
    return (
      <MetaTagsInfo
        metas={metas}
        pageTitle={Constants.DEFAULT_PAGE_TITLE}
        action="home"
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderMeta()}
        <div className="Full-Page">
          <div className="Full-Width-Section-Premium">
              <div className="Intro-Full-Width-Container">
                  <div className="FullImage-BG" style={{background: "url(/images/DIA-IntroBG.jpeg)", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed"}}>
                      <div className="Intro-Full-Width-Container">
                          <div className="Image-Link-Section" >
                              <div className="custom-h2 text-center">
                                <strong>REASONS TO</strong>
                              </div>
                              <img style={{width: "100%", maxWidth: "500px"}}
                                  src="/images/Logo-DriveEV.png"
                                  alt="Drive EV" />
                              <div className="custom-h1 text-center" style={{marginTop: "10px", color: "#ec37a0"}}>
                                  <strong><em>with DriveItAway!</em></strong>
                              </div>
                              <ul className="custom-h5">
                                  <li>Subscribe to own your EV</li>
                                  <li>Save while you drive</li>
                                  <li>Fuel up with sustainable energy</li>
                                  <li>Build your down payment</li>
                                  <li>Qualify based on income - not credit</li>
                                  <li>Full warranty and maintenance included</li>
                              </ul>
                          </div>
                          <div className="Image-Text">
                              <img style={{width: "100%", maxWidth: "800px"}}
                                  src="/images/DIA-Intro-Vehicle_Cutout.png"
                                  alt="Drive EV" />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="Full-Width-Section">
                  <div className="Grey-BG">
                      <div className="TwoThirds-Width-Section">
                          <center>
                              <img style={{width: "100%", maxWidth: "125px"}}
                                  src="/images/DIA-icon-SustainableEnergy.png"
                                  alt="Sustainable Energy" />
                          </center>
                          <div className="custom-h2 text-center" >
                              <strong>Calculate Your Fuel Savings</strong>
                          </div>

                          <div className="custom-h4 text-center">
                              Driving an electric vehicle comes with many
                              financial benefits including big savings when it comes to daily fuel costs. Find out how much
                              you could save on gasoline costs when you upgrade to an EV by using this convenient Electric
                              Drive Cost Calculator.
                          </div>
                      </div>

                      <div className="Third-Width-Section">
                          <center>
                              <iframe src="https://afdc.energy.gov/widget/calc/Electric"
                                  style={{height:"372px", width: "250px", padding: "0", margin: "0", border: "1px solid #ec37a0"}}
                                  scrolling="no" frameBorder="0"></iframe>
                          </center>
                      </div>
                      <div style={{clear: "both"}}></div>
                  </div>
              </div>
          </div>

          <div className="Full-Width-Section" style={{marginTop: "25px"}}>
              <div className="Full-Width-Flex">
                  <div className="Half-Width-Section-2COl-Container">
                      <div className="Half-Width-Section-Image">
                          <center>
                              <img src="/images/DIA-1-Supercharged.jpeg"
                                  style={{width: "100%", maxWidth: "500px"}} alt="Get Supercharged"/>
                          </center>
                      </div>

                      <div className="Half-Width-Section-WhiteBG" style={{padding: "15px 20px"}}>
                          <div className="custom-h2 text-center"><em>Get <strong>Charged Up</strong></em></div>

                          <center>
                              <div className="Accent-Underline"></div>
                          </center>
                          <div className="custom-h5 text-center">Charging your vehicle is easy no matter where you
                              are.</div>

                          <center><a href="#ChargingMap" target="_blank" className="CTA-BTN-Large">Find a Charging Station</a>
                          </center>
                      </div>
                  </div>

                  <div style={{clear: "both"}}></div>
              </div>
          </div>

          <div className="Full-Width-Section" style={{marginTop: "25px"}}>
              <div className="Full-Width-Flex">
                  <div className="Half-Width-Section-2COl-Container">
                      <div className="Half-Width-Section-WhiteBG" style={{padding: "15px 20px"}}>
                          <div className="custom-h2 text-center"><em>The EV <strong>Advantage</strong></em></div>
                          <center>
                              <div className="Accent-Underline"></div>
                          </center>
                          <div className="custom-h5 text-center">Don’t make the same compromises as you would in a
                              gas powered vehicle. Discover the “EV edge” you’ll enjoy.</div>
                      </div>


                      <div className="Half-Width-Section-Image">
                          <center>
                              <img src="/images/DIA-2-SuperPerformance.jpeg"
                                  style={{width: "100%", maxWidth: "500px"}} alt="Super Performance" />
                          </center>
                      </div>
                  </div>

                  <div style={{clear: "both"}}></div>
              </div>
          </div>
          <div className="Full-Width-Section" style={{marginTop: "25px"}}>
              <div className="Full-Width-Flex">
                  <div className="Half-Width-Section-2COl-Container">
                      <div className="Half-Width-Section-Image">
                          <center>
                              <img src="/images/DIA-3-SafetyFirst.jpeg"
                                  style={{width: "100%", maxWidth: "500px"}} alt="Safety First" />
                          </center>
                      </div>

                      <div className="Half-Width-Section-WhiteBG" style={{padding: "15px 20px"}}>
                          <div className="custom-h2 text-center"><em>Safety <strong>First</strong></em></div>
                          <center>
                              <div className="Accent-Underline"></div>
                          </center>
                          <div className="custom-h5 text-center">No matter which brand you choose, drive in a
                              total awareness with a suite of next-gen driver assistance features.</div>
                      </div>
                  </div>

                  <div style={{clear: "both"}}></div>
              </div>
          </div>
          <div style={{clear: "both"}}></div>
          <div className="Intro-Full-Width-Container" style={{marginTop: "30px"}}>
              <div className="Grey-BG">

                  <div className="Half-Width-Section">
                      <div className="custom-h1 text-center">SUBSCRIBE &amp; DECIDE IN JUST 5 EASY STEPS!</div>

                      <div className="videoWrapper">
                          <iframe width="560" height="349"
                              src="https://cdn1-originals.webdamdb.com/14049_132843503?cache=1650045526&response-content-disposition=inline;filename=homepagevideo.mp4&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly9jZG4xLW9yaWdpbmFscy53ZWJkYW1kYi5jb20vMTQwNDlfMTMyODQzNTAzP2NhY2hlPTE2NTAwNDU1MjYmcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj1pbmxpbmU7ZmlsZW5hbWU9aG9tZXBhZ2V2aWRlby5tcDQiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjIxNDc0MTQ0MDB9fX1dfQ__&Signature=Aj0QdIG1tWEoSM8ZIdgA5a5J05U-QQnVvzd4U7X3qkpVNXC2JEcysEObjKEu2Imqg5Ex-cozFnOyiLamcN0aIOe9OGZipauwUQoA2o67Zj6uKL5IhxthlbwA3ilCcpjHKDJTqeZf~A2M4CCShRG5cuCXquGbOlbx7G551Jg11hFE6so~bMREFS~eVvpRihoJ6FlhTfrjrRB~PCwssWM~tBYnXX2UtIxQrqinp1RdI01bhkxxfaHoW8IchJMAxf0VttLcd2OSd6UcJU0c9eM-RYdrPmBax36q9tg-BtHYoYb-Ew3yjzWDDH7kgEoeOZVPeqFk1dwHXALnNlFvPY4I8w__&Key-Pair-Id=APKAI2ASI2IOLRFF2RHA"
                              frameBorder="0" allowFullScreen></iframe>
                      </div>
                  </div>


                  <div className="Half-Width-Section" style={{padding: "0px 0px 0px 25px"}}>

                      <div style={{float: "left", display: "block", width: "10%"}}>
                          <img src="/images/icon-Pink_1.png"
                              style={{width: "100%", maxWidth:"40px", marginRight: "10px"}} alt="Step 1"/>
                      </div>

                      <div style={{float: "left", display: "block", width: "90%"}}>
                          <div className="custom-h3 text-left"><strong>Subscribe</strong> to Own</div>
                          <p className="custom-h5">Start in a rental contract - with no obligation to own!</p>
                      </div>

                      <div style={{clear: "both"}}></div>

                      <div style={{float: "left", display: "block", width: "10%"}}>
                          <img src="/images/icon-Pink_2.png"
                              style={{width: "100%", maxWidth: "40px", marginRight: "10px"}} alt="Step 2" />
                      </div>

                      <div style={{float: "left", display: "block", width: "90%"}}>
                          <div className="custom-h3 text-left"><strong>Build a Down Payment</strong> While Driving
                          </div>
                          <p className="custom-h5">Each payment will go towards a down payment if you decide to keep the car.</p>
                      </div>
                      <div style={{clear: "both"}}></div>

                      <div style={{float: "left", display: "block", width: "10%"}}>
                          <img src="/images/icon-Pink_3.png"
                              style={{width: "100%", maxWidth:"40px", marginRight: "15px"}} alt="Step 3" />
                      </div>

                      <div style={{float: "left", display: "block", width: "90%"}}>
                          <div className="custom-h3 text-left"><strong>Receive Loan Offers</strong> Along the Way
                          </div>
                          <p className="custom-h5">Get offers from lenders to convert to an auto loan using what you've already
                              paid.</p>
                      </div>
                      <div style={{clear: "both"}}></div>
                      <div style={{float: "left", display: "block", width: "10%"}}>
                          <img src="/images/icon-Pink_4.png"
                               style={{width: "100%", maxWidth:"40px", marginRight: "15px"}} alt="Step 4" />
                      </div>

                      <div style={{float: "left", display: "block", width: "90%"}}>
                          <div className="custom-h3 text-left"><strong>Qualify Based on Income</strong> - NOT
                              Credit</div>
                          <p className="custom-h5">Drive based on your income and budget - NOT your credit!</p>
                      </div>
                      <div style={{clear: "both"}}></div>

                      <div style={{float: "left", display: "block", width: "10%"}}>
                          <img src="/images/icon-Pink_5.png"
                               style={{width: "100%", maxWidth:"40px", marginRight: "15px"}} alt="Step 5" />
                      </div>

                      <div style={{float: "left", display: "block", width: "90%"}}>
                          <div className="custom-h3"><strong>Full Warranty</strong> and Maintenance
                          </div>
                          <p className="custom-h5">All vehicles are fully covered while in the program.</p>
                      </div>
                      &nbsp;
                  </div>

              </div>
          </div>

          <center>
              <img style={{width: "100%", maxWidth: "200px", zIndex: "-1", opacity: "0.25", marginBottom: "-100px"}}
                  src="/images/DIA-TransparentPlug.png"
                  alt="Drive EV" />
          </center>
          <div className="custom-h1 text-center">Ready to Plug into a <span
                  className="p-text-bold">DriveItAway EV</span>?</div>
          <div className="Intro-Full-Width-Container"
              style={{background: "linear-gradient(135deg, #ffffff 80%, #eaeaea 20%)", zIndex: "1"}}>
              <div style={{clear: "both"}}></div>
              <div className="Half-Width-Section">

                  <div style={{float:"left", display:"block"}}>
                      <img src="/images/icon-Black_1.png"
                          style={{width:"100%", maxWidth: "40px", marginRight: "15px"}} alt="Step 1" />
                  </div>

                  <div style={{float: "left", display: "block"}}>
                      <div className="custom-h2" style={{textAlign: "left", color: "#ec37a0"}}><span className="p-text-bold">Pick Your
                              Vehicle</span></div>
                      <p className="p-text" style={{marginBottom: "30px"}}>Browse our super selection of EVs from your favorite
                          brands.</p>
                  </div>


                  <div style={{clear: "both"}}></div>


                  <div style={{float: "left", display: "block"}}>
                      <img src="/images/icon-Black_2.png"
                          style={{width: "100%", maxWidth:"40px", marginRight: "15px"}} alt="Step 2" />
                  </div>

                  <div style={{float: "left", display: "block"}}>
                      <div className="custom-h2" style={{textAlign: "left", color: "#ec37a0"}}>
                        <span className="p-text-bold">Speak with an EV Enthusiast</span>
                      </div>
                      <p className="p-text" style={{marginBottom: "30px"}}>Chat. Finance. Sign.</p>
                  </div>
                  <div style={{clear: "both"}}></div>

                  <div style={{float: "left", display: "block"}}>
                      <img src="/images/icon-Black_3.png"
                          style={{width: "100%", maxWidth: "40px", marginRight: "15px"}} alt="Step 3" />
                  </div>

                  <div style={{float: "left",display: "block"}}>
                      <div className="custom-h2" style={{textAlign: "left", color: "#ec37a0"}}>
                        <span className="p-text-bold">Drive Your EV</span>
                      </div>
                      <p className="p-text" style={{marginBottom: "40px"}}>Get charged up to start your electric adventure.</p>
                  </div>
                  <div style={{clear: "both"}}></div>

                  <center>
                    <Link to="/how-it-works" className="CTA-BTN-Large-Full">Learn More</Link>
                  </center>
              </div>

              <div className="Half-Width-Section">
                  <div className="Half-Width-Section">
                      <img src="/images/Outro-img-Short1.png"
                          style={{width: "100%", maxWidth: "500px"}} alt="Image 1" />
                  </div>
                  <div className="Half-Width-Section">
                      <img src="/images/Outro-img-Short2.png"
                          style={{width: "100%", maxWidth: "500px"}} alt="Image 2" />
                  </div>
                  <div className="Full-Width-Section p-1">
                      <img src="/images/Outro-img-Long3.png"
                          style={{width: "100%", maxWidth: "1000px"}} alt="Image 3" />
                  </div>

              </div>
          </div>

          <div id="ChargingMap"></div>
          <div className="Intro-Full-Width-Container" style={{marginTop: "30px"}}>
              <div className="Grey-BG w-100">
                  <div className="Full-Width-Section">
                      <div className="custom-h1 text-center">FIND A CHARGING STATION NEAR YOU</div>
                      <div id="afdc-stations">
                          <div id="afdc-stations-loading">Loading alternative fueling station locator...</div>
                      </div>
                      {/*}
                      <script type="text/javascript">
                            window.afdcStationsOptions = { "country": "all", "localeCountry": "US", "path": "/find/nearest", "query": { "fuel": ["ELEC"], "ev_levels": ["all"], "country": "US" } }</script>
                      <script async defer src="https://widgets.nrel.gov/afdc/station-locator/assets/embed.js"></script>
                      <noscript>Please enable JavaScript to view the alternative fueling station locator.</noscript>
                    */}
                  </div>

              </div>
          </div>
        </div>
        <HomeAppBottom bgclassName="bg-gray" />
      </div>
    );
  }
}
export default EvCars;
