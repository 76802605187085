import React from "react";
import { NavLink } from "react-router-dom";
import HomeAppBottom from "../blocks/HomeAppBottom";

function EmployeeBridge() {
  return (
    <div>
      <section className="landing_bnr">
        <div className="container">
          <div className="landBnr_logo text-sm-left text-center">
            <a href="javascript:;" title="">
              <img className="w-xs-75" src="/images/top-logo.png" alt="" />
            </a>
            {/*<span className="plus">
              <img src="/images/plus.png" alt="" />
            </span>
            <a href="javascript:;" title="">
              <img src="/images/EmployBridgeLogos.png" alt="" />
            </a>*/}
          </div>
          <div className="landBnr_txt text-sm-left text-center">
            {/*<h4 className="mt-4">Together</h4>*/}
            <h4 className="mt-4">Employee Vehicle Ownership Program</h4>
            <h1 className="head_h1">
              Easy & Transparent Rent To Own Experience
            </h1>
            <p>The Best Alternative to Renting a Vehicle</p>
            <NavLink to="/cars">
              <button type="button" className="btn pink_btn">
                Get Started
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      <section className="whyRent_sec">
        <div className="container">
          <div className="whyRent_txt text-sm-left text-center">
            <h2 className="blue_text head_h2">Why Just Rent?</h2>
            <p>When with the DriveItAway Employee Vehicle Ownership Program:</p>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon.png" alt="" />
                <p className="font_18 poppins_font">
                  Pick your dream car and each rental payment contributes to a
                  down payment to buy the car, regardless of credit score.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon2.png" alt="" />
                <p className="font_18">
                  Insurance & maintenance{" "}
                  <span className="d-block">all included during your</span>{" "}
                  rental to purchase period
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon3.png" alt="" />
                <p className="font_18">
                  One-on-one credit counseling and credit remediation from the
                  national organization, CredEvolv, is included while you drive
                  and build up down payment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hoWork_sec">
        <div className="container">
          <h2 className="blue_text head_h2 text-center">How it Works</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="hoWork_img">
                <img src="/images/work_img.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-sm-0 mb-5 mb-sm-0">
              <div className="hoWork_txt text-sm-left text-center">
                <h2 className="head_h2">No Commitment Pay As You Go</h2>
                <p>
                  Start in a rental contract with no commitment and as you drive
                  each payment contributes to a down payment.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-md-6">
              <div className="hoWork_img">
                <img src="/images/work_img2.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-sm-0 mb-5 mb-sm-0">
              <div className="hoWork_txt text-sm-left text-center">
                <h2 className="head_h2">Long - Short term Benefits </h2>
                <p>
                  Capture all of the benefits of rental, while building towards
                  potential ownership with each mile that you drive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="renting_sec renting_sec2 d-none d-sm-block">
        <div className="container">
          <div className="rent_div d-flex align-items-center">
            <div className="rent_list">
              <ul className="list-unstyled">
                <li>
                  <a href="javascript:;" title="">
                    No Commitment
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    No Down Payment
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    No Credit Needed
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    Return at Anytime
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    Lowest upfront cash outlay
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    Lowest long term cash outlay
                  </a>
                </li>
                <li>
                  <a href="javascript:;" title="">
                    Long term Value
                  </a>
                </li>
              </ul>
            </div>
            <div className="rentcheck_box">
              <div className="rent_box text-center">
                <h6 className="black_text">Renting</h6>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="rentcheck_box">
              <div className="rent_box text-center">
                <h6 className="black_text">DriveItAway</h6>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="rentcheck_box">
              <div className="rent_box text-center">
                <h6 className="black_text">Buying</h6>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="renting_sec renting_sec2 d-block d-sm-none">
        <div className="container">
          <div className="rent_div d-flex align-items-center">
            <div className="rentcheck_box">
              <div className="rent_box pr-3 pl-3 w-100 pull-left">
                <h4 className="text-center pull-left w-100">Renting</h4>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Commitment</span>{" "}
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Down Payment</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Credit Needed</span>{" "}
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Return at Anytime</span>{" "}
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Lowest upfront cash outlay</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">
                    Lowest long term cash outlay
                  </span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Long term Value</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="rentcheck_box">
              <div className="rent_box pr-3 pl-3 w-100 pull-left">
                <h4 className="text-center pull-left w-100">DriveItAway</h4>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Commitment</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Down Payment</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Credit Needed</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Return at Anytime</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Lowest upfront cash outlay</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">
                    Lowest long term cash outlay
                  </span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Long term Value</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="rentcheck_box">
              <div className="rent_box pr-3 pl-3 w-100 pull-left">
                <h4 className="text-center pull-left w-100">Buying</h4>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Commitment</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Down Payment</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">No Credit Needed</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Return at Anytime</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Lowest upfront cash outlay</span>
                  <i className="fa fa-times pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">
                    Lowest long term cash outlay
                  </span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
                <a href="javascript:;" title="" className="w-100 pull-left">
                  <span className="pull-left">Long term Value</span>
                  <i className="fa fa-check pull-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="howork_sec howork_sec2 position-relative">
        <div className="container position-relative">
          <div className="text-center">
            <h2 className="blue_text head_h2">Our Process</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="buy_car">
                <div className="media work_box d-block">
                  <div className="media-left white_text d-flex align-items-center justify-content-center">
                    1
                  </div>
                  <div className="media-body">
                    <p>
                      Browse the DriveItAway web or mobile app and find your
                      dream car
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="buy_car">
                <div className="media work_box d-block">
                  <div className="media-left white_text d-flex align-items-center justify-content-center">
                    2
                  </div>
                  <div className="media-body">
                    <p>
                      Build your program with your selected vehicle choosing
                      your upfront and monthly payment structure
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="buy_car">
                <div className="media work_box d-block">
                  <div className="media-left white_text d-flex align-items-center justify-content-center">
                    3
                  </div>
                  <div className="media-body">
                    <p>Book your vehicle </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="buy_car">
                <div className="media work_box d-block">
                  <div className="media-left white_text d-flex align-items-center justify-content-center">
                    4
                  </div>
                  <div className="media-body">
                    <p>
                      Prepay for usage and insurance where usage fees will build
                      a potential down payment as you drive
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="buy_car">
                <div className="media work_box d-block">
                  <div className="media-left white_text d-flex align-items-center justify-content-center">
                    5
                  </div>
                  <div className="media-body">
                    <p>
                      Receive offers from lenders along the way to convert to an
                      auto loan and commit to buying on YOUR TERMS
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="contact_box position-relative">
                <div className="position-relative">
                  <h2 className="blue_text head_h2">Contact Us</h2>
                  {/*<h3 className="font_22 black_text">
                    Special DriveItAway-Employbridge support
                  </h3>*/}
                  <p className="font_18 black_text">
                    Live Chat through the web or mobile app
                  </p>
                  <p className="font_18 black_text">
                    <span className="pink_text">Hotline -</span> 203-491-4283
                  </p>
                  <p className="font_18 black_text">
                    <span className="pink_text">Email -</span>{" "}
                    support@driveitaway.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <img src="/images/contact.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <HomeAppBottom bgclass="bg-gray" />
    </div>
  );
}

export default EmployeeBridge;
