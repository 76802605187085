import React, { Component } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
// import Page from "./templates/Page";
// import UserAccount from "./templates/UserAccount";
// import SimplePage from "./templates/SimplePage";
import { store, persistor } from "./store/store";
import { FullPageLoader } from "./blocks/Loaders";
import { GlobalErrorDialog } from "./blocks/Dialogs";
import { Toaster } from "react-hot-toast";
import IframePage from "./templates/IframePage";

//import PageNotFound from './pages/PageNotFound';
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div>
            <Toaster />
          </div>
          <Router basename={`${process.env.PUBLIC_URL}/`}>
            <Switch>
            {/* <Route exact path="/" render={() => <IframePage page="homepage" />} /> */}
            {/* <Route exact path="/" render={(props) => <IframePage page="cars" {...props} />} /> */}
            <Route
                exact
                path="/iframecars/:query?"
                render={(props) => <IframePage page="cars" {...props} />}
              />
              <Route
                exact
                path="/iframecars/details/:id/:name?"
                render={(props) => <IframePage page="cardetails" {...props} />}
              />

              {/* <Route exact path="/" render={() => <Page page="homepage" />} />
              <Route
                exact
                path="/drivers"
                render={() => <Page page="drivers" />}
              />
              <Route
                exact
                path="/login"
                render={() => <SimplePage page="login" />}
              />
              <Route
              exact
              path="/forgot-password"
              render={() => <SimplePage page="forgotpassword" />}
            />
              
              <Route
                exact
                path="/register"
                render={() => <SimplePage page="register" />}
              />
              <Route
                exact
                path="/dealers"
                render={() => <Page page="dealers" />}
              />
              <Route
                exact
                path="/employeevehicleprogram"
                render={() => <Page page="employeevehicleprogram" />}
              />
              <Route
                exact
                path="/program/usecase/rideshare"
                render={() => <Page page="rideshare" />}
              />
              <Route
                exact
                path="/program/usecase/regular-use"
                render={() => <Page page="regularuse" />}
              />
              <Route
                exact
                path="/how-it-works"
                render={() => <Page page="howitworks" />}
              />
              <Route
                exact
                path="/aboutus"
                render={() => <Page page="aboutus" />}
              />
              <Route
                exact
                path="/cars/:query?"
                render={(props) => <Page page="cars" {...props} />}
              />
              <Route
                exact
                path="/car/details/:id/:name?"
                render={(props) => <Page page="cardetails" {...props} />}
              />
              <Route
                exact
                path="/booking"
                render={() => <Page page="booking" />}
              />
              <Route
                exact
                path="/confirm-buy-reservation"
                render={() => <Page page="confirmbuyreservation" />}
              />
              <Route
                exact
                path="/confirm-reservation"
                render={() => <Page page="confirmreservation" />}
              />
              <Route exact path="/faq" render={() => <Page page="faq" />} />
              <Route
                exact
                path="/publications-blog-industry-videos"
                render={() => <Page page="publications" />}
              />
              <Route
                exact
                path="/contact-us"
                render={() => <Page page="contactus" />}
              />
              <Route
                exact
                path="/thank-you"
                render={() => <Page page="thankyou" />}
              />
              <Route
                exact
                path="/booking-success"
                render={() => <Page page="bookingsuccess" />}
              />
              <Route
                exact
                path="/account"
                render={() => <UserAccount page="editprofile" />}
              />
              <Route
                exact
                path="/account/edit-profile"
                render={() => <UserAccount page="editprofile" />}
              />
              <Route
                exact
                path="/account/upload-license"
                render={() => <UserAccount page="uploadlicense" />}
              />
              <Route
                exact
                path="/account/wishlist-vehicles"
                render={() => <UserAccount page="wishlistvehicles" />}
              />
              <Route
                exact
                path="/account/change-password"
                render={() => <UserAccount page="changepassword" />}
              />
              <Route
                exact
                path="/account/prequalify"
                render={() => <UserAccount page="prequalify" />}
              />
              <Route
                exact
                path="/account/bookings/:type?"
                render={() => <UserAccount page="bookings" />}
              />
              <Route
                exact
                path="/account/booking-detail/:id/:type"
                render={() => <UserAccount page="bookingdetail" />}
              />
              <Route
                exact
                path="/account/pending-booking-detail/:id"
                render={() => <UserAccount page="pendingbookingdetail" />}
              />
              <Route
                exact
                path="/account/myoffers"
                render={() => <UserAccount page="myoffers" />}
              />
              <Route
                exact
                path="/account/myoffers/confirm-booking"
                render={() => <UserAccount page="myofferconfirmbooking" />}
              />
              <Route
                exact
                path="/account/myoffers/:id"
                render={() => <UserAccount page="myofferdetails" />}
              />
              <Route
                exact
                path="/account/my-payments"
                render={() => <UserAccount page="mypayments" />}
              />
              <Route
                exact
                path="/account/payment-methods"
                render={() => <UserAccount page="paymentmethods" />}
              />
              <Route
                exact
                path="/account/invite-friends"
                render={() => <UserAccount page="invitefriends" />}
              />
              <Route
                exact
                path="/carite-downpayment-program"
                render={() => <Page page="carite" />}
              />
              <Route
                exact
                path="/investor-relations"
                render={() => <Page page="investors" />}
              />
              <Route
                exact
                path="/evprogram"
                render={() => <Page page="evprogram" />}
              /> */}
              <Redirect from="/" to="/iframecars/" />

              {/* <Redirect from="*" to="/" /> */}
            </Switch>
          </Router>
          <FullPageLoader />
          <GlobalErrorDialog />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
