import React,{Component} from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import Title from '../templates/Title';
//import {Link} from 'react-router-dom';
var Constants = require("../config/Constants");

class Investors extends Component{
	componentDidMount(){
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway : Investor Relations"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="dealers"/>
		)
	}	
	render(){
		return (
      <div>
        {this.renderMeta()}
        <Title title="Investor Relations" />
        <section className="product_sec">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12">
                <img src="/images/investor-banner.jpg" className="w-100" />
              </div>
            </div>

            <div className="row text-left">
              <ul className='list-unstyled'>
              <li className="mb-4">
                  <h6>05/17/2022 - NT 10-Q</h6>
                  <p>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112222000943/e3772_nt10q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                    Notification of inability to timely file Form 10-Q or 10-QSBOpen document
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>04/29/2022 - 8-K   </h6>
                  <p>
                    <a href="https://www.sec.gov/ix?doc=/Archives/edgar/data/1394638/000173112222000781/e3739_8-k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current reportOpen document
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>03/23/2022 - DEF 14C</h6>
                  <p>
                    <a href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112222000510/e3636_def14c.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Other definitive information statements
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    03/11/2022 - 8-K
                  </h6>
                  <p>
                    <a href="https://www.sec.gov/ix?doc=/Archives/edgar/data/0001394638/000173112222000494/e3630_8-k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    03/11/2022 - PRE 14C
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112222000416/e3596_pre-14c.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Other preliminary information statements
                    </a>
                  </p>
                </li>

                <li className="mb-4">
                  <h6>
                    03/02/2022 - 8-K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/ix?doc=/Archives/edgar/data/0001394638/000173112222000343/e3545_8-k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report
                    </a>
                  </p>
                </li>

                <li className="mb-4">
                  <h6>
                    02/28/2022 - SC 14F1
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112222000329/e3544_14f-1.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Statement regarding change in majority of directors
                      [Rule 14f-1]
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    02/22/2022 - 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/ix?doc=/Archives/edgar/data/0001394638/000173112222000295/e3523_10q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)]
                    </a>
                  </p>
                </li>

                <li className="mb-4">
                  <h6>
                    02/14/2022 - NT 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112222000240/e3512_nt10-q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Notification of inability to timely file Form 10-Q or
                      10-QSB
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    01/11/2022 - 10-K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/ix?doc=/Archives/edgar/data/0001394638/000173112222000038/e3409_10-k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Annual report [Section 13 and 15(d), not S-K Item 405]
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    12/30/2021 - NT 10-K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221002220/e3399_nt10-k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Notification of inability to timely file Form 10-K
                      405, 10-K, 10-KSB 405, 10-KSB, 10-KT, or 10-KT405
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    12/14/2021 - 8-K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221002131/e3359_8k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    08/16/2021 - 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/ix?doc=/Archives/edgar/data/0001394638/000173112221001363/e3023_10-q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)]
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    08/11/2021 - SC 13D/A
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000092189521001989/sc13da912696clcn_08112021.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      General statement of acquisition of beneficial
                      ownership - amendment
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    05/17/2021 - 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221000884/e2774_10-q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)]
                    </a>
                  </p>
                </li>

                <li className="mb-4">
                  <h6>
                    05/10/2021 - SC 13D/A
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000092189521001249/sc13da812696clcn_05102021.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      General statement of acquisition of beneficial
                      ownership - amendmentOpen document
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    3/25/2021 - 8-K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221000454/e2553_8k.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report
                    </a>
                  </p>
                </li>

                <li className="mb-4">
                  <h6>
                    3/17/2021 - NT 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221000401/e2503_10q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)]
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    2/16/2021 - NT 10-Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/0001394638/000173112221000244/e2463_nt10q.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Notification of inability to timely file Form 10-Q or
                      10-QSB
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    1/4/2021 - 4
                  </h6>
                  <p>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220001347/0001731122-20-001347-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-21-000002
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    12/30/2020 - 10K
                  </h6>
                  <p>
                    {" "}Annual Report -{" "}
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220001347/0001731122-20-001347-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-001347
                    </a>
                    <a
                      href="https://b4k-web-assets.s3.us-east-2.amazonaws.com/XBRL_12_2020.zip"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      XBRL
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    12/20/2020 - NT 10K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220001345/0001731122-20-001345-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-001345
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    10/13/2020 - 8K
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220001027/0001731122-20-001027-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-001027&nbsp;
                    </a>
                  </p>
                </li>
                <li className="mb-4">
                  <h6>
                    8/4/2020 - 10Q
                  </h6>
                  <p>
                    <a
                      href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000789/0001731122-20-000789-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000789
                    </a>
                  </p>
                </li>
              </ul>
              <ul className='list-unstyled'>
                <li className="mb-4">
                  <h6>6/5/2020 - 8K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000623/0001731122-20-000623-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000623
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>Annual Shareholders Meeting 5-19-2020</h6>
                  <span>
                    <a href="https://b4k-web-assets.s3.us-east-2.amazonaws.com/Shareholders+Annual+Meeting+Creative_34768.mp3"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Click here to download call
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>5/15/2020 - 10Q</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000516/0001731122-20-000516-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000516
                    </a>
                    <a href="https://b4k-web-assets.s3.us-east-2.amazonaws.com/xbrl+5_15.zip"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      XBRL
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>5/8/2020 - 8K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000516/0001731122-20-000516-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000452
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>4/30/2020 - 10Q</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000421/0001731122-20-000421-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000421
                    </a>{" "}
                    &nbsp;Size: 2 MB
                    <a href="https://b4k-web-assets.s3.us-east-2.amazonaws.com/XBRL-4-2020.zip"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      XBRL
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>4/07/2020 - DEF 14A</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000351/0001731122-20-000351-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000351
                    </a>
                    <a href="http://creativelearningcorporation.com/investor-relations"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>4/01/2020 - 8K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000340/0001731122-20-000340-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000340
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>1/27/2020 - 8K</h6>
                  <span>Current Report</span>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112220000071/0001731122-20-000071-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-20-000071
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>12/18/2019 - 10Q</h6>
                  <span>Quarterly report</span>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112219000788/0001731122-19-000788-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-19-000788
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>12/09/2019 - 8K</h6>
                  <span>
                    Current report, items 5.03 and 9.01
                    <br />
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112219000763/0001731122-19-000763-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-19-000763
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>12/09/2019 - 10Q</h6>
                  <span>
                    Quarterly report [Sections 13 or 15(d)]
                    <br />
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112219000761/0001731122-19-000761-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-19-000761
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>11/22/2019 - 10Q</h6>
                  <span>
                    Quarterly report [Sections 13 or 15(d)]
                    <br />
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112219000734/0001731122-19-000734-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Acc-no: 0001731122-19-000734
                    </a>
                  </span>
                </li>
              </ul>
              <ul className='list-unstyled'>
                <li className="mb-4">
                  <h6>10/4/18 - 8K/A</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173112218000100/0001731122-18-000100-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, items 4.01 and 9.01 Acc-no:
                      0001731122-18-000100&nbsp;(34 Act)
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>9/25/18 - 8K/A</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418009952/0001615774-18-009952-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, item 4.01 Acc-no: 0001615774-18-009952
                      (34 Act) &nbsp;&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>9/20/18 - 8K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418009764/0001615774-18-009764-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Securities to be offered to employees in employee
                      benefit plans Acc-no: 0001615774-18-008311&nbsp;(33
                      Act)
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>8/16/18 - 8K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418008194/0001615774-18-008194-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, item 5.02 Acc-no:
                      0001615774-18-008194&nbsp;(34 Act
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>8/13/18 - 10-Q</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418007880/0001615774-18-007880-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)] Acc-no:
                      0001615774-18-007880 (34 Act&nbsp;)
                    </a>
                  </span>
                  <span>XBRL</span>
                </li>
                <li className="mb-4">
                  <h6>5/14/18 - 10-Q</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418003733/0001615774-18-003733-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Quarterly report [Sections 13 or 15(d)] Acc-no:
                      0001615774-18-003733&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                </li>
              </ul>
              <ul className='list-unstyled'>
                <li className="mb-4">
                  <h6>5/10/18 - 4/A</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173559818000004/0001735598-18-000004-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <strong>[Amend]</strong>
                      Statement of changes in beneficial ownership of
                      securities Acc-no: 0001735598-18-000004&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>5/8/18 - 4</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000173559818000003/0001735598-18-000003-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Statement of changes in beneficial ownership of
                      securities Acc-no: 0001735598-18-000003 Size: 4 KB
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>3/23/18 - 4</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000092189518001067/0000921895-18-001067-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Statement of changes in beneficial ownership of
                      securities Acc-no: 0000921895-18-001067&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>2/15/18 - 10-Q</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418001244/0001615774-18-001244-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Notification of inability to timely file Form 10-Q or
                      10-QSB Acc-no: 0001615774-18-001244&nbsp;(34
                      Act)&nbsp;
                    </a>
                  </span>
                  <span>
                    <a href="https://s3.us-east-2.amazonaws.com/b4k-website-assets/xbrl-2-15.zip"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      XBRL&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>1/4/18 - 10-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418000094/0001615774-18-000094-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Annual report [Section 13 and 15(d), not S-K Item 405]
                      Acc-no: 0001615774-18-000094&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                  <span>
                    <a href="https://b4k-web-assets.s3.us-east-2.amazonaws.com/XBRL_2018-01-04.zip"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      XBRL
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>1/2/18 - NT 10-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577418000009/0001615774-18-000009-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Notification of inability to timely file Form 10-K
                      405, 10-K, 10-KSB 405, 10-KSB, 10-KT, or 10-KT405
                      Acc-no: 0001615774-18-000009 (34 Act) &nbsp;&nbsp;
                    </a>
                  </span>
                </li>
              </ul>
              <ul className='list-unstyled'>
                <li className="mb-4">
                  <h6>12/19/17 - 3</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577417007455/0001615774-17-007455-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Initial statement of beneficial ownership of
                      securities Acc-no: 0001615774-17-007455&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>11/2/17 - 3</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000092189517002528/0000921895-17-002528-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Initial statement of beneficial ownership of
                      securities Acc-no: 0000921895-17-002528&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>9/22/17 - 8-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577417005284/0001615774-17-005284-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, item 8.01 Acc-no:
                      0001615774-17-005284&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>9/18/17 - 8-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577417005143/0001615774-17-005143-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, item 5.02 Acc-no:
                      0001615774-17-005143&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>9/1/17 - 8-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577417004897/0001615774-17-004897-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, item 8.01 Acc-no:
                      0001615774-17-004897&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                </li>
                <li className="mb-4">
                  <h6>8/30/17 - 8-K</h6>
                  <span>
                    <a href="https://www.sec.gov/Archives/edgar/data/1394638/000161577417004870/0001615774-17-004870-index.htm"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Current report, items 2.02, 5.02, and 9.01 Acc-no:
                      0001615774-17-004870&nbsp;(34 Act)&nbsp;&nbsp;
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="container conatiner-bg-gray pb-5 pt-5">
            <div className="row">
				<div className="col-md-12 text-center">
              		<h5>Committee Charters</h5>
			  	</div>
			</div>
			<div className="row ">
              	<div className="col-sm-6 mt-4">
				  	<p>Audit Committee Charter</p>
                	<span>
						<a
						href="https://s3.us-east-2.amazonaws.com/b4k-website-assets/clc-l-audit-committee-charter.pdf"
						rel="noopener noreferrer"
						target="_blank"
						>Download</a>
                	</span>
              </div>
              <div className="col-sm-6 mt-4">
                <p>Compensation Committee Charter</p>
                <span>
                  <a
                    href="https://s3.us-east-2.amazonaws.com/b4k-website-assets/clc-l-compensation-committee-charter.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download
                  </a>
                </span>
              </div>
              <div className="col-sm-6 mt-4">
                <p>Executive Committee Charter</p>
                <span>
                  <a
                    href="https://s3.us-east-2.amazonaws.com/b4k-website-assets/clc-executive-committee-charter.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download
                  </a>
                </span>
              </div>
            </div>
		</div>
		<div className="container conatiner-bg-gray mt-5 mb-5 pb-4 pt-4">
            <div className="row">
              <div className="col-sm-6 text-left">
                <a
                  rel="noopener noreferrer"
                  role="link"
                  target="_blank"
                  href="https://img1.wsimg.com/blobby/go/7232034c-9c66-4eee-87f7-a588e37795db/downloads/CODE%20OF%20ETHICS.pdf?ver=1615473645315"
                >
                  CODE OF ETHICS (pdf)
                </a>
              </div>
              <div className="col-sm-6 text-right">
                <a
                  rel="noopener noreferrer"
                  role="link"
                  target="_blank"
                  href="https://img1.wsimg.com/blobby/go/7232034c-9c66-4eee-87f7-a588e37795db/downloads/CODE%20OF%20ETHICS.pdf?ver=1615473645315"
                >
                  <span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="1em"
                      height="1em"
                      data-ux="Icon"
                      data-aid="download"
                    >
                      <path
                        fillRule="evenodd"
                        d="M19.24 19.992H4.75a.75.75 0 0 1 0-1.5h14.49a.75.75 0 1 1 0 1.5zM15.839 9.569a.75.75 0 1 1 1.06 1.061l-4.89 4.89-4.921-4.89a.751.751 0 0 1 1.058-1.064l3.097 3.079V4.75a.75.75 0 1 1 1.5 0v7.914l3.096-3.095z"
                      />
                    </svg>
                    Download
                  </span>
                </a>
              </div>
            </div>
        </div>
        </section>
        <HomeAppBottom bgclass="bg-gray" />
      </div>
    );
	}
}
 export default Investors;
