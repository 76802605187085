import React from "react";
const HowWeDrive = () => {
  return (
    <div
      className="wp-container-16 wp-block-group alignfull bg-pattern-tl-dots bg-pattern-br-dots py-5 py-lg-6 has-white-color has-secondary-background-color has-text-color has-background"
      id="content"
    >
      <div className="bg-pattern-br-dots__inner" />
      <div className="bg-pattern-tl-dots__inner" />
      <div className="wp-block-group__inner-container container-lg">
        <div className="wp-container-15 wp-block-columns gap-1 howitworks-table row g-0 break-lg">
          <div className="wp-container-11 wp-block-column" style={{flexBasis:"30%"}}>
            <h2 className="lh-1 mb-0 px-0 pb-3 py-md-0 d-flex align-items-center h-md-7  text-white">
              How We Drive the Competition Away…
            </h2>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-light-background-color has-text-color has-background">
              No Commitment
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-medium-background-color has-text-color has-background">
              No Down Payment
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-light-background-color has-text-color has-background">
              Any Credit
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-medium-background-color has-text-color has-background">
              Return Anytime
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-light-background-color has-text-color has-background">
              Lower Cash Upfront
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-medium-background-color has-text-color has-background">
              Lower Long Term Cash Outlay
            </p>

            <p className="d-none d-lg-flex p-0 px-3 fs-6 fs-lg-5 mb-0 align-items-center h-4 has-secondary-color has-light-background-color has-text-color has-background">
              Long Term Value
            </p>
          </div>

          <div className="wp-container-12 wp-block-column">
            <h2 className="has-text-align-center mb-0 px-0 py-4 py-lg-0 d-flex align-items-center justify-content-center h-lg-7 has-primary-background-color has-background text-white">
              <i className="fak fa-xs fa-driveitaway-d" aria-hidden="true" />
              <strong>
                <em>riveIt</em>
              </strong>
              Away
            </h2>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Commitment</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Down Payment</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Any Credit</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Return Anytime</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Lower Cash Upfront</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">
                Lower Long Term Cash Outlay
              </span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Long Term Value</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>
          </div>

          <div className="wp-container-13 wp-block-column">
            <h2 className="has-text-align-center mb-0 px-0 py-4 py-lg-0 d-flex align-items-center justify-content-center h-lg-7 has-dark-background-color has-background text-white">
              Buying
            </h2>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Commitment</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Down Payment</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Any Credit</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Return Anytime</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Lower Cash Upfront</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">
                Lower Long Term Cash Outlay
              </span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Long Term Value</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>
          </div>

          <div className="wp-container-14 wp-block-column">
            <h2 className="has-text-align-center mb-0 px-0 py-4 py-lg-0 d-flex align-items-center justify-content-center h-lg-7 has-dark-background-color has-background text-white">
              Renting
            </h2>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Commitment</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">No Down Payment</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Any Credit</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Return Anytime</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Lower Cash Upfront</span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-medium-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">
                Lower Long Term Cash Outlay
              </span>
              <i className="fas fa-circle-check" aria-hidden="true" />
            </p>

            <p className="has-text-align-center py-0 px-4 px-lg-0 fs-2 mb-0 d-flex align-items-center justify-content-between justify-content-lg-center h-3 h-lg-4 has-secondary-color has-light-background-color has-text-color has-background">
              <span className="label d-lg-none fs-6">Long Term Value</span>
              <i className="fas fa-circle-xmark" aria-hidden="true" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowWeDrive;
