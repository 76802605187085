import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { Link } from "react-router-dom";

var Constants = require("../config/Constants");

class SimilarCars extends Component {
  state = { loading: true, cars: [], car: this.props.car };

  componentDidMount() {
    this.getCars();
  }

  componentDidUpdate(prevProps, prevState) {
    window.initSimilarProductsCarousal();
  }
  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ car: nextProps.car });
    this.getCars();
  }
  formatPrice = (price) => {
    return (
      Constants.PREFIX_CURRENCY_SYMBLE +
      new Intl.NumberFormat("en-US", { syle: "decimal" }).format(price)
    );
  };
  getCars = async () => {
    var self = this;
    var car = self.state.car;
    // Get Cars
    DiaAxios.post("similarVehicles", {
      make: car.make,
      model: car.model,
      lat: car.lat,
      lng: car.lng,
      year: car.year,
      id: car.id,
    })
      .then(function(response) {
        if (response.data.status) {
          self.setState({
            cars: response.data.result,
            loading: false,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  chunkArray = (myArray, chunk_size) => {
    let results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  };

  processCarChunks = (obj) => {
    return (
      <div className="car_box">
        <div className="car_img text-center position-relative">
          <Link
            to={
              "/car/details/" +
              obj.id +
              "/" +
              obj.vehicle_name
                .split(" ")
                .join("-")
                .toLowerCase()
            }
          >
            <img src={obj.image} alt="" />
          </Link>
          <Link
            className="heart position-absolute d-flex align-items-center justify-content-center"
            to="#"
            title=""
          >
            <i className="fa fa-heart" aria-hidden="true" />
          </Link>
        </div>
        <div className="car_list">
          <span className="pull-left">
            {obj.model} {obj.year}
          </span>
          <span className="pull-right">
            <img
              src="/images/miles.png"
              alt=""
              className="milesicon pull-left"
            />{" "}
            {obj.odometer} Miles
          </span>
          <br />
          <h4 className="mt-2">{obj.make}</h4>
        </div>
        <div className="car_price d-flex justify-content-between">
          <div>
            <h5>{obj.rent}</h5>
            <span>{obj.rent_label}</span>
          </div>
          <div>
            <Link
              className="btn"
              to={
                "/car/details/" +
                obj.id +
                "/" +
                obj.vehicle_name
                  .split(" ")
                  .join("-")
                  .toLowerCase()
              }
            >
              {this.formatPrice(obj.msrp)}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  renderCars = () => {
    if (this.state.loading)
      return (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="loader">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    if (!Object.keys(this.state.cars).length) return null;
    return this.state.cars.map((obj, index) => {
      return (
        <div className="citem cars_div" key={"testi-" + index}>
          {this.processCarChunks(obj)}
        </div>
      );
    }, this);
  };

  render() {
    return (
      <section id="demos" className="owl_slider">
        <div className="container">
          <h2 className="head_h2 text-center white_text">Similar Cars</h2>
          <div className="row">
            <div className="large-12 columns">
              <div id="similarCarsCarousel" className="owl-carousel owl-theme">
                {this.renderCars()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SimilarCars;
