import React, { Component } from "react";

class BuyingCarLeft extends Component {
  state = {
    showPopup: false,
  };
  componentWillMount() {}
  closePopup = () => {
    this.setState({
      showPopup: false,
    });
  };
  showPopup = () => {
    this.setState({
      showPopup: true,
    });
  };
  render() {
    return (
      <div className="col-md-6 text-left alignitems-center mb-5">
        
        {this.state.showPopup ? (
          <div className="video_div iframe-loaded">
            <video width="100%" height="100%" autoPlay={true} controls={true}>
              <source src="/images/homepagevideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <div className="video_div">
            <a
              className="mt-5 video-thumb"
              onClick={this.showPopup.bind(this)}
              title=""
            >
              <img src="/images/video1.png" alt="" />
            </a>
          </div>
        )}
      </div>
    );
  }
}
export default BuyingCarLeft;
