import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOutUser } from "../store/actions/AuthAction";
import { ConfirmDialog } from "../blocks/Dialogs";

const EmployeeHead = ({ isSticky }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => {
    return {
      user: state.auth,
    };
  });

  const [showLogoutConfirmDialog, setShowLogoutConfirmDialog] = useState(false);

  const getUserNameLetters = () => {
    if (user.userDetails) {
      return user.userDetails.first_name[0] + user.userDetails.last_name[0];
    } else return "";
  };

  const getUserName = () => {
    return user.userDetails.first_name + " " + user.userDetails.last_name;
  };

  const getWalletBalance = () => {
    return "$" + user.userDetails.wallet_balance;
  };
  const logoutUser = async () => {
    dispatch(signOutUser());
  };

  return (
    <header className={`header landing_header ${isSticky && "sticky-header"}`}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <NavLink to="/" className="navbar-brand">
            <img src="/images/top-logo.png" alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto justify-content-center">
              <li className="nav-item">
                <NavLink
                  to="/drivers"
                  className="nav-link"
                  title=""
                  activeClassName="active"
                >
                  Drivers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/dealers" className="nav-link" title="">
                  Dealers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/evprogram" className="" title="">
                  Electric Vehicles
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  id="navbarDropdown"
                  to="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Program
                </NavLink>
                
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="nav-item">
                    <NavLink
                      to="/how-it-works"
                      className="nav-link"
                      title=""
                      activeClassName="sub-active"
                    >
                      How it Works
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown-submenu">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      data-toggle="subdropdown"
                      to="#"
                      id="navbarSubDropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Use Cases
                    </NavLink>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarSubDropdown"
                    >
                      <li className="nav-item">
                        <NavLink
                          to="/program/usecase/rideshare"
                          className="nav-link"
                          title=""
                          activeClassName="sub-active"
                        >
                          Rideshare/delivery
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/program/usecase/regular-use"
                          className="nav-link"
                          title=""
                          activeClassName="sub-active"
                        >
                          Regular use
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/faq"
                      className="nav-link"
                      title=""
                      activeClassName="sub-active"
                    >
                      FAQ
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/employeevehicleprogram"
                      className="nav-link"
                      title=""
                      activeClassName="sub-active"
                    >
                      Employee Program
                    </NavLink>
                  </li>
                  {/*<li className="nav-item">
                    <NavLink
                      to="/evprogram"
                      className="nav-link"
                      title=""
                      activeClassName="sub-active"
                    >
                      Electric Vehicles
                    </NavLink>
                  </li>*/}
                </ul>
              </li>
              
              <li className="nav-item">
                <NavLink to="/contact-us" className="nav-link" title="">
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/investor-relations" className="nav-link" title="">
                  Investor Relations
                </NavLink>
              </li>
              {user.userLoggedIn && (
                <>
                  <li className="nav-item notification">
                    <NavLink
                      className="nav-link position-relative"
                      to="/account/wishlist-vehicles"
                      title=""
                    >
                      <i className="fa fa-heart" aria-hidden="true" />
                      {/* <span className="d-flex align-items-center justify-content-center">
                        1
                      </span> */}
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown user_dropdown">
                    <NavLink
                      className="nav-link position-relative dropdown-toggle d-flex align-items-center"
                      to="#"
                      title="App Store"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="user_img d-flex align-items-center justify-content-center">
                        {getUserNameLetters()}
                      </span>
                      {/* <img
                        src={user.userDetails.photo}
                        alt=""
                        className="user-avatar mr-2"
                      />{" "} */}
                      <span>{getUserName()}</span>
                    </NavLink>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="userDropdown"
                    >
                      <NavLink
                        className="dropdown-item"
                        to="/account"
                        title="My Profile"
                      >
                        My Profile
                      </NavLink>
                      <span className="dropdown-item" title="Wallet">
                        Wallet: <b>{getWalletBalance()}</b>
                      </span>
                      <NavLink
                        className="dropdown-item"
                        to="/account/bookings"
                        title="My Bookings"
                      >
                        My Bookings
                      </NavLink>
                      <NavLink
                        className="dropdown-item"
                        to="/account/myoffers"
                        title="My Offers"
                      >
                        My Offers
                      </NavLink>
                      <NavLink
                        className="dropdown-item"
                        to="/account/prequalify"
                        title="Prequalify"
                      >
                        Prequalify
                      </NavLink>
                      <button
                        className="dropdown-item"
                        title="Logout"
                        onClick={logoutUser}
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </>
              )}
            </ul>
            {!user.userLoggedIn && (
              <>
                <NavLink to="/login">
                  <button type="button" className="btn outline_btn">
                    LOG IN
                  </button>
                </NavLink>
              </>
            )}
            <NavLink to="/cars" className="btn outline_btn" title="">
              GET STARTED
            </NavLink>
          </div>
        </div>
      </nav>
      {showLogoutConfirmDialog && (
        <ConfirmDialog
          message={"Dou you want to logout ?"}
          onAccept={logoutUser}
          onCancel={() => setShowLogoutConfirmDialog(false)}
        />
      )}
    </header>
  );
};

export default EmployeeHead;
