import React, { useEffect, useCallback, useRef, useState } from "react";
import Header from "./Header";
import EmployeeHead from "./EmployeeHead";
import Footer from "./Footer";
import Home from "../pages/Home";
import Drivers from "../pages/Drivers";
import Dealers from "../pages/Dealers";
import Rideshare from "../pages/Rideshare";
import Regularuse from "../pages/Regularuse";
import HowItWorks from "../pages/HowItWorks";
import AboutUs from "../pages/AboutUs";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Faq from "../pages/Faq";
import Login from "../pages/Login";
import Publications from "../pages/Publications";
import Contact from "../pages/Contact";
import ThankYou from "../pages/ThankYou";
import Booking from "../pages/Booking";
import ConfirmBuyReservation from "../pages/ConfirmBuyReservation";
import ConfirmReservation from "../pages/ConfirmReservation";
import BookingSuccess from "../pages/BookingSuccess";
import EmployeeBridge from "../pages/EmployeeBridge";
import Carite from "../pages/Dealers/Carite";
import Investors from "../pages/Investors";
import EvCars from "../pages/EvCars";

function Page(props) {
  const getHead =()=>{
    switch (props.page) {
      case "employeebridge":
        return <EmployeeHead isSticky={isSticky}/>;
      default:
        return <Header isSticky={isSticky}/>;
    } 
  }

  const getPage = () => {
    switch (props.page) {
      case "homepage":
        return <Home />;
      case "login":
        return <Login />;
      case "drivers":
        return <Drivers />;
      case "dealers":
        return <Dealers />;
      case "rideshare":
        return <Rideshare />;
      case "regularuse":
        return <Regularuse />;
      case "howitworks":
        return <HowItWorks />;
      case "aboutus":
        return <AboutUs />;
      case "cars":
        return <CarListing params={props.match.params} />;
      case "cardetails":
        return <CarDetails params={props.match.params} />;
      case "booking":
        return <Booking />;
      case "confirmbuyreservation":
        return <ConfirmBuyReservation />;
      case "confirmreservation":
        return <ConfirmReservation />;
      case "faq":
        return <Faq />;
      case "publications":
        return <Publications />;
      case "contactus":
        return <Contact />;
      case "thankyou":
        return <ThankYou />;
      case "bookingsuccess":
        return <BookingSuccess />;
      case "employeevehicleprogram":
        return <EmployeeBridge />;
      case "carite":
        return <Carite />;
      case "investors":
        return <Investors />;
      case "evprogram":
        return <EvCars />;
      default:
        return <Home />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.darkHeader(false);
  }, []);

  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const toggleSticky = useCallback(
    ({ top, bottom }) => {
      if (top <= -80) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }
    },
    [isSticky]
  );

  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(headerRef.current.getBoundingClientRect());
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);

  return (
    
    <div>
      {getHead()}
      <div ref={headerRef}>
        {getPage()}
      </div>
      <Footer />
    </div>
  );
}
export default Page;
