import React, { Component } from "react";
import { Link } from "react-router-dom";
import DiaAxios from "../config/DiaAxios";
import { connect } from "react-redux";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import DriveItWantIt from "../blocks/DriveItWantIt";
import DriveItWantItPackage from "../blocks/DriveItWantItPackage";
import HowWeDrive from "../blocks/HowWeDrive";
import { FixedLoader, FullPageLoader } from "../blocks/Loaders";
import { getCarDetails, getPreAuthCarDetails } from "../services/CarService";
import { setCarDetails } from "../store/actions/CarAction";
import {showDownloadAppModal} from "../store/actions/GlobalActions";

var Constants = require("../config/Constants");

class IframeCarDetails extends Component {
  state = {
    carid: this.props.params.id,
    car: {},
    media: {},
    loading: true,
    textloading: true,
    vehicleText: {}
  };

  componentDidMount() {
    this.getCar();
    this.getVehicleDetailText();
  }
  componentDidUpdate(nextProps, nextState) {
    window.scrollTo(0, 0);
    if (this.state.carid !== nextState.carid) {
      this.getCar();
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.params.id !== this.state.carid) {
      this.setState({
        carid: nextProps.params.id,
        car: {},
        media: {},
        loading: true,
        textloading: true,
        vehicleText: {},
      });
    }
  }

  closePage = () => {
    if (window.history.length > 0) return window.history.back();
    return (window.location.href = "/");
  };

  getCar = async () => {
    var self = this;
    // Get Cars
    const data = {
      list_id: this.state.carid,
      program: "rideshare",
      financing: "pto",
    };

    let res = null;
    try {
      if (this.state.isUserLoggedIn) res = await getCarDetails(data);
      else res = await getPreAuthCarDetails(data);
      if (res && res.data) {
        this.props.setCardDetailsToStore(res.data.result);
        self.setState({
          car: res.data.result,
          media: res.data.result.images,
          loading: false,
        });
        window.productPageSlider();
      } else {
        this.closePage();
      }
    } catch (err) {
      console.log(err);
      this.closePage();
    }
  };

  getVehicleDetailText = async () => {
    var self = this;
    // Get Cars detail page TEXT
    DiaAxios.post("getVehicleDetailText")
      .then(function(response) {
        if (response.data.status) {
          self.setState({
            vehicleText: response.data.result,
            textloading: false,
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  renderSliderImages = (key) => {
    var self = this;
    if (!Object.keys(self.state.media).length || self.state.loading) {
      return (
        <div className="carousel-item">
          <img alt="" src="/images/plasma.jpg" />
        </div>
      );
    }
    return self.state.media.map((m, i) => {
      return (
        <li className={i === 0 ? "flex-active-slide" : ""} key={key + "-" + i}>
          <img src={m} alt={self.state.car.vehicle_name} />
        </li>
      );
    });
  };
  rentalTabDetail = () => {
    var self = this;
    if (
      self.state.loading ||
      !Object.keys(self.state.car.rental_details).length
    ) {
      return;
    }
    return self.state.car.rental_details.map((m, i) => {
      return (
        <li className="col-xl-12 col-sm-12 pull-left pl-1" key={"ren-" + i}>
          <span className="blue_text mb-0">{m}</span>
        </li>
      );
    });
  };
  vehicleDetailText = () => {
    var self = this;
    if (self.state.textloading || !Object.keys(self.state.vehicleText).length) {
      return <FullPageLoader />;
    }
    return self.state.vehicleText.map((m, i) => {
      return (
        <div className="col-sm-6" key={"det-" + i}>
          <div className="owner_box">
            <h5 className="blue_text head_h5">
              <img src={"/images/" + m.icon} alt="" />
              {m.title}
            </h5>
            <p>{m.content}</p>
          </div>
        </div>
      );
    });
  };
  equipmentTabDetail = () => {
    var self = this;
    if (
      self.state.loading ||
      (self.state.car && !Object.keys(self.state.car.equipment).length)
    ) {
      return;
    }
    return self.state.car.equipment.map((m, i) => {
      return (
        <li className="col-xl-4 col-sm-4 pull-left pl-1" key={"ren-" + i}>
          <span className="blue_text mb-0">{m}</span>
        </li>
      );
    });
  };
  renderMeta = () => {
    if (!Object.keys(this.state.car).length) return;

    var metas = {
      metatitle: this.state.car.year+' '+this.state.car.make+' '+this.state.car.model+' | DriveItAway',
    };
    return (
      <MetaTagsInfo metas={metas} pageTitle={this.state.car.year+' '+this.state.car.make+' '+this.state.car.model+' | DriveItAway'} />
    );
  };
  formatPrice = (price) => {
    return (
      Constants.PREFIX_CURRENCY_SYMBLE +
      new Intl.NumberFormat("en-US", { syle: "decimal" }).format(price)
    );
  };
  formatNumber = (num) => {
    return new Intl.NumberFormat("en-US", { syle: "number" }).format(num);
  };

  onPrequalifyResult = (message) => {
    if (message) window.location.href = "/booking";
  };
  getWeeklyRent=()=>{
    var self = this;
    if(self.state.car.tier_rental===undefined) return;
    if(!Object.keys(self.state.car.tier_rental).length) return;
    var weekprice=0;
    Object.entries(self.state.car.tier_rental).forEach((val, i) => {
      if (i === 0) {
        weekprice= (val[1]['weekkEmfRent']);
      } 
    });
    return weekprice.match(/[0-9,.]+/);
  }
  getDayRent=()=>{
    var self = this;
    if(self.state.car.rent===undefined) return;
    return self.state.car.rent.match(/[0-9,.]+/);
  }

  render() {
    return (
      <div className="product-page">
        {this.renderMeta()}
        <section className=" pl-0 pr-0 pt-3">
          <div className="container product_dtl">
            <Link to={"/iframecars"} className="head_h5">
              <u> {"<<"} Back to Listings</u>
            </Link>
          </div>
          <div className="container product_dtl mt-3">
            <h1 className="blue_text head_h2 m-0 font-weight-normal">
              {this.state.car.year} {this.state.car.make}{" "}
              <b>{this.state.car.model}</b>{" "}
            </h1>
            <p className="d-flex mt-2">
              <i
                className="fa fa-map-marker textblue mr-1"
                aria-hidden="true"
              />
              {this.state.car.city}, {this.state.car.state}
            </p>
          </div>
        </section>
        <section className="product_sec searchtop pl-0 pr-0">
          <div className="container ">
            <div className="row ">
              <div className="col-md-6 col-lg-6 carousel">
                <div id="slider" className="flexslider">
                  <ul className="slides">
                    {this.renderSliderImages("big")}
                  </ul>
                </div>
                <div id="carousel" className="flexslider">
                  <ul className="slides">
                    {this.renderSliderImages("thumb")}
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="buy_car product_dtl ">
                      <span className="blue_text">As low as</span>
                      <h5 className="fontsizelarge blue_text">
                        
                        <sup>$</sup>{this.getDayRent()}
                      </h5>
                      <span className="blue_text">per day</span>
                    </div>
                    <div className="buy_car product_dtl thelgmt">
                      <span>As low as</span>
                      <h5 className="fontsizesmall blue_text">
                        <sup>$</sup>{this.getWeeklyRent()}
                      </h5>
                      <span>per week</span>
                    </div>
                  </div>
                  <div className="col-sm-6 product_dtl">
                    <table className="w-100 table">
                      <tbody>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Odometer
                            </h5>
                          </td>
                          <td>
                            <h5 className="head_h5 mb-0">
                              {this.state.car.odometer?this.state.car.odometer:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Engine Type
                            </h5>
                          </td>
                          <td>
                            <h5 className="head_h5 mb-0">
                              {this.state.car.engine?this.state.car.engine:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">MPG</h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.mpg_city} City/
                              {this.state.car.mpg_hwy} Hightway
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Exterior Color
                            </h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.color?this.state.car.color:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Interior Color
                            </h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.interior_color?this.state.car.interior_color:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Transmission
                            </h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.transmition_type?this.state.car.transmition_type:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">Door</h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.doors?this.state.car.doors:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">VIN</h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.vin_no}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Stock#
                            </h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.stock_no?this.state.car.stock_no:'N/A'}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5 className="blue_text head_h5 mb-0">
                              Selling Price
                            </h5>
                          </td>
                          <td>
                            <h5 className=" head_h5 mb-0">
                              {this.state.car.msrp}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 mt-3">
                    <div className="row detailpagebtnblock">
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn pricing_btn w-100  bg-blue text-capitalize pull-left download"
                          onClick={() => this.props.showDownloadAppModal()}
                        >
                          <small>Download App to Book</small>
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn pricing_btn btn-white mt-4 mb-4 mt-md-0 mt-lg-0  mt-md-0  mb-lg-0 w-100 text-capitalize pull-left"
                          onClick={() => this.props.showDownloadAppModal()}
                        >
                          <small>Get More Info</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" bottom-border">
          <DriveItWantIt />
        </section>
        <section className=" bottom-border">
          <DriveItWantItPackage />
        </section>
        <section className="product_sec_">
          <div className="container">
          <h2 className="highlight-primary mt-4 mb-2 fs-2 has-secondary-color has-text-color">More About the {this.state.car.year}{" "}{this.state.car.make}{" "}
              {this.state.car.model}</h2>
            <div className="tab_box">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {this.state.car && this.state.car.equipment && <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="quipment-tab"
                    data-toggle="tab"
                    href="#quipment"
                    role="tab"
                    aria-controls="quipment"
                    aria-selected="true"
                  >
                    Equipment
                  </a>
                </li>}
                {this.state.car && this.state.car.rental_details && <li className="nav-item">
                  <a
                    className="nav-link"
                    id="rentaldetail-tab"
                    data-toggle="tab"
                    href="#rentaldetail"
                    role="tab"
                    aria-controls="rentaldetail"
                    aria-selected="false"
                  >
                    Rental Details
                  </a>
                </li>}
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="rentaldetail-tab"
                    data-toggle="tab"
                    href="#otherdetail"
                    role="tab"
                    aria-controls="otherdetail"
                    aria-selected="false"
                  >
                    Other Details
                  </a>
                </li> */}
              </ul>
              <div className="tab-content product_dtl" id="myTabContent">
                {this.state.car && this.state.car.equipment && <div
                  className="tab-pane fade show active"
                  id="quipment"
                  role="tabpanel"
                  aria-labelledby="quipment-tab"
                >
                  <div className="row gap_14 pl-lg-2 pr-lg-2">
                    <ul className="col-sm-12">
                      {this.equipmentTabDetail()}
                    </ul>
                  </div>
                </div>}
                {this.state.car && this.state.car.rental_details && <div
                  className="tab-pane fade"
                  id="rentaldetail"
                  role="tabpanel"
                  aria-labelledby="rentaldetail-tab"
                >
                  <div className="row gap_14 pl-lg-2 pr-lg-2">
                    <ul className="col-sm-12">{this.rentalTabDetail()}</ul>
                  </div>
                </div>}
                {/* <div
                  className="tab-pane fade"
                  id="otherdetail"
                  role="tabpanel"
                  aria-labelledby="otherdetail-tab"
                >
                  <div className="row gap_14 pl-lg-2 pr-lg-2">
                    {this.vehicleDetailText()}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <HowWeDrive />
        {this.state.loading && <FixedLoader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userLoggedIn, userDetails } = state.auth;
  return { userLoggedIn, userDetails };
};

const mapDispatcherToProps = (dispatch) => {
  return {
    setCardDetailsToStore: (data) => dispatch(setCarDetails(data)),
    showDownloadAppModal: () => dispatch(showDownloadAppModal())
  };
};

export default connect(mapStateToProps, mapDispatcherToProps)(IframeCarDetails);
