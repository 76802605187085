import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ListingCarFilters from "../blocks/ListingCarFilters";
import Pagination from "../blocks/Pagination";
import SearchForm from "../blocks/SearchForm";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import HomeAppBottom from "../blocks/HomeAppBottom";
import { FixedLoader } from "../blocks/Loaders";
import { LoginModal } from "../blocks/Modals";
import { toggleLoginModal } from "../store/actions/GlobalActions";
import {
  addVehicleToFavourite,
  removeVehicleFromFavourite,
} from "../services/CarService";
var Constants = require("../config/Constants");

class CarListing extends Component {
  state = {
    cars: {},
    filters: { city: null, limit: 16, orderby: "id" },
    loading: true,
    pagination: 1,
    pages: 1,
    make: null,
    model: null,
    from_year: null,
    to_year: null,
    city: null,
    cab_type: null,
    search_records: null,
    color: null,
    message: "",
    isUserLoggedIn: this.props.isUserLoggedIn,
  };
  componentWillMount() {
    //if(this.props.params.query === undefined) return;
    var query = ""; // this.props.params.query;
    var search = query.split("&");
    var searchList = {
      make: null,
      model: null,
      from_year: null,
      to_year: null,
      city: null,
      page: 1,
      cab_type: null,
      color: null,
    };
    search.forEach((s, i) => {
      var temp = s.split("=");
      searchList[temp[0]] = temp[1];
    });
    var filters = this.state.filters;

    if (searchList["orderby"] === "random") {
      filters["orderby"] = "random";
    }
    this.setState({
      make: searchList["make"],
      model: searchList["model"],
      from_year: searchList["from_year"],
      to_year: searchList["to_year"],
      city: searchList["city"],
      pagination: searchList["page"],
      cab_type: searchList["cab_type"],
      color: searchList["color"],
    });
  }
  componentDidMount() {
    document.title = Constants.SITE_TITLE;
    // this.getCars();
  }
  componentDidUpdate(prevProps, prevState) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (this.state.loading && !Object.keys(this.state.cars).length)
      this.getCars();
  }
  componentWillReceiveProps(nextProps, prevState) {
    var searchList = {
      make: null,
      model: null,
      from_year: null,
      to_year: null,
      city: null,
      page: 1,
      cab_type: null,
      color: null,
    };
    if (
      nextProps.params !== undefined &&
      nextProps.params.query !== undefined
    ) {
      var query = nextProps.params.query;
      var search = query.split("&");
      search.forEach(function(s, i) {
        var temp = s.split("=");
        searchList[temp[0]] = temp[1];
      });
    }
    var filters = this.state.filters;

    this.setState({
      make: searchList["make"],
      model: searchList["model"],
      from_year: searchList["from_year"],
      to_year: searchList["to_year"],
      city: searchList["city"],
      filters: filters,
      loading: true,
      cars: {},
      pagination: searchList["page"],
      cab_type: searchList["cab_type"],
      color: searchList["color"],
      accord:"fa pull-right fas fa-plus"
    });
  }

  getCars = async () => {
    var self = this;
    // Get Cars
    var url = "searchPreAuthVehicles";
    if (this.state.isUserLoggedIn) {
      url = "searchVehicles";
    }
    DiaAxios.post(url, {
      page: self.state.pagination,
      orderby: self.state.filters.orderby,
      limit: self.state.filters.limit,
      totol_count: true,
      pagination: self.state.pagination,
      year: { min: self.state.from_year, max: self.state.to_year },
      make: self.state.make,
      model: self.state.model,
      from_year: self.state.from_year,
      to_year: self.state.to_year,
      city: self.state.city,
      cab_type: self.state.cab_type,
      color: self.state.color,
    })
      .then(function(response) {
        var search_records = null,
          cars = null,
          pages = null;
        var limit = self.state.filters.limit;
        if (response.data.status) {
          if (
            self.props.params.query &&
            self.props.params.query !== undefined
          ) {
            if (
              self.props.params.query.indexOf("city") > -1 ||
              self.props.params.query.indexOf("make") > -1 ||
              self.props.params.query.indexOf("model") > -1 ||
              self.props.params.query.indexOf("from_year") > -1 ||
              self.props.params.query.indexOf("to_year") > -1 ||
              self.props.params.query.indexOf("price_range") > -1
            )
              search_records = response.data.records;
          }
          search_records = response.data.records;
          cars = response.data.result;
          pages = Math.ceil(response.data.records / limit);
        } else {
          search_records = null;
          cars = [];
          pages = 0;
        }
        self.setState({
          message: response.data.message,
          search_records: search_records,
          cars: cars,
          pages: pages,
          loading: false,
        });
      })
      .catch(function(error) {
        self.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  addCarToFavourite = async (vehicle_id) => {
    if (!this.state.isUserLoggedIn) {
      this.props.toggleLoginModal();
      return;
    }
    const data = {
      vehicle_id: vehicle_id,
      program: "rideshare",
      financing: "rent ",
    };
    addVehicleToFavourite(data);
  };

  removeCarFromFavourite = async (vehicle_id) => {
    if (!this.state.isUserLoggedIn) {
      this.props.toggleLoginModal();
      return;
    }
    const data = {
      vehicle_id: vehicle_id,
    };
    removeVehicleFromFavourite(data);
  };

  wishlistButton = (obj) => {
    if (this.state.isUserLoggedIn && obj.isWishlist) {
      return (
        <button
          className="active page-link heart  d-flex align-items-center justify-content-center"
          onClick={() => this.removeCarFromFavourite(obj.id)}
        >
          <i className="fa fa-heart" aria-hidden="true" />
        </button>
      );
    }
    return (
      <button
        className="page-link heart  d-flex align-items-center justify-content-center"
        onClick={() => this.addCarToFavourite(obj.id)}
      >
        <i className="fa fa-heart" aria-hidden="true" />
      </button>
    );
  };

  featuredText = (featured_text) => {
    return featured_text.map((ob, index) => {
      return <li key={"fet=" + index} className="d-flex"><i className="fa fa-check-circle" aria-hidden="true"></i> {ob}</li>;
    });
  };
  formatPrice = (price) => {
    return Constants.PREFIX_CURRENCY_SYMBLE + price; //new Intl.NumberFormat('en-US', { syle: 'decimal'}).format(price);
  };
  calculatePrice = (car) => {
    return Math.ceil(car.msrp);
  };
  renderCars = () => {
    // var self = this;
    if (this.state.loading) return <FixedLoader />;
    if (!Object.keys(this.state.cars).length)
      return <h3 className="text-center">{this.state.message}</h3>;
    let cars = [];
    let row = [];
    let count = 1;
    this.state.cars.forEach((obj, index) => {
      row.push(
        <div key={"car-" + index} className="col-sm-6 col-lg-3">
          <div className="car_box">
            <div className="car_list">
              <span>
              {obj.year} {obj.make} 
              </span>
              <span className="pull-right">
                {this.wishlistButton(obj)}
                <img src="/images/miles.png" alt="" /> {obj.odometer}(s)
              </span>
              <h4>{obj.model}</h4>
              
            </div>
            <div className="car_img text-center position-relative">
              <Link
                to={
                  "/car/details/" +
                  obj.id +
                  "/" +
                  obj.vehicle_name
                    .split(" ")
                    .join("-")
                    .toLowerCase()
                }
                className="linktodetails"
              > 
                <span className="litsing-car-image" style={{background: `url(${obj.image})`}}></span>
              </Link>
            </div>
            <div className="car_list">
              <ul className="list-unstyled d-inline-flex featuredtext">
                {this.featuredText(obj.featured_text)}
              </ul>
              <br />
            </div>
            <div className="car_price d-flex justify-content-between">
              <div>
                <span>{obj.rent_label}</span>
                <h5>{obj.rent}</h5>
              </div>
              <div>
                <Link
                  className="btn"
                  to={
                    "/car/details/" +
                    obj.id +
                    "/" +
                    obj.vehicle_name
                      .split(" ")
                      .join("-")
                      .toLowerCase()
                  }
                >
                  {/*this.formatPrice(obj.msrp)*/}
                  {obj.msrp}
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
      if (count === 4) {
        cars.push(this.formatCarRow(row, index));
        row = [];
        count = 0;
      }
      count++;
    });
    if (count > 1) {
      cars.push(this.formatCarRow(row));
    }
    return cars;
  };
  formatCarRow = (row, index) => {
    return (
      <div key={"car-row-" + index} className={"row displayFlex"}>
        {row}
      </div>
    );
  };

  updatePagination = (pagination) => {
    var self = this;
    var params = this.getUrl(pagination);
    window.history.pushState({ urlPath: params }, document.title, params);
    self.setState({
      pagination: pagination,
      cars: {},
      loading: true,
    });
    window.scrollTo(0, 150);
  };

  getUrl = (page) => {
    var url = window.location.href.split("/");
    var lastParam = url[url.length - 1];
    var params = "";
    if (lastParam.indexOf("=") > -1) {
      url.splice(url.length - 1, 1);
      if (lastParam.indexOf("page") > -1) {
        var arr = lastParam.split("&");
        params = window.location.href.replace(
          arr[arr.length - 1],
          "page=" + page
        );
      } else {
        params = window.location.href + "&page=" + page;
      }
    } else {
      params = window.location.href + "/page=" + page;
    }
    return params;
  };
  updateFilter = (event) => {
    var filters = this.state.filters;
    filters[event.target.id] = event.target.value;
    this.setState({ filters: filters, loading: true, pagination: 1, cars: {} });
  };

  toggleClass = () => {
		this.setState({accord: this.state.accord=='fa pull-right fas fa-plus'?'fa pull-right fas fa-minus':'fa pull-right fas fa-plus'});	
	}
  renderPagination = () => {
    if (this.state.pages < 2) return null;
    return (
      <Pagination
        records={this.state.search_records}
        limit={this.state.filters.limit}
        pages={this.state.pages}
        currentPage={this.state.pagination}
        updatePagination={this.updatePagination}
      />
    );
  };
  render() {
    return (
      <div className="mt-3">
        <section className="search_sec">
          <MetaTagsInfo
            pageTitle="DriveItAway"
            action="getPageMetas"
            id={Constants.LISTING_PAGE_ID}
          />
          <div className="container">
            <div className="accordion md-accordion w-100" id="accordionEx3" role="tablist" aria-multiselectable="true">
                <div className="w-100  d-block d-sm-none card-header" role="tab" id="headingTwo3">
                  <a className="collapsed w-100" data-toggle="collapse" data-parent="#accordionEx3" href="#collapseTwo3"
                    aria-expanded="false" aria-controls="collapseTwo3" onClick={()=>this.toggleClass()}>
                    <h2 className="blue_text head_h2 small_h2 ">Filters <i className={this.state.accord}></i></h2>
                  </a>
                </div>
                <div id="collapseTwo3" className="collapse d-none d-sm-block" role="tabpanel" aria-labelledby="headingTwo3"
              data-parent="#accordionEx3">
                  <div className="select_sec">
                    <SearchForm params={this.props.params} />
                  </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="sort_box">
              <ListingCarFilters
                search_records={this.state.search_records}
                updateFilter={
                  this.updateFilter
                }
                pages={this.state.pages}
                currentPage={this.state.pagination}
                updatePagination={this.updatePagination}
                filters={this.state.filters}
              />
            </div>
            <div className="car_sec">{this.renderCars()}</div>
          </div>
          <div className="container">
            <div className="page_next">{this.renderPagination()}</div>
          </div>
        </section>
        <HomeAppBottom bgclass="bg-gray" />
        <LoginModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { isUserLoggedIn: state.auth.userLoggedIn };
};

const mapDispatchToPops = (dispatch) => {
  return {
    toggleLoginModal: () => dispatch(toggleLoginModal()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToPops
)(CarListing);
