import React,{useEffect} from "react";
const DriveItWantIt = () => {


  return (
    <>
      <div className="container py-5 ">
        <div className="row">
          <div className="col-sm-6 mb-4">
            <div className="wp-container-20 wp-block-column is-vertically-aligned-center">
              <h2 className="highlight-primary mb-4 fs-1 has-secondary-color has-text-color">
                Get the Whole
                <br />
                <em>Turnkey Package</em>
              </h2>
              <p className="dark-gray-para">
                Looking to swipe right on your next match? Don’t <br />
                settle. DriveItAway vehicles are the whole package.
              </p>
            </div>
            <div className="wp-container-21 wp-block-column is-vertically-aligned-center pt-6">
              <figure className="wp-block-image size-full pt-0 pt-md-6">
                <img
                  loading="lazy"
                  width="602"
                  height="319"
                  src="/images/newui/homepage-vehicle2.png"
                  alt=""
                  className="wp-image-192"
                />
              </figure>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-6">
                <div className="wp-block-image">
                  <figure className="aligncenter size-full text-center">
                    <img
                      loading="lazy"
                      width="150"
                      height="150"
                      src="/images/newui/DriveItAway_Turnkey-Icons_Credit.png"
                      alt=""
                      className="wp-image-195"
                    />
                  </figure>
                </div>
                <h4 className="text-center has-secondary-color has-text-color wp-block-image-h4 ">
                  Eligibility based on income, not credit.
                </h4>
                <p className="text-center dark-gray-para">
                  {" "}
                  Barriers to entry who? We make qualification simple.
                </p>
              </div>
              <div className="col-sm-6">
                <div className="wp-block-image">
                  <figure className="aligncenter size-full text-center">
                    <img
                      loading="lazy"
                      width="150"
                      height="150"
                      src="/images/newui/DriveItAway_Turnkey-Icons_Long-Term.png"
                      alt=""
                      className="wp-image-195"
                    />
                  </figure>
                </div>
                <h4 className="text-center has-secondary-color has-text-color wp-block-image-h4">
                  No long-term financial obligation (unlike a loan or lease).
                </h4>
                <p className="text-center dark-gray-para">
                  {" "}
                  Subscribe-to-own. Buy or return whenever you want.
                </p>
              </div>
              <div className="col-sm-6">
                <div className="wp-block-image">
                  <figure className="aligncenter size-full text-center">
                    <img
                      loading="lazy"
                      width="150"
                      height="150"
                      src="/images/newui/DriveItAway_Turnkey-Icons_Financing.png"
                      alt=""
                      className="wp-image-197"
                    />
                  </figure>
                </div>
                <h4 className="text-center has-primary-color has-text-color wp-block-image-h4">
                  More Financing Options.
                </h4>
                <p className="text-center dark-gray-para">
                  Build your down payment while you drive. Purchase on your
                  own schedule.
                </p>
              </div>
              <div className="col-sm-6">
                <div className="wp-block-image">
                  <figure className="aligncenter size-full text-center">
                    <img
                      loading="lazy"
                      width="150"
                      height="150"
                      src="/images/newui/DriveItAway_Turnkey-Icons_Warranty.png"
                      alt=""
                      className="wp-image-197"
                    />
                  </figure>
                </div>
                <h4 className="text-center has-primary-color has-text-color wp-block-image-h4">
                  A full warranty keeps you covered.
                </h4>
                <p className="text-center dark-gray-para">
                  Drive with confidence. A full warranty is included.
                </p>
              </div>
              <div className="col-sm-6">
                <div className="wp-block-image">
                  <figure className="aligncenter size-full text-center">
                    <img
                      loading="lazy"
                      width="150"
                      height="150"
                      src="/images/newui/DriveItAway_Turnkey-Icons_Coverage.png"
                      alt=""
                      className="wp-image-198"
                    />
                  </figure>
                </div>
                <h4 className="text-center has-secondary-color has-text-color wp-block-image-h4">
                  Full Coverage.
                </h4>
                <p className="text-center dark-gray-para">
                  We keep the motor running. Insurance, maintenance, and
                  roadside assistance come with every subscription.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DriveItWantIt;
