import React, { Component } from "react";
// import RentToOwnProgramDetails from '../blocks/RentToOwnProgramDetails';
import HomeAppBottom from "../blocks/HomeAppBottom";
// import { Link } from 'react-router-dom';
import MetaTagsInfo from "../blocks/MetaTagsInfo";

var Constants = require("../config/Constants");

class HowItWorks extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = Constants.DEFAULT_PAGE_TITLE;
  }
  renderMeta = () => {
    var metas = {
      metatitle: Constants.DEFAULT_PAGE_TITLE,
      metadescription: "DriveItAway",
    };
    return (
      <MetaTagsInfo
        metas={metas}
        pageTitle={Constants.DEFAULT_PAGE_TITLE}
        action="home"
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderMeta()}
        <section className="howItWork">
          <div className="container text-center">
            <h1 className="pink_text head_h1">How It Works</h1>
            <button type="button" className="btn">
              DriveItAway is a No Commitment Pay As You Go solution to buying a
              car -- a new form of rent-to-own.
            </button>
            <button type="button" className="btn pink_bg">
              DriveItAway brings together the short term benefits of renting a
              car with the long-term benefits of owning a car.{" "}
            </button>
          </div>
        </section>
        <section className="renting_sec">
          <div className="container">
            <div className="rent_div d-flex">
              <div className="rent_list">
                <ul className="list-unstyled">
                  <li>
                    <span>No Commitment</span>
                  </li>
                  <li>
                    <span>No Down Payment</span>
                  </li>
                  <li>
                    <span>No Credit Needed</span>
                  </li>
                  <li>
                    <span>Return at Anytime</span>
                  </li>
                  <li>
                    <span>Lowest upfront cash outlay</span>
                  </li>
                  <li>
                    <span>Lowest long term cash outlay</span>
                  </li>
                  <li>
                    <span>Long term Value</span>
                  </li>
                </ul>
              </div>
              <div className="rentcheck_box">
                <h6>Renting</h6>
                <div className="rent_box text-center">
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                </div>
              </div>
              <div className="rentcheck_box">
                <h6>DriveItAway</h6>
                <div className="rent_box text-center">
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </div>
              <div className="rentcheck_box">
                <h6>Buying</h6>
                <div className="rent_box text-center">
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="processTxt">
          <div className="container">
            <p className="m-0 text-center">
              DriveItAway allows you to start in rental where there is no
              commitment and a lower fee to begin. As you drive, you pay for
              usage. With each usage payment, you are building a potential down
              payment to be used to buy the car.{" "}
            </p>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <h2 className="blue_text head_h2 text-center">The Process</h2>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="media">
                  <div className="media-left pink_text d-flex align-items-center justify-content-center">
                    01
                  </div>
                  <div className="media-body d-flex align-items-center">
                    <h5 className="blue_text head_h5 m-0">
                      Browse the DriveItAway web or mobile app and find your
                      dream car
                    </h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left pink_text d-flex align-items-center justify-content-center">
                    03
                  </div>
                  <div className="media-body d-flex align-items-center">
                    <h5 className="blue_text head_h5 m-0">Book your vehicle</h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left pink_text d-flex align-items-center justify-content-center">
                    05
                  </div>
                  <div className="media-body d-flex align-items-center">
                    <h5 className="blue_text head_h5 m-0">
                      Receive offers from lenders along the way to convert to an
                      auto loan and commit to buying on YOUR TERMS
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="media">
                  <div className="media-left pink_text d-flex align-items-center justify-content-center">
                    02
                  </div>
                  <div className="media-body d-flex align-items-center">
                    <h5 className="blue_text head_h5 m-0">
                      Build your program with your selected vehicle choosing
                      your upfront and monthly payment structure
                    </h5>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left pink_text d-flex align-items-center justify-content-center">
                    04
                  </div>
                  <div className="media-body d-flex align-items-center">
                    <h5 className="blue_text head_h5 m-0">
                      Prepay for usage and insurance where usage fees will build
                      a potential down payment as you drive
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="includ_sec">
          <div className="container text-center">
            <h2 className="white_text head_h2">What’s Included?</h2>
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-3">
                <img src="/images/includ_icon.png" alt="" />
                <p className="white_text">
                  Use the car as much or as little as you like. Prepay for miles
                  each week.
                </p>
              </div>
              <div className="col-md-6 col-lg-3">
                <img src="/images/includ_icon2.png" alt="" />
                <p className="white_text">
                  Insurance is bundled and billed separately. No need to get
                  your own insurance
                </p>
              </div>
              <div className="col-md-6 col-lg-3">
                <img src="/images/includ_icon3.png" alt="" />
                <p className="white_text">Maintenance</p>
              </div>
              <div className="col-md-6 col-lg-3">
                <img src="/images/includ_icon4.png" alt="" />
                <p className="white_text">Roadside assistance</p>
              </div>
            </div>
          </div>
        </section>
        <HomeAppBottom bgclass="bg-gray" />
      </div>
    );
  }
}
export default HowItWorks;
