import React,{Component} from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import Title from '../templates/Title';
import {Link} from 'react-router-dom';
var Constants = require("../config/Constants");

class Dealers extends Component{
	componentDidMount(){
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="dealers"/>
		)
	}	
	render(){
		return (
			<div>
				{this.renderMeta()}
				<Title title="Dealers" />
				<section className="product_sec">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<p className="pb-2">Car Dealers! Car subscriptions and other forms of Mobility-As-A-Service are being touted as the future of
								Automotive. But, what if selling cars and offering these different forms of mobility weren’t the opposite
								of each other? What if you could use mobility as a way to sell more cars?</p>
								<p className="pb-2"><strong>Welcome to DriveItAway!</strong></p>
								<p className="pb-2">DriveItAway allows a customer to start the car buying process in a rental contract where they pay for
								use and accrue a down payment while driving the car. Use this as a tool in your F&amp;I office for anytime:</p>
								<p>1. A customer doesn’t have the full down payment</p>
								<p className="pb-2">2. A customer doesn’t have sufficient proof of income</p>
								<p className="pb-2">Preserve profit in each deal. Give your customers an alternative to traditional car buying. Bring your
								operation into the 21 st century with a true online and mobile process.</p>
							</div>
						</div>
						<div className="row mb-4 mt-4">
							<div className="col-md-6 text-center">
							 <Link to="/contact-us" className="btn" title="">More Info</Link>
							 </div>
							 <div className="col-md-6 text-center">
							 <a href="hhttps://app.driveitaway.com/logins/pre_register" className="btn">Sign Up</a>
							</div>
						</div>
						
						<div className="faqlist text-center">
							<h3>Frequently Asked Questions</h3>
						</div>
						
						<div className="row">
					    	<div className="faqlist mx-auto">
					    		<div className="accordion" id="faqExample">
					    			<div className="card">
					    				<div className="card-header p-2" id="headingOne">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
					    							Q: How do I get into the DriveItAway turn-key Mobility as a Service/Car Sharing Program?
					    						</button>
					    					</h5>
					    				</div>

					    				<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#faqExample">
					    					<div className="card-body">
					    						<b>Answer:</b> Give us a call at <a href="tel:2034914283">(203) 491-4283</a> , we are dedicated to making it easy and cost effective for any dealer to get into the Mobility as a Service business. Each DriveItAway affiliated dealer can design their own car sharing program, focused on both satisfying the needs of current Ride Sharing (Lyft/Uber) drivers, and your retail prospects who want a subscription service—why let them migrate to 3rd party car sharing providers? We give you a fully automated process, in a turn-key, comprehensive, solutions driven program (including a mobile software platform, insurance, and training) to get your dealership up and running in this emerging, rapidly expanding new area of the transportation business.
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingTwo">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
					    							Q: What does it cost?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
					    					<div className="card-body">
					    						We are dedicated to making our DriveItAway, turn-key Car Sharing program affordable to all dealerships, franchise and independent, large and small. There are literally no start-up fees, and no minimum usage requirements of any kind. We only charge a small revenue share from your profits, so give us a call and we will give you the details: <a href="tel:8564953138">856 495 3138</a>. Your only fixed cost is the gps module you need to install on each car sharing vehicle, to allow the program to be fully “self-service” for the customer (including vehicle tracking, condition, fuel level, and even keyless entry).
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingThree">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
					    							Q. Are there any start-up fees?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
					    					<div className="card-body">
					    						None.<br/><br/>
					    						We are dedicated to enabling every dealer to get into the game of Mobility as a Service, so you may offer subscription vehicle services or “cents per mile” models right along with traditional dealership activities. We truly believe that “MaaS” or whatever Silicon Valley wants to call it, is best suited to be conducted inside car dealerships, not by third parties—the place transportation needs have been satisfied for the last 100 years.
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingfour">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
					    							Q. Are there any volume obligations?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseFour" className="collapse" aria-labelledby="headingfour" data-parent="#faqExample">
					    					<div className="card-body">
					    						None – we make it easy!
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingFive">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
					    							Q. Who decides what vehicles I rent and under what rates and terms?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#faqExample">
					    					<div className="card-body">
					    						You do, we just facilitate (software/hardware), the insurance and the training. It’s your business to operate how you would like…we work for you and give you everything you need to custom design your “Mobility as a Service” business…and we add the strength of the “powered by DriveItAway label” with our national “Lyft Your Down Payment” and “Drive for Your Down Payment” programs.
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingSix">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
					    							Q. What does the comprehensive insurance cover?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#faqExample">
					    					<div className="card-body">
					    						All of the State minimums and any time your driver does not happen to be covered under a Lyft/Uber Ride Sharing policy. The vehicle, in operation, is never under your garage policy coverage. You may title vehicles to be on your car sharing program in a separate LLC if desired.
					    					</div>
					    				</div>
					    			</div>
					    			<div className="card">
					    				<div className="card-header p-2" id="headingSeven">
					    					<h5 className="mb-0">
					    						<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
					    							Q. I want to try it, how do I start?
					    						</button>
					    					</h5>
					    				</div>
					    				<div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#faqExample">
					    					<div className="card-body">
					    						<a href="/contact-us/">CLICK HERE</a> to get started. For a limited time, we will even throw in, at our expense, one premium GPS module that does everything (tracks location mileage, fuel, engine light, hard acceleration or breaking, geo-fence locations, even opens the door of the vehicle by app), a $330 value, when you sign up. Give us a call <a href="tel:2034914283">(203) 491-4283</a>, email us –<a href="mailto:dealers@driveitaway.com">dealers@driveitaway.com</a>, text DRIVEITAWAY to 69696, or just hit this link. We will get you started today.
					    					</div>
					    				</div>
					    			</div>
					    		</div>
					    	</div>
					    </div>
					</div>
				</section>
				<HomeAppBottom bgclass="bg-gray" />
				
			</div>
		)
	}
}
 export default Dealers;
