import React from "react";
const IFooter = () => {

  return (
<footer>
  <div className="driveitaway-footer bg-primary text-white py-4">
    <div className="container-lg">
      <div className="row">
        <div className="col-md-6 footer-left mb-4 mb-md-0">
          <div>
            <div className="widget_text widget-odd widget-last widget-first widget-1 text-center text-md-start footer-widget mb-3">
              <div className="textwidget custom-html-widget">
                <h2 className="mb-3 mb-md-4 lh-1 text-white">
                  Download our <strong><em>NEW</em></strong> Mobile App
                </h2>
                <div className="row g-3 app-buttons">
                  <div className="col-6">
                    <a
                      href="https://apps.apple.com/us/app/driveitaway/id1393803514"
                      target="_blank"
                      rel="noreferrer noopener"
                      title="Download on the App Store"
                    >
                      <img
                        loading="lazy"
                        width="400"
                        height="119"
                        src="/images/newui/app-store.png"
                        alt="Download on the App Store"
                      />
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.carshare"
                      target="_blank"
                      rel="noreferrer noopener"
                      title="Get it on Google Play"
                    >
                      <img
                        loading="lazy"
                        width="404"
                        height="119"
                        src="/images/newui/play-store.png"
                        alt="Get it on Google Play"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="col-md-6 footer-right">
          <div>
            <div className="widget-odd widget-first widget-1 text-center text-md-end footer-widget mb-3">
              <a href="https://www.driveitaway.com/">
                <img
                  width="300"
                  height="300"
                  src="/images/newui/logo-dark.svg"
                  className="image wp-image-137  attachment-medium size-medium"
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
            <div className="widget-even widget-last widget-2 footer-widget mb-3">
              <div className="menu-social-menu-container">
                <ul id="menu-social-menu" className="menu">
                  <li
                    id="menu-item-138"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-138"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.facebook.com/driveitaway"
                    >
                      <i className="fa fa-facebook-f" />
                    </a>
                  </li>
                  <li
                    id="menu-item-139"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-139"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.instagram.com/driveitaway/"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li
                    id="menu-item-140"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-140"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://twitter.com/driveitaway/"
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="driveitaway-info bg-primary text-white border-top border-dark border-opacity-10 py-2 text-center">
    <div className="container-lg">
      <small>©&nbsp;2022 DriveItAway</small>
    </div>
  </div>
</footer>
);
};

export default IFooter;
