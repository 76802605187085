import React,{Component} from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import Title from '../templates/Title';
import {Link} from 'react-router-dom';
var Constants = require("../config/Constants");

class Rideshare extends Component{
	componentDidMount(){
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="rideshare"/>
		)
	}	
	render(){
		return (
			<div>
				{this.renderMeta()}
				<Title title="Rideshare/Delivery" />
				<section className="product_sec">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<p className="pb-2">If you work for any of the rideshare or delivery companies, like Uber, Lyft, Doordash, Grubhub, and so
									on, you will make more money OWNING your vehicle instead of renting. It’s a matter of unit economics.
									Every mile you drive, ALL of your rental fees go to the rental company.</p>
								
								<p className="pb-2">Instead, use DriveItAway as a bridge between renting and owning. Build your program with us today.</p>
								
								<p>1. Choose your dream car</p>
								<p>2. Choose your upfront and weekly payment structure</p>
								<p>3. Use your usage payments as potential equity to buy the car!</p>
								<p className="pb-2">4. Keep all of your gig income for YOU!</p>
								<p className="pb-2">It’s truly that simple.</p>
							</div>
						</div>
						<div className="row mb-4 mt-4">
							<div className="col-md-12 text-center">
							 <Link to="/signin" className="btn" title="">Get Started</Link>
							 </div>
						</div>
					</div>
				</section>
				<HomeAppBottom bgclass="bg-gray" />
				
			</div>
		)
	}
}
 export default Rideshare;
