import React,{useEffect} from "react";
import {showDownloadAppModal} from "../store/actions/GlobalActions";
import { useDispatch } from "react-redux";
const DriveItWantItPackage = () => {
const dispatch=useDispatch();

   useEffect(()=>{
      window.driveItWantItSlider();
   },[]);

  return (
  <>
<div className="container py-5" >
   <div className="row">
      <div className="col-sm-4 mb-4">
         <div className="wp-container-20 wp-block-column is-vertically-aligned-center">
            <h2 className="highlight-primary mb-4 fs-1 has-secondary-color has-text-color">Drive it. Want it.<br/> Buy it...<em>but only if you love it</em> <img draggable="false" role="img" width="40px" className="emoji" alt="😍" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f60d.svg"></img></h2>
            <p className="dark-gray-para">See a car you love? DriveItAway! Subscribe and drive by downloading the app. If you love it, buy it! If not, return it. We call that low-risk, high-reward.</p>
         </div>
         <div className="text-center is-vertically-aligned-center pt-4">
            <button
                  type="button"
                  className="btn pricing_btn text-capitalize pl-4 pr-4"
                  onClick={()=>{dispatch(showDownloadAppModal())}}
                >Get Started
            </button>
         </div>
      </div>
      <div className="col-sm-8">
         <div id="driveItWantItSlider" className="flexslider">
            <ul className="slides">
               <li className="">
                  <div className="d-block text-white bg-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                        <span className="title">Download the app</span>
                        <p className="text-white">Our app will lead you through the DriveItAway process. It’s free to download and easy to use.</p>
                     </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/download-app.png"/>
                     </div>
                  </div>
               </li>
               <li className="">
                  <div className="d-block text-white bg-dark-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                        <span className="title">Select your car</span>
                        <p className="text-white">Choose from a large selection of cars, trucks, SUVs, and even EVs in your area. Will you drive an old favorite or try something new?</p>
                     </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/DriveItAway_Carousel_343X438_Select.png"/>
                     </div>
                  </div>
               </li>
               <li className="">
                  <div className="d-block text-white bg-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                        <span className="title">Create your subscription</span>
                        <p className="text-white">Build the subscription plan that works for you. Credit doesn’t matter here. Qualify based on income instead.</p>
                        </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/DriveItAway_Carousel_343X438_Create.png"/>
                     </div>
                  </div>
               </li>
               <li className="">
                  <div className="d-block text-white bg-dark-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                     <span className="title">Drive your car</span>
                     <p className="text-white">Take the scenic route knowing we’ve got the details handled. Maintenance, insurance, and roadside assistance are included. </p>
                     </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/DriveItAway_Carousel_343X438_Drive.png"/>
                     </div>
                  </div>
               </li>
               <li className="">
                  <div className="d-block text-white bg-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                        <span className="title">Grow your down payment</span>
                        <p className="text-white">A percentage of your usage fees can go toward building your future down payment. If you decide to buy the vehicle, you’re already on your way. </p>
                     </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/DriveItAway_Carousel_343X438_Grow.png"/>
                     </div>
                  </div>
               </li>
               <li className="">
                  <div className="d-block text-white bg-dark-blue pull-left w-100 p-3 p-sm-5">
                     <div className="pull-left w-50">
                        <span className="title">Purchase, keep driving, or return</span>
                        <p className="text-white">You choose what happens next. Will you purchase the vehicle, return it, or keep driving?</p>
                        </div>
                     <div className="pull-right w-50">
                        <img className="img-responseive" src="/images/newui/DriveItAway_Carousel_343X438_Decide.png"/>
                     </div>
                  </div>
               </li>
            </ul>
         </div>
         <div id="driveItWantItCarousel" className="flexslider">
            <ul className="slides">
               <li className="">
                  <div className="tp-tab-title">Download</div>
                  <div className="tp-tab-border"></div>
               </li>
               <li className="">
                  <div className="tp-tab-title">Select</div>
                  <div className="tp-tab-border"></div>
               </li>
               <li className="">
                  <div className="tp-tab-title">Create</div>
                  <div className="tp-tab-border"></div>
               </li>
               <li className="">
                  <div className="tp-tab-title">Drive</div>
                  <div className="tp-tab-border"></div>
               </li>
               <li className="">
                  <div className="tp-tab-title">Grow</div>
                  <div className="tp-tab-border"></div>
               </li>
               <li className="">
                  <div className="tp-tab-title">Decide</div>
                  <div className="tp-tab-border"></div>
               </li>
            </ul>
         </div>     
      </div>
   </div>
</div>
</>
  );
};
export default DriveItWantItPackage;
