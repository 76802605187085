import React,{useState} from "react";
import {showDownloadAppModal} from "../store/actions/GlobalActions";
import { useDispatch } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
const IHeader = ({ isSticky }) => {

 const dispatch=useDispatch();
 const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <header id="masthead" className="site-header">
      <nav
        id="nav-top"
        className="navbar navbar-expand-lg border-bottom py-1 pt-md-3 pb-md-2"
      >
        <div className="container-lg">
          <div
            id="nav_menu-2"
            className="widget-odd widget-last widget-first widget-1 top-nav-item widget_nav_menu"
          >
            <div className="menu-top-menu-container">
              <ul id="menu-top-menu" className="menu">
                <li
                  id="menu-item-12"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-12"
                >
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.driveitawayholdings.com"
                  >
                    Investor Relations
                  </a>
                </li>
                <li
                  id="menu-item-57"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-57"
                >
                  <a href="https://www.driveitaway.com/contact-us/">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <nav id="nav-main" className="navbar navbar-expand-lg py-3 py-md-4 py-xxl-5">
        <div className="container-lg">
          <a
            className="navbar-brand m-0 p-0"
            href="https://www.driveitaway.com"
          >
            <img
              src="/images/newui/driveitaway-logo-even.png"
              alt="DriveItAway"
              className="logo md"
            />
          </a>

          {/* <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvas-navbar"
            aria-labelledby="offcanvas-navbar-label"
          > */}
          <Offcanvas show={show} placement='end' name='offcanvas-navbar' responsive="lg">
            <div className="offcanvas-header bg-white">
              <span className="h5 mb-0" id="offcanvas-navbar-label">
                Menu
              </span>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>

            <div className="offcanvas-body">
              <ul
                id="main-menu"
                className="navbar-nav text-center text-sm-end justify-content-end flex-wrap ms-auto "
              >
                <li
                  id="menu-item-117"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown nav-item nav-item-117"
                >
                  <a
                    href="#"
                    className="nav-link  dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Program
                  </a>
                  <ul className="dropdown-menu  depth_0">
                    <li
                      id="menu-item-125"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-125"
                    >
                      <a
                        href="https://www.driveitaway.com/how-it-works/"
                        className="dropdown-item "
                      >
                        How It Works
                      </a>
                    </li>
                    <li
                      id="menu-item-126"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-126"
                    >
                      <a
                        href="https://www.driveitaway.com/drivers/"
                        className="dropdown-item "
                      >
                        Drivers
                      </a>
                    </li>
                    <li
                      id="menu-item-127"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-127"
                    >
                      <a
                        href="https://www.driveitaway.com/dealers/"
                        className="dropdown-item "
                      >
                        Dealers
                      </a>
                    </li>
                    <li
                      id="menu-item-128"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-128"
                    >
                      <a
                        href="https://www.driveitaway.com/faqs/"
                        className="dropdown-item "
                      >
                        FAQs
                      </a>
                    </li>
                    <li
                      id="menu-item-129"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-129"
                    >
                      <a
                        href="https://www.driveitaway.com/testimonials/"
                        className="dropdown-item "
                      >
                        Testimonials
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-118"
                  className="menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-118"
                >
                  <a href="https://cars.driveitaway.com" className="nav-link ">
                    Find Your Car
                  </a>
                </li>
                <li
                  id="menu-item-52"
                  className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-52"
                >
                  <a
                    href="https://www.driveitaway.com/electric-vehicles/"
                    className="nav-link "
                  >
                    Electric Vehicles
                  </a>
                </li>
                <li
                  id="menu-item-53"
                  className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-53"
                >
                  <a
                    href="https://www.driveitaway.com/about-us/"
                    className="nav-link "
                  >
                    About Us
                  </a>
                </li>
                <li
                  id="menu-item-54"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown nav-item nav-item-54"
                >
                  <a
                    href="https://www.driveitaway.com/news-events/"
                    className="nav-link  dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    News &amp; Events
                  </a>
                  <ul className="dropdown-menu  depth_0">
                    <li
                      id="menu-item-131"
                      className="menu-item menu-item-type-taxonomy menu-item-object-category nav-item nav-item-131"
                    >
                      <a
                        href="https://www.driveitaway.com/category/news/"
                        className="dropdown-item "
                      >
                        News
                      </a>
                    </li>
                    <li
                      id="menu-item-136"
                      className="menu-item menu-item-type-post_type menu-item-object-page nav-item nav-item-136"
                    >
                      <a
                        href="https://www.driveitaway.com/events/"
                        className="dropdown-item "
                      >
                        Events
                      </a>
                    </li>
                    <li
                      id="menu-item-133"
                      className="menu-item menu-item-type-taxonomy menu-item-object-category nav-item nav-item-133"
                    >
                      <a
                        href="https://www.driveitaway.com/category/press-releases/"
                        className="dropdown-item "
                      >
                        Press Releases
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  id="menu-item-15"
                  className="menu-cta app-modal menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-15"
                >
                  <a href="" className="nav-link" 
                    onClick={(e)=>{e.preventDefault();dispatch(showDownloadAppModal());
                    }}
                   >
                    Download Our App!
                  </a>
                  
                </li>
              </ul>
            </div>
          </Offcanvas>
          {/* </div> */}

          <div className="header-actions d-block d-lg-none d-flex align-items-center">
            <button
              className="btn btn-outline-primary d-lg-none ms-1 ms-md-2 px-3 offcanvas-navbar-toggle"
              type="button"
              onClick={handleShow}
              aria-controls="offcanvas-navbar"
            >
              <i className="fas fa-bars" aria-hidden="true" />
              <span className="visually-hidden-focusable">Menu</span>
            </button>
          </div>
        </div>
      </nav>

      <div className="collapse container-lg d-lg-none" id="collapse-search" />
    </header>

    <section className="site-announcements site-announcements-header site-announcements-after site-announcements-header-after" role="region" aria-label="Site Announcements">
      <div className="announcement announcement-18 p-0" data-announcement-id="18" data-announcement-color="secondary" data-announcement-size="none" style={{backgroundColor: "#0e175f",color: "#ffffff"}} role="alert">
        <div className="contents">
        <span className="fs-6 fs-md-5 fs-lg-4 pt-2 pt-md-0 d-flex flex-column flex-md-row justify-content-center align-items-center gap-2 gap-md-4 gap-lg-5 lh-sm text-center text-md-left fw-lg-bold">
          <em className="px-4 px-md-0">Drive a New EV with our New “EVs for Everyone” Program.</em>
          <a href="https://www.driveitaway.com/electric-vehicles/" className="btn btn-primary w-100 w-md-auto d-md-inline-block fs-md-5 fs-lg-4 px-4 rounded-0">Learn More</a>
        </span>
      </div>
      <div className="actions d-flex align-items-stretch"></div>
    </div>
    </section>
    </>
  );
};

export default IHeader;
