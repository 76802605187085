import React, { Component } from "react";
// import {Link} from 'react-router-dom';
import DiaAxios from "../config/DiaAxios";
// var Constants = require("../config/Constants");

class Testimonials extends Component {
  state = {
    articles: {},
    loading: true,
  };
  componentDidMount() {
    this.getBlockInfo();
  }
  componentDidUpdate() {
    //window.initTestimonialsCarousal();
  }
  getBlockInfo = async () => {
    var self = this;
    DiaAxios.get("getTestimonials", {})
      .then(function(response) {
        if (response.data.status) {
          self.setState({
            articles: response.data.result,
          });
        }
        self.setState({ loading: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getStartRating = (obj) => {
    return (
      <div className="star_div">
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
        <i className="fa fa-star" aria-hidden="true" />
      </div>
    );
  };
  getControls = () => {
    if (!Object.keys(this.state.articles).length) return null;
    return this.state.articles.map((obj, index) => {
      return (
        <li data-target="#carouselExampleIndicators" data-slide-to={index} className={index==0?"active":""} key={"con-" + index} />
        );
    });
  };

  renderTestimonials = () => {
    if (this.state.loading)
      return (
        <div className="text-center" style={{ width: "100%" }}>
          <div className="loader">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    if (!Object.keys(this.state.articles).length) return null;
    return this.state.articles.map((obj, index) => {
      return (
        <div className={index==0?"carousel-item active":"carousel-item"} key={"testi-" + index}>
          <div className="profile_div">
            <img className="rounded-circle" src={obj.thumbnail} />
            <div className="review_text1">
              <p> {obj.detail} </p>
              <strong>{obj.name} </strong>
              <span> NY, USA </span>
            </div>
          </div>
        </div>
      );
    }, this);
  };

  render() {
    return (
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          {this.renderTestimonials()}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
          <ol className="carousel-indicators">
            {this.getControls()}
          </ol>
      </div>
    );
  }
}
export default Testimonials;
