/** @format */

import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { connect } from "react-redux";
import { showDownloadAppModal } from "../store/actions/GlobalActions";
import { GoogleAutocomplete } from "./GoogleAutocomplete";

class IframeSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      makesLoading: true,
      makes: [],
      selectedMake: null,
      modelsLoading: false,
      models: [],
      selectedModel: null,
      showSuggetionList: "none",
      loading: false,
      cityLoading: false,
      curruntLocation: {},
      selectedCity: null,
      select: false,
      cities: [],
      allmodels: [],
      isLocal:false
    };
    this.showPosition = this.showPosition.bind(this);
  }
  componentWillMount() {
  }
  componentDidMount() {
    //function call after load html
    var searchList = {
      make: null,
      model: null,
      city: null,
    };
    if (
      this.props.params !== undefined &&
      this.props.params.query !== undefined
    ) {
      var query = this.props.params.query;
      var search = query.split("&");
      search.forEach((s, i) => {
        var temp = s.split("=");
        searchList[temp[0]] = temp[1];
      });
    }
    this.getCurretnLocation();
    this.setState({
      selectedMake: searchList["make"],
      selectedModel: searchList["model"],
      selectedCity: searchList["city"],
    });

    this.getFilters();
  }

  componentDidUpdate() { } //when state change

  getFilters = () => {
    var self = this;
    DiaAxios.post("getVehicleFilters")
      .then(function (response) {
        //self.setState({cityLoading: false});
        if (response.data.status) {
          self.setState({
            filters: response.data.result,
          });

          self.sortFilters();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  sortFilters = () => {
    var self = this;
    self.setState({ cityLoading: false, makesLoading: false });
    var city = [],
      make = [],
      model = [];
    self.state.filters.forEach((m, indx) => {
      if (m.name === "city") {
        city = Object.entries(m.options);
      }
      if (m.name === "model") {
        make = Object.entries(m.options);
        model = Object.entries(m.options);
      }
      // if (m.name === "model") {
      //   model = Object.entries(m.options);
      // }

    });

    var cities = [],
      makes = [];

    city.forEach((val, i) => {
      if (i === 0 && !this.state.selectedCity && Object.keys(this.state.curruntLocation).length == 0) {
        this.setState({ selectedCity: { label: val[1], value: val[0] } });
      } else if (
        this.state.selectedCity &&
        val[0] === this.state.selectedCity
      ) {
        this.setState({ selectedCity: { label: val[1], value: val[0] } });
      }
      cities.push({ label: val[1], value: val[0] });
    });
    make.forEach((val, i) => {
      if (this.state.selectedMake && val[0] === this.state.selectedMake) {
        this.setState({ selectedMake: { label: val[0], value: val[0] } });
      }
      makes.push({ label: val[0], value: val[0] });
    });



    self.setState({
      cities: cities,
      makes: makes,
      allmodels: model,
    });
    // console.log(this.props.params.query)
    if (this.props.params.query === undefined) {
      this.searchButton.click();
    } else {
      const items = this.props.params.query
        .split("&")
        .filter((el) => el.includes("city"));
      if (items && items.length > 0) {
        this.searchButton.click();
      }
    }
  };
  getModels = (make) => {
    var self = this;
    self.setState({ modelsLoading: true, models: [], selectedModel: null });
    if (make === null) {
      self.setState({ modelsLoading: false });
      return;
    }
    var modl = [],
      modls = [];
    self.state.allmodels.forEach((m, indx) => {
      if (m[0] === make.value) {
        modl = Object.entries(m[1]);
      }
    });
    modl.forEach((val, i) => {
      modls.push({ label: val[1], value: val[0] });
    });
    self.setState({ modelsLoading: false });
    self.setState({ models: modls });
  };
  getCurretnLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
      this.state.isLocal=true;
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  showPosition(position) {
    this.setState({
      curruntLocation: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
    });
    this.searchButton.click();
  }

  citySelect = () => {
    return (
      <Select
        isLoading={this.state.cityLoading}
        value={
          this.state.select
            ? this.state.selectedCity
            : Object.keys(this.state.curruntLocation).length
              ? ""
              : this.state.selectedCity
        }
        onChange={this.handleCityChange}
        options={this.state.cities}
        placeholder="Select"
        resetValue={null}
        clearable={true}
        searchable={false}
        noResultsText={""}
      />
    );
  };
  makesSelect = () => {
    return (
      <Select
        isLoading={this.state.makesLoading}
        value={this.state.selectedMake}
        onChange={this.handleMakeChange}
        options={this.state.makes}
        placeholder="Select"
        resetValue={null}
        clearable={true}
        searchable={false}
        noResultsText={""}
      />
    );
  };
  handleMakeChange = (selectedMake) => {
    this.setState({ selectedMake,isLocal:false, });
    this.getModels(selectedMake);
  };
  modelsSelect = () => {
    return (
      <Select
        isLoading={this.state.modelsLoading}
        value={this.state.selectedModel}
        onChange={this.handleModelChange}
        options={this.state.models}
        placeholder="Select"
        clearable={true}
        searchable={false}
        noResultsText={"Select Make first"}
      />
    );
  };
  handleModelChange = (selectedModel) => {
    this.setState({ selectedModel });
  };

  handleCityChange = (selectedCity) => {
    this.setState({ select: true,isLocal:false,selectedCity });
    this.getModels(selectedCity);
  };
  handleChangeLocation = (e) => {
    this.setState({ curruntLocation:e ,isLocal:false});
  }
  getQuery = (exclude) => {
    var query = "";
    if (Object.keys(this.state.curruntLocation).length && !this.state.select) {
      query +=
        "&lat=" +
        this.state.curruntLocation.latitude +
      "&lng=" +
      this.state.curruntLocation.longitude
      +"&islocal="+this.state.isLocal;
       } else {
        if (this.state.selectedCity && exclude !== "city") {
        query += "&city=" + this.state.selectedCity.value;
      }
    }
    if (this.state.selectedMake && exclude !== "make") {
      query += "&make=" + this.state.selectedMake.value;
    }
    if (this.state.selectedModel && exclude !== "model") {
      query += "&model=" + this.state.selectedModel.value;
    }
   
    if (exclude !== "") return query;
    return query.replace("&", "");
  };
  keyPressed = (e) => {
    if (e.keyCode !== 13) return;
    var query = this.getQuery("");
    var url = window.location.origin + "/iframecars/" + query;
    window.location.href = url;
  };

  render() {
    return (
      <div className="select_div ">
        <div className="row">
          <div className="col-md-5 ititle the-title">
            <h1>
              <strong>
                Find <i>Your Car.</i>
              </strong>
            </h1>
            <p>
              If you want to DriveItAway, start by finding your dream car!
              Here, you’ll find just a sampling of our current inventory.
              We’re always working to increase our inventory and locations,
              so drivers across the country can Drive it. Want it. Buy it (but only if you love it)
            </p>
          </div>
          <div className="col-md-7 pl-0 pr-0">
            <div className="row">
              <div className="col-md-12 pull-left">
                <div className="search_div autocomplete">
                  <div className="form-group">
                    <label>
                      <strong>Your City</strong>
                    </label>
                    <div className="customselect"><GoogleAutocomplete handleChange={this.handleChangeLocation} /></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4 pull-left">
                <div className="search_div autocomplete">
                  <div className="form-group">
                    <label>
                      <strong>City</strong>
                    </label>
                    <div className="customselect">{this.citySelect()}</div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 pull-left">
                <div className="search_div autocomplete">
                  <div className="form-group">
                    <label>
                      <strong>Make</strong>
                    </label>
                    <div className="customselect">{this.makesSelect()}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pull-left">
                <div className="search_div autocomplete">
                  <div className="form-group">
                    <label>
                      <strong>Model</strong>
                    </label>
                    <div className="customselect">
                      {this.modelsSelect()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 pull-left mt-sm-5 mt-2 ">
                <button
                  type="submit"
                  className="btn pricing_btn  bgblue w-100 "
                  onClick={() => this.props.showDownloadAppModal()}>
                  <small>Download App to Book</small>
                </button>
              </div>
              <div className="col-sm-6 pull-left mt-sm-5 mt-2 mb-sm-5 mb-2 ">
                <Link
                  to={"/iframecars/" + this.getQuery("")}
                  className="  pull-sm-right pull-left w-100 ">
                  <button
                    className="pr-lg-5 pl-lg-5 btn bg-blue w-100 "
                    ref={(searchButton) =>
                      (this.searchButton = searchButton)
                    }
                    type="button">
                    Find My Car
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatcherToProps = (dispatch) => {
  return {
    showDownloadAppModal: () => dispatch(showDownloadAppModal()),
  };
};

export default connect(
  null,
  mapDispatcherToProps
)(IframeSearchForm);
