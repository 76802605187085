import React, { Component } from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import HomeBlock from '../blocks/HomeBlock';
import MetaTagsInfo from '../blocks/MetaTagsInfo';

var Constants = require("../config/Constants");

class Home extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return (
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="home" />
		)
	}
	render() {
		return (
			<div>
				{this.renderMeta()}
				<div className='container'>
					<div id="process" className="is-layout-flow wp-block-group pt-lg-6 pt-5">
						<div className="wp-block-group__inner-container">
							<div className="is-layout-flex wp-container-3 wp-block-columns">
								<div className="is-layout-flow wp-block-column pe-lg-6 pe-md-5">
									<h2 className="me-lg-6 pb-4 mb-4 border-bottom border-primary border-4 highlight-primary has-secondary-color has-text-color">The Drive Now<br /><em><strong>DECIDE LATER</strong></em> Process</h2>
									<p>Drive now, decide later. DriveItAway is revolutionizing the way you drive. Whether you’re looking to rent, buy, or simply test out a vehicle or EV, we put you in the driver’s seat.</p>
									<p className="mb-lg-5 mb-0 has-secondary-color has-text-color">We provide the roadmap—you choose the destination.</p>
								</div>
								<div className="is-layout-flow wp-block-column is-vertically-aligned-center">
									<figure className="wp-block-image size-full">
										<img decoding="async" src="/images/newui/homepage-vehicle.webp" alt="New Rental Vehicle" className="wp-image-60" srcSet="/images/newui/homepage-vehicle.webp 567w,/images/newui/homepage-vehicle.webp 300w" sizes="(max-width: 567px) 100vw, 567px" width={567} height={280} />
									</figure>
								</div>
							</div>
						</div>
					</div>
					<div className="is-layout-flow wp-block-group alignfull" id="roadmap" style={{ width: '1440px', marginLeft: '-87px' }}><div className="wp-block-group__inner-container container-lg">
						<div className="wp-block-themepunch-revslider revslider" data-modal="false" data-slidertitle="Roadmap" style={{}}>
							<rs-fullwidth-wrap id="rev_slider_2_2_forcefullwidth" style={{ marginTop: '0px', marginBottom: '0px' }}>
								<rs-module-wrap id="rev_slider_2_2_wrapper" data-source="gallery" style={{ visibility: 'visible', background: 'transparent', padding: '0px', margin: '0px auto', height: '1150px', display: 'block', width: '1440px', position: 'absolute', overflow: 'visible', transform: 'translate(0px)', top: '0px', left: '-167px' }}>
									<rs-module id="rev_slider_2_2" style={{ height: '1150px', width: '1440px', maxHeight: '1150px' }} data-version="6.6.5" data-idcheck="true" className="revslider-initialised rev_redraw_on_blurfocus" data-slideactive="rs-2">
										<rs-slides style={{ overflow: 'hidden', position: 'absolute', visibility: 'visible', maxHeight: 'none', height: '100%', width: '100%' }}>
											<rs-slide style={{ position: 'absolute', display: 'block', overflow: 'hidden', height: '100%', width: '100%', zIndex: 20, opacity: 1, visibility: 'inherit' }} data-key="rs-2" data-title="Slide" data-in="o:0;" data-out="a:false;" data-originalindex={1} data-origindex={0} data-description data-sba data-scroll-based="false" data-owidth={300} data-oheight={200} data-rspausetimeronce={0} data-isactiveslide="true">
												<rs-layer-wrap className="rs-parallax-wrap rs-layer-hidden" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '50px', top: '50px', zIndex: 13, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-0" data-type="shape" data-rsp_ch="on" data-xy="xo:50px,50px,50px,0;yo:50px,50px,50px,0;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:300px,300px,300px,100%;h:180px,180px,180px,100%;" data-vbility="f,f,f,t" data-basealign="slide" data-frame_999="o:0;st:w;sR:8700;" style={{ zIndex: 13, backgroundColor: 'rgba(255, 255, 255, 0.85)', color: 'rgb(255, 255, 255)', width: '300px', height: '180px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap rs-layer-hidden" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '50px', top: '50px', zIndex: 14, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-1" data-type="shape" data-rsp_ch="on" data-xy="xo:50px,50px,50px,0;y:t,t,t,b;yo:50px,50px,50px,0;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:300px,300px,300px,100%;h:180px,180px,180px,50%;" data-vbility="f,f,f,t" data-basealign="slide" data-frame_999="o:0;st:w;sR:8700;" style={{ zIndex: 14, background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(46, 163, 255, 0.15) 100%)', color: 'rgb(255, 255, 255)', width: '300px', height: '180px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '502px', top: '64px', zIndex: 8, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-2" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:1px,1px,1px,0;yo:64px,64px,64px,84px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:598px,598px,598px,400px;h:787px,787px,787px,526px;" data-frame_0="sX:0.98;sY:0.98;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;sR:8000;" style={{ zIndex: 8, color: 'rgb(255, 255, 255)', width: '598px', height: '787px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/road@2x.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/road@2x.png" data-no-retina data-reference="/images/newui/road@2x.png" data-src-rs-ref="/images/newui/road@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={1666} height={2192} />
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '593px', top: '0px', zIndex: 9, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-3" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:-70px,-70px,-70px,-68px;yo:0,0,0,35px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:274px,274px,274px,152px;h:147px,147px,147px,81px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="st:300;sp:700;sR:300;" data-frame_999="o:0;st:w;sR:8000;" style={{ zIndex: 9, color: 'rgb(255, 255, 255)', width: '274px', height: '147px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/phone@2x.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/phone@2x.png" data-no-retina data-reference="/images/newui/phone@2x.png" data-src-rs-ref="/images/newui/phone@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={764} height={409} />
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '666px', top: '6px', zIndex: 36, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '42.9948px', width: '27.9948px', opacity: 1, transformOrigin: '50% 50%', minHeight: '43px', minWidth: '28px', transform: 'perspective(600px) translate3d(0px, -7.1112px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-4" data-type="image" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-120px,-120px,-120px,30px;yo:6px,6px,6px,30px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:28px,28px,28px,30px;h:43px,43px,43px,46px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:600;sp:600;sR:600;" data-frame_999="o:0;st:w;sR:7800;" data-loop_999="y:-8px;sp:6000;st:1000;e:sine.inOut;yym:t;" style={{ zIndex: 36, color: 'rgb(255, 255, 255)', width: '28px', height: '43px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-1@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-1@2x.png" data-no-retina data-reference="/images/newui/pin-1@2x.png" data-src-rs-ref="/images/newui/pin-1@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={76} height={116} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '774px', top: '77px', zIndex: 33, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '51.9965px', width: '33.9931px', opacity: 1, transformOrigin: '50% 50%', minHeight: '52px', minWidth: '34px', transform: 'perspective(600px) translate3d(0px, -8.6994px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-5" data-type="image" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:-9px,-9px,-9px,30px;yo:77px,77px,77px,160px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:34px,34px,34px,30px;h:52px,52px,52px,46px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:1000;sp:600;sR:1000;" data-frame_999="o:0;st:w;sR:7400;" data-loop_999="y:-9px;sp:6000;st:2000;e:sine.inOut;yym:t;" style={{ zIndex: 33, color: 'rgb(255, 255, 255)', width: '34px', height: '52px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-2@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-2@2x.png" data-no-retina data-reference="/images/newui/pin-2@2x.png" data-src-rs-ref="/images/newui/pin-2@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={95} height={146} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '617px', top: '150px', zIndex: 30, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '65.9983px', width: '42.9948px', opacity: 1, transformOrigin: '50% 50%', minHeight: '66px', minWidth: '43px', transform: 'perspective(600px) translate3d(0px, -5.777px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-6" data-type="image" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-161px,-161px,-161px,30px;yo:150px,150px,150px,290px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:43px,43px,43px,30px;h:66px,66px,66px,46px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:1400;sp:600;sR:1400;" data-frame_999="o:0;st:w;sR:7000;" data-loop_999="y:-10px;sp:6000;st:3000;e:sine.inOut;yym:t;" style={{ zIndex: 30, color: 'rgb(255, 255, 255)', width: '43px', height: '66px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-3@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-3@2x.png" data-no-retina data-src-rs-ref="/images/newui/pin-3@2x.png" data-reference="/images/newui/pin-3@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={143} height={221} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '770px', top: '371px', zIndex: 24, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '86.9965px', width: '55.9983px', opacity: 1, transformOrigin: '50% 50%', minHeight: '87px', minWidth: '56px', transform: 'perspective(600px) translate3d(0px, -0.3674px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-7" data-type="image" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-2px,-2px,-2px,30px;yo:371px,371px,371px,550px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:56px,56px,56px,30px;h:87px,87px,87px,46px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:2200;sp:600;sR:2200;" data-frame_999="o:0;st:w;sR:6200;" data-loop_999="y:-11px;sp:6000;st:5000;e:sine.inOut;yym:t;" style={{ zIndex: 24, color: 'rgb(255, 255, 255)', width: '56px', height: '87px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-5@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-5@2x.png" data-no-retina data-src-rs-ref="/images/newui/pin-5@2x.png" data-reference="/images/newui/pin-5@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={220} height={341} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '758px', top: '534px', zIndex: 21, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '123.993px', width: '80px', opacity: 1, transformOrigin: '50% 50%', minHeight: '124px', minWidth: '80px', transform: 'perspective(600px) translate3d(0px, -5.0676px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-8" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:-2px,-2px,-2px,0;yo:534px,534px,534px,685px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:80px,80px,80px,30px;h:124px,124px,124px,47px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:2800;sp:600;sR:2800;" data-frame_999="o:0;st:w;sR:5600;" data-loop_999="y:-12px;sp:6000;st:6000;e:sine.inOut;yym:t;" style={{ zIndex: 21, color: 'rgb(255, 255, 255)', width: '80px', height: '124px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-6@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-6@2x.png" data-no-retina data-src-rs-ref="/images/newui/pin-6@2x.png" data-reference="/images/newui/pin-6@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={220} height={341} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '404px', top: '546px', zIndex: 12, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-10" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:-307px,-307px,-307px,-186px;yo:546px,546px,546px,412px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:177px,177px,177px,110px;h:183px,183px,183px,110px;" data-frame_0="x:-10px,-10px,-10px,-3px;" data-frame_1="st:3000;sp:800;sR:3000;" data-frame_999="o:0;st:w;sR:5200;" style={{ zIndex: 12, color: 'rgb(255, 255, 255)', width: '177px', height: '183px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/purchase@2x.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/purchase@2x.png" data-no-retina data-src-rs-ref="/images/newui/purchase@2x.png" data-reference="/images/newui/purchase@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={491} height={507} />
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '1009px', top: '546px', zIndex: 11, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-11" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:298px,298px,298px,186px;yo:546px,546px,546px,412px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:177px,177px,177px,110px;h:177px,177px,177px,110px;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:3400;sp:800;sR:3400;" data-frame_999="o:0;st:w;sR:4800;" style={{ zIndex: 11, color: 'rgb(255, 255, 255)', width: '177px', height: '177px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/exchange@2x.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/exchange@2x.png" data-no-retina data-src-rs-ref="/images/newui/exchange@2x.png" data-reference="/images/newui/exchange@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={492} height={492} />
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '713px', top: '756px', zIndex: 10, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-12" data-type="image" data-rsp_ch="on" data-xy="x:c;xo:2px,2px,2px,1px;yo:756px,756px,756px,542px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:177px,177px,177px,130px;h:177px,177px,177px,134px;" data-frame_0="y:20px,20px,20px,7px;" data-frame_1="st:3800;sp:800;sR:3800;" data-frame_999="o:0;st:w;sR:4400;" style={{ zIndex: 10, color: 'rgb(255, 255, 255)', width: '177px', height: '177px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/keep-driving@2x.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/keep-driving@2x.png" data-no-retina data-src-rs-ref="/images/newui/keep-driving@2x.png" data-reference="/images/newui/keep-driving@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={491} height={491} />
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '401px', top: '734px', zIndex: 20, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-13" data-type="text" data-color="#0e175f" data-rsp_ch="on" data-xy="x:c;xo:-306px,-306px,-306px,0;yo:734px,734px,734px,750px;" data-text="w:normal;s:35,35,35,22;l:36,36,36,22;fw:700,700,700,400;a:center;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:3100;sp:800;sR:3100;" data-frame_999="o:0;st:w;sR:5100;" style={{ zIndex: 20, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(14, 23, 95)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'center', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '35px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Purchase
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '1029px', top: '734px', zIndex: 18, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-14" data-type="text" data-color="#0e175f" data-rsp_ch="on" data-xy="x:c;xo:298px,298px,298px,0;yo:734px,734px,734px,860px;" data-text="w:normal;s:35,35,35,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:3500;sp:800;sR:3500;" data-frame_999="o:0;st:w;sR:4700;" style={{ zIndex: 18, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(14, 23, 95)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '35px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Return
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '683px', top: '943px', zIndex: 16, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-15" data-type="text" data-color="#0e175f" data-rsp_ch="on" data-xy="x:c;xo:1px,1px,1px,0;yo:943px,943px,943px,970px;" data-text="w:normal;s:35,35,35,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="y:20px,20px,20px,7px;" data-frame_1="st:3900;sp:800;sR:3900;" data-frame_999="o:0;st:w;sR:4300;" style={{ zIndex: 16, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(14, 23, 95)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '35px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Keep Driving
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '365px', top: '786px', zIndex: 19, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-16" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c;xo:-306px,-306px,-306px,0;yo:786px,786px,786px,780px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:center;" data-dim="w:258.562px,258.562px,258.562px,370px;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:3200;sp:800;sR:3200;" data-frame_999="o:0;st:w;sR:5000;" style={{ zIndex: 19, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '258px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'center', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Love it? Use the down payment you’ve built toward the purchase.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '671px', top: '996px', zIndex: 15, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-18" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c;yo:996px,996px,996px,1000px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:center;" data-dim="w:258.562px,258.562px,258.562px,370px;" data-frame_0="y:20px,20px,20px,7px;" data-frame_1="st:4000;sp:800;sR:4000;" data-frame_999="o:0;st:w;sR:4200;" style={{ zIndex: 15, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '258px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'center', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Not quite ready to decide? Keep driving your vehicle for as long as you want.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '896px', top: '82px', zIndex: 32, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-19" data-type="text" data-color="#2ea3ff" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:246px,246px,246px,80px;yo:82px,82px,82px,160px;" data-text="w:normal;s:30,30,30,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:1000;sp:800;sR:1000;" data-frame_999="o:0;st:w;sR:7200;" style={{ zIndex: 32, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(46, 163, 255)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '30px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">2. Select Your Car
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '969px', top: '786px', zIndex: 17, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-20" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c;xo:298px,298px,298px,0;yo:786px,786px,786px,890px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:center;" data-dim="w:258.562px,258.562px,258.562px,370px;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:3600;sp:800;sR:3600;" data-frame_999="o:0;st:w;sR:4600;" style={{ zIndex: 17, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '258px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'center', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">No longer need it? Return the vehicle at any time with no penalty.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '896px', top: '126px', zIndex: 31, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-21" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:261px,261px,261px,80px;yo:126px,126px,126px,190px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:left,left,left,right;" data-dim="w:329px,329px,329px,370px;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:1100;sp:800;sR:1100;" data-frame_999="o:0;st:w;sR:7100;" style={{ zIndex: 31, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '329px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Shop an ever-growing selection of new and used cars, trucks, SUVs, and electric vehicles on the app.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '260px', top: '153px', zIndex: 29, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-22" data-type="text" data-color="#2ea3ff" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-397px,-397px,-397px,80px;yo:153px,153px,153px,290px;" data-text="w:normal;s:30,30,30,22;l:30,30,30,22;fw:700,700,700,400;a:right,right,right,left;" data-dim="w:285px,285px,285px,338px;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:1400;sp:800;sR:1400;" data-frame_999="o:0;st:w;sR:6800;" style={{ zIndex: 29, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', color: 'rgb(46, 163, 255)', textDecoration: 'none', whiteSpace: 'normal', width: '285px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'right', lineHeight: '30px', letterSpacing: '0px', fontWeight: 700, fontSize: '30px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">3. Create Your Subscription
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '197px', top: '225px', zIndex: 28, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-23" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-429px,-429px,-429px,80px;yo:225px,225px,225px,320px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:right,right,right,left;" data-dim="w:348px,348px,348px,370px;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:1500;sp:800;sR:1500;" data-frame_999="o:0;st:w;sR:6700;" style={{ zIndex: 28, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '348px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'right', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Qualify based on income. Once you're approved, create the subscription that works for you! Credit issues are non-issues.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '213px', top: '0px', zIndex: 35, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-24" data-type="text" data-color="#2ea3ff" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-421px,-421px,-421px,80px;yo:0,0,0,30px;" data-text="w:normal;s:30,30,30,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:600;sp:800;sR:600;" data-frame_999="o:0;st:w;sR:7600;" style={{ zIndex: 35, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(46, 163, 255)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '30px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">1. Download The App
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '201px', top: '42px', zIndex: 34, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-25" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-427px,-427px,-427px,80px;yo:42px,42px,42px,60px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:right;" data-dim="w:343px,343px,343px,370px;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:700;sp:800;sR:700;" data-frame_999="o:0;st:w;sR:7500;" style={{ zIndex: 34, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '343px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'right', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Start your journey by downloading our intuitive app. Just a few taps and you're on your way.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '769px', top: '232px', zIndex: 27, visibility: 'visible', perspective: '601px' }}><rs-loop-wrap style={{ position: 'absolute', display: 'block', height: '80px', width: '51.9965px', opacity: 1, transformOrigin: '50% 50%', minHeight: '80px', minWidth: '52px', transform: 'perspective(600px) translate3d(0px, -1.111px, 0px)', filter: 'none' }}><rs-mask-wrap style={{ position: 'absolute', display: 'block', overflow: 'visible' }}><rs-layer id="slider-2-slide-2-layer-27" data-type="image" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:-5px,-5px,-5px,30px;yo:232px,232px,232px,420px;" data-text="w:normal;s:20,20,20,7;l:0,0,0,9;" data-dim="w:52px,52px,52px,30px;h:80px,80px,80px,46px;" data-frame_0="y:-20px,-20px,-20px,-7px;" data-frame_1="e:bounce.out;st:1800;sp:600;sR:1800;" data-frame_999="o:0;st:w;sR:6600;" data-loop_999="y:-10px;sp:6000;st:4000;e:sine.inOut;yym:t;" style={{ zIndex: 27, color: 'rgb(255, 255, 255)', width: '52px', height: '80px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/pin-4@2x.png" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/pin-4@2x.png" data-no-retina data-src-rs-ref="/images/newui/pin-4@2x.png" data-reference="/images/newui/pin-4@2x.png" style={{ position: 'relative', height: '100%', width: '100%' }} width={155} height={239} />
												</rs-layer></rs-mask-wrap></rs-loop-wrap></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '898px', top: '262px', zIndex: 26, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-28" data-type="text" data-color="#2ea3ff" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:238px,238px,238px,80px;yo:262px,262px,262px,420px;" data-text="w:normal;s:30,30,30,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:1800;sp:800;sR:1800;" data-frame_999="o:0;st:w;sR:6400;" style={{ zIndex: 26, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(46, 163, 255)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '30px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">4. Drive Your Car
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '898px', top: '306px', zIndex: 25, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-29" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c,c,c,r;xo:286px,286px,286px,80px;yo:306px,306px,306px,450px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:left,left,left,right;" data-dim="w:376px,376px,376px,370px;" data-frame_0="x:20px,20px,20px,7px;" data-frame_1="st:1900;sp:800;sR:1900;" data-frame_999="o:0;st:w;sR:6300;" style={{ zIndex: 25, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '376px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Insurance, maintenance, and roadside assistance included. Enjoy the ride while we take care of the details.
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '305px', top: '363px', zIndex: 23, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-30" data-type="text" data-color="#2ea3ff" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-310px,-310px,-310px,80px;yo:363px,363px,363px,550px;" data-text="w:normal;s:30,30,30,22;l:36,36,36,22;fw:700,700,700,400;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:2200;sp:800;sR:2200;" data-frame_999="o:0;st:w;sR:6000;" style={{ zIndex: 23, fontFamily: 'proxima-nova', textTransform: 'uppercase', fontStyle: 'italic', height: 'auto', width: 'auto', color: 'rgb(46, 163, 255)', textDecoration: 'none', whiteSpace: 'nowrap', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'left', lineHeight: '36px', letterSpacing: '0px', fontWeight: 700, fontSize: '30px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">5. Grow Down Payment
												</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '295px', top: '405px', zIndex: 22, visibility: 'visible', perspective: '601px' }}><rs-layer id="slider-2-slide-2-layer-31" data-type="text" data-color="#333333" data-rsp_ch="on" data-xy="x:c,c,c,l;xo:-315px,-315px,-315px,80px;yo:405px,405px,405px,580px;" data-text="w:normal;s:22,22,22,18;l:26,26,26,22;a:right,right,right,left;" data-dim="w:379px,379px,379px,370px;" data-frame_0="x:-20px,-20px,-20px,-7px;" data-frame_1="st:2300;sp:800;sR:2300;" data-frame_999="o:0;st:w;sR:5900;" style={{ zIndex: 22, fontFamily: 'proxima-nova', height: 'auto', color: 'rgb(51, 51, 51)', textDecoration: 'none', whiteSpace: 'normal', width: '379px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', textAlign: 'right', lineHeight: '26px', letterSpacing: '0px', fontWeight: 400, fontSize: '22px', transformOrigin: '50% 50%', opacity: 1, transform: 'translate(0px, 0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true">Up to 85% of every payment will build dollars toward your purchase if or when you choose to buy the vehicle.
												</rs-layer></rs-layer-wrap>
											</rs-slide>
										</rs-slides>
									</rs-module>
								</rs-module-wrap>
								<rs-fw-forcer style={{ height: '1150px' }}>
								</rs-fw-forcer>
							</rs-fullwidth-wrap>
						</div>
					</div>
					</div>
					<div className="is-layout-flow wp-block-group alignfull pt-5 pb-3 py-md-5 py-lg-0 bg-lg-pattern-fade-right" id="download-app" style={{ width: '1440px', marginLeft: '-87px' }}><div className="wp-block-group__inner-container container-lg">
						<div className="is-layout-flex wp-container-14 wp-block-columns are-vertically-aligned-center">
							<div className="is-layout-flow wp-block-column is-vertically-aligned-center py-lg-5 pe-xl-5" style={{ flexBasis: '45%' }}>
								<div className="is-layout-flex wp-container-8 wp-block-columns are-vertically-aligned-center is-not-stacked-on-mobile gap-4 mb-4">
									<div className="is-layout-flow wp-block-column is-vertically-aligned-center" style={{ flexBasis: '70px' }}>
										<figure className="wp-block-image size-full m-0">
											<img decoding="async" loading="lazy" src="/images/newui/d-icon.webp" alt="DriveItAway" className="wp-image-152" width={70} height={77} />
										</figure>
									</div>
									<div className="is-layout-flow wp-block-column is-vertically-aligned-center">
										<h2 className="m-0 highlight-primary has-secondary-color has-text-color">Download our<br /><em><strong>NEW</strong></em> Mobile App</h2>
									</div>
								</div>
								<p className="mb-lg-5 mb-4">Browse our available vehicles from wherever you are. Find your car, build your program, and drive with just a few taps.</p>
								<div className="is-layout-flex wp-container-11 wp-block-columns is-not-stacked-on-mobile pe-lg-6 pe-md-5">
									<div className="is-layout-flow wp-block-column">
										<figure className="wp-block-image size-full"><a href="https://apps.apple.com/us/app/driveitaway/id1393803514" target="_blank" rel="noreferrer noopener">
											<img decoding="async" loading="lazy" src="/images/newui/app-store.png" alt="" className="wp-image-80" srcSet="/images/newui/app-store.png 400w, /images/newui/app-store-300x89.png 300w" sizes="(max-width: 400px) 100vw, 400px" width={400} height={119} /></a></figure>
									</div>
									<div className="is-layout-flow wp-block-column">
										<figure className="wp-block-image size-full">
											<a href="https://play.google.com/store/apps/details?id=com.carshare" target="_blank" rel="noreferrer noopener">
												<img decoding="async" loading="lazy" src="/images/newui/play-store.png" alt="" className="wp-image-82" srcSet="/images/newui/play-store.png 404w, /images/newui/play-store-300x88.png 300w" sizes="(max-width: 404px) 100vw, 404px" width={404} height={119} /></a></figure>
									</div>
								</div>
							</div>
							<div className="is-layout-flow wp-block-column is-vertically-aligned-center">
								<div className="wp-block-themepunch-revslider revslider" data-modal="false" data-slidertitle="Mobile App" style={{}}>
									<rs-module-wrap id="rev_slider_3_3_wrapper" data-source="gallery" style={{ visibility: 'visible', background: 'transparent', padding: '0px', margin: '0px auto', height: '542px', display: 'block', width: 'auto', overflow: 'visible', transform: 'translate(0px)', top: '0px' }}><rs-module id="rev_slider_3_3" style={{ height: '542px', left: '0px', width: '633px' }} data-version="6.6.5" data-idcheck="true" className="revslider-initialised rev_redraw_on_blurfocus" data-slideactive="rs-4">
										<rs-slides style={{ overflow: 'hidden', position: 'absolute', visibility: 'visible', maxHeight: 'none', height: '100%', width: '100%' }}>
											<rs-slide style={{ position: 'absolute', display: 'block', overflow: 'hidden', height: '100%', width: '100%', zIndex: 20, opacity: 1, visibility: 'inherit' }} data-key="rs-4" data-title="Phone" data-in="o:0;" data-out="a:false;" data-originalindex={1} data-origindex={0} data-description data-sba data-scroll-based="false" data-owidth={300} data-oheight={200} data-rspausetimeronce={0} data-isactiveslide="true"><rs-sbg-px><rs-sbg-wrap data-key="rs-4" data-owidth={300} data-oheight={200}>
												<img src="/images/newui/dummy.png" alt="Phone" title="Home" class="rev-slidebg tp-rs-img rs-lazyload" data-lazyload="/images/newui/transparent.png" data-parallax="off" data-no-retina="" />
												<rs-sbg data-lazyload="/images/newui/transparent.png" className data-bgcolor="transparent" style={{ width: '100%', height: '100%' }} data-src-rs-ref="/images/newui/transparent.png"><canvas style={{ width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 1, filter: 'none' }} width={633} height={542} /></rs-sbg></rs-sbg-wrap></rs-sbg-px><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '125px', top: '72px', zIndex: 9, visibility: 'visible' }}>
													<rs-layer id="slider-3-slide-4-layer-1" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:101px,101px,101px,88px;y:b;" data-text="w:normal;" data-dim="w:['432px','432px','432px','432px'];h:['500px','500px','500px','500px'];" data-rsp_o="off" data-frame_1="sp:800;" data-frame_999="o:0;st:w;sR:8200;" style={{ zIndex: 9, color: 'rgb(255, 255, 255)', width: '407px', height: '470px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50% 0px', opacity: 1, transform: 'translate(0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/phone-app-layer1.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/phone-app-layer1.webp" data-no-retina data-reference="/images/newui/phone-app-layer1.webp" data-src-rs-ref="/images/newui/phone-app-layer1.webp" style={{ position: 'relative', height: '100%', width: '100%' }} width={419} height={485} />
													</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '223px', top: '41px', zIndex: 10, visibility: 'visible' }}><rs-layer id="slider-3-slide-4-layer-2" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:81px,81px,81px,68px;y:b;yo:31px;" data-text="w:normal;" data-dim="w:['350px','350px','350px','350px'];h:['500px','500px','500px','500px'];" data-rsp_o="off" data-ford="frame_0;frame_1;frame_2;frame_999;" data-frame_0="x:-32px;y:30px;" data-frame_1="x:inherit;y:inherit;sp:800;" data-frame_999="o:0;st:w;sR:7200;" data-frame_2="x:0px;y:0px;oX:50%;oY:50%;oZ:0;tp:600;st:1000;sp:1000;" style={{ zIndex: 10, color: 'rgb(255, 255, 255)', width: '329px', height: '470px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50% 0px', opacity: 1, transform: 'translate(0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/phone-app-layer2.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/phone-app-layer2.webp" data-no-retina data-reference="/images/newui/phone-app-layer2.webp" data-src-rs-ref="/images/newui/phone-app-layer2.webp" style={{ position: 'relative', height: '100%', width: '100%' }} width={338} height={483} />
													</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '263px', top: '19px', zIndex: 11, visibility: 'visible' }}><rs-layer id="slider-3-slide-4-layer-3" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:41px,41px,41px,28px;y:b;yo:53px;" data-text="w:normal;" data-dim="w:['350px','350px','350px','350px'];h:['500px','500px','500px','500px'];" data-rsp_o="off" data-ford="frame_0;frame_1;frame_2;frame_999;" data-frame_0="x:-71px;y:52px;" data-frame_1="x:inherit;y:inherit;sp:800;sR:100;" data-frame_999="st:w;sR:6610;" data-frame_2="x:0px;y:0px;oX:50%;oY:50%;oZ:0;tp:600;st:900;sp:1000;sR:490;" style={{ zIndex: 11, color: 'rgb(255, 255, 255)', width: '329px', height: '470px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50% 0px', opacity: 1, transform: 'translate(0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/phone-app-layer3.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/phone-app-layer3.webp" data-no-retina data-reference="/images/newui/phone-app-layer3.webp" data-src-rs-ref="/images/newui/phone-app-layer3.webp" style={{ position: 'relative', height: '100%', width: '100%' }} width={338} height={483} />
													</rs-layer></rs-layer-wrap><rs-layer-wrap className="rs-parallax-wrap" style={{ position: 'absolute', display: 'block', pointerEvents: 'auto', left: '305px', top: '-4px', zIndex: 12, visibility: 'visible' }}><rs-layer id="slider-3-slide-4-layer-4" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:-1px,-1px,-1px,-14px;y:b;yo:73px;" data-text="w:normal;" data-dim="w:['350px','350px','350px','350px'];h:['503px','503px','503px','503px'];" data-rsp_o="off" data-ford="frame_0;frame_1;frame_2;frame_999;" data-frame_0="x:-114px;y:73px;" data-frame_1="x:inherit;y:inherit;sp:800;" data-frame_999="o:0;st:w;sR:6480;" data-frame_2="x:0px;y:0px;oX:50%;oY:50%;oZ:0;tp:600;st:800;sp:1000;sR:720;" data-frame_hover="oX:10%;sp:600ms;iC:true;" style={{ zIndex: 12, color: 'rgb(255, 255, 255)', width: '329px', height: '473px', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', transformOrigin: '50% 50% 0px', opacity: 1, transform: 'translate(0px)', visibility: 'visible' }} className="rs-layer" data-idcheck="true" data-stylerecorder="true" data-initialised="true"><img src="/images/newui/phone-app-layer4.webp" alt="" className="tp-rs-img rs-lazyload" data-lazyload="/images/newui/phone-app-layer4.webp" data-no-retina data-reference="/images/newui/phone-app-layer4.webp" data-src-rs-ref="/images/newui/phone-app-layer4.webp" style={{ position: 'relative', height: '100%', width: '100%' }} width={337} height={484} />
													</rs-layer></rs-layer-wrap></rs-slide>
										</rs-slides><rs-progress style={{ visibility: 'hidden' }}><rs-progress-bar style={{ background: 'rgba(255, 255, 255, 0.5)' }} /></rs-progress></rs-module>
									</rs-module-wrap>
								</div>
							</div>
						</div>
					</div>
					</div>
					<div className="is-layout-flow wp-block-group alignfull py-4 bg-pattern-tl-dots bg-pattern-br-dots has-white-color has-secondary-background-color has-text-color has-background" id="testimonial" style={{ width: '1440px', marginLeft: '-87px' }}>
						<div className="wp-block-group__inner-container container-lg">
							<div className="strong-view strong-view-id-2 unstyled wpmtst-unstyled slider-container slider-mode-horizontal slider-adaptive" data-count={30} data-slider-var="strong_slider_id_2" data-state="init">
								<div className="wpmslider-wrapper" style={{ maxWidth: '100%', margin: '0px auto' }}>
									<div className="wpmslider-viewport" aria-live="polite" style={{ width: '100%', overflow: 'hidden', position: 'relative', height: '237.733px' }}>
										<div className="strong-content wpmslider-content" style={{ width: '30215%', position: 'relative', transitionDuration: '0s', transform: 'translateX(-1267px)' }}>
											<div className="wpmtst-testimonial testimonial t-slide post-493" style={{ float: 'left', listStyle: 'none', position: 'relative', width: '1266px', marginRight: '1px', display: 'block', height: 'auto' }} aria-hidden="true">
												<div className="wpmtst-testimonial-inner testimonial-inner">
													<div data-infinite-loop="false" className="wpmtst-testimonial-content testimonial-content">
														<div className="maybe-clear" />
														<p>I am so happy for the opportunity to work with Emanuel at DriveItAway; he is certainly a breadth of fresh air…I am quite elated to give him and his staff a “5-Star”, as he and staff are professional, courteous, patient, and understanding… Great team to work with…Keep up the great work!!!</p>
													</div>
													<div className="wpmtst-testimonial-field testimonial-field testimonial-name">Lo Robinson</div>
													<div className="clear" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="is-layout-flow wp-block-group alignfull py-5 has-secondary-color has-primary-background-color has-text-color has-background" id="news-events" style={{ width: '1440px', marginLeft: '-87px' }}><div className="wp-block-group__inner-container container-lg">
						<div className="is-layout-flex wp-container-20 wp-block-columns break-lg">
							<div className="is-layout-flow wp-block-column d-flex flex-column">
								<h2 className="mb-2 has-white-color has-text-color" style={{ fontSize: '46px' }}><strong>In the News</strong></h2>
								<p className="lead">Stay on top of all the news at DriveItAway.</p>
								<ul className="wp-block-latest-posts__list is-grid columns-2 has-dates flex-grow-1 wp-block-latest-posts"><li><a className="wp-block-latest-posts__post-title" href="https://www.wardsauto.com/dealers/will-oems-compete-dealers-used-car-sales">Will OEMs Compete With Dealers for Used Car Sales?</a><time dateTime="2022-09-23T13:39:00-04:00" className="wp-block-latest-posts__post-date">September 23, 2022</time><div className="wp-block-latest-posts__post-excerpt">While the new-car “agency” model is getting all of the attention, there are other disturbing trends afoot in some global OEMs’ efforts to “remake” distribution that so far in the U.S. have gone unnoticed. One is the control and retailing of used vehicles.</div></li>
									<li><a className="wp-block-latest-posts__post-title" href="https://www.wardsauto.com/dealers/driveitaway-reaches-entry-level-bev-drivers">DriveItAway Reaches Entry-Level BEV Drivers</a><time dateTime="2022-09-12T12:38:00-04:00" className="wp-block-latest-posts__post-date">September 12, 2022</time><div className="wp-block-latest-posts__post-excerpt">The Subscription Service gives dealers “a second bite at the apple” with the opportunity to sell a new car, service it and perhaps sell the car as used.</div></li>
								</ul></div>
							<div className="is-layout-flow wp-block-column">
								<h2 className="mb-2 has-white-color has-text-color" style={{ fontSize: '46px' }}><strong>Come Join Us!</strong></h2>
								<p className="lead">Check where we will be next below!</p>
								<div className="is-layout-flow wp-block-group py-2 px-3 py-lg-3 px-lg-4 has-white-color has-secondary-background-color has-text-color has-background"><div className="wp-block-group__inner-container">
									<div className="mec-wrap mec-skin-list-container mec-sm959" id="mec_skin_103">
										<div className="mec-skin-list-events-container" id="mec_skin_events_103">
											<div className="mec-wrap colorskin-custom mec-sm959">
												<div className="mec-event-list-minimal">
													<article className="mec-event-article  mec-clear  mec-divider-toggle mec-toggle-202211-103" itemScope>
														<div className="row">
															<div className="col-4 col-sm-3 d-flex flex-column float-none">
																<div className="mec-event-date mec-bg-color m-0">
																	<span className="mec-multiple-dates">14 - 17</span>
																	Nov									</div>
															</div>
															<div className="col-8 col-sm-9 d-flex flex-column gap-2 justify-content-center ps-1 ps-sm-2 float-none">
																<div className="d-sm-flex gap-3 justify-content-between">
																	<div className="title-wrapper">
																		<h4 className="mec-event-title"><a className="mec-color-hover" data-event-id={322} href="https://usedcarweek.biz/" target="_blank" rel="noopener">Used Car Week 2022</a></h4>
																	</div>
																	<div className="btn-wrapper mt-2 mt-sm-0">
																		<a className="mec-detail-button" data-event-id={322} href="https://usedcarweek.biz/" target="_blank" rel="noopener">EVENT DETAIL</a>									</div>
																</div>
																<div className="mec-event-detail">
																	<p className="m-0 text-white-50 lh-sm text-small">John F. Possumato, CEO of DriveItAway will moderate “Panel Discussion: EVs, Subscription Models: Manufacturing Used Cars” CX”</p>
																</div>
															</div>
														</div>
													</article>
												</div>
											</div>
										</div>
										<div className="mec-skin-list-no-events-container mec-util-hidden" id="mec_skin_no_events_103">
											There are no upcoming events scheduled at this time. Please check back soon.  </div>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
					</div>
					<div id="what-you-get" className="is-layout-flow wp-block-group py-lg-6 py-5"><div className="wp-block-group__inner-container">
						<div className="is-layout-flex wp-container-24 wp-block-columns are-vertically-aligned-center mb-4">
							<div className="is-layout-flow wp-block-column is-vertically-aligned-center">
								<figure className="wp-block-image size-full">
									<img decoding="async" loading="lazy" src="/images/newui/homepage-vehicle2.png" alt="Mobile Phone Automotive Tools" className="wp-image-94" srcSet="/images/newui/homepage-vehicle2.png 646w, /images/newui/homepage-vehicle2.png 300w" sizes="(max-width: 646px) 100vw, 646px" width={646} height={375} /></figure>
							</div>
							<div className="is-layout-flow wp-block-column is-vertically-aligned-center ps-lg-6 ps-md-5">
								<h2 className="me-lg-6 pb-4 mb-4 border-bottom border-primary border-4 highlight-primary has-secondary-color has-text-color">What Do You Get With<br /><em><strong>DRIVEITAWAY?</strong></em></h2>
								<p className="mb-0">Welcome to the new way to buy a car. Start with a rental and you’re ready to go. Drive the car for as long—or as little—as you like before deciding if you’ll buy. No down payment, no credit, and no commitment required upfront.</p>
							</div>
						</div>
						<div className="is-layout-flex wp-container-27 wp-block-columns mb-4">
							<div className="is-layout-flow wp-block-column">
								<div className="wp-block-media-text" style={{ gridTemplateColumns: '22% auto' }}><figure className="wp-block-media-text__media"><img decoding="async" loading="lazy" src="/images/newui/insurance-icon.webp" alt="Protection Shield" className="wp-image-96 size-full" srcSet="/images/newui/insurance-icon.webp 282w, /images/newui/insurance-icon.webp 150w" sizes="(max-width: 282px) 100vw, 282px" width={282} height={282} /></figure><div className="wp-block-media-text__content">
									<p style={{ fontSize: '24px' }}>Insurance, full maintenance<br />&amp; roadside assistance included.</p>
								</div></div>
							</div>
							<div className="is-layout-flow wp-block-column">
								<div className="wp-block-media-text" style={{ gridTemplateColumns: '22% auto' }}><figure className="wp-block-media-text__media"><img decoding="async" loading="lazy" src="/images/newui/car-icon.webp" alt="Vehicle Icon" className="wp-image-98 size-full" srcSet="/images/newui/car-icon.webp 242w, /images/newui/car-icon.webp 150w" sizes="(max-width: 242px) 100vw, 242px" width={242} height={242} /></figure><div className="wp-block-media-text__content">
									<p style={{ fontSize: '24px' }}>All usage fees can contribute to a down payment if you choose to buy the car</p>
								</div></div>
							</div>
						</div>
						<div className="is-layout-flex wp-container-30 wp-block-columns">
							<div className="is-layout-flow wp-block-column">
								<div className="wp-block-media-text" style={{ gridTemplateColumns: '22% auto' }}><figure className="wp-block-media-text__media"><img decoding="async" loading="lazy" src="/images/newui/drive-icon.webp" alt="Steering Wheel" className="wp-image-100 size-full" srcSet="/images/newui/drive-icon.webp 242w, /images/newui/drive-icon.webp 150w" sizes="(max-width: 242px) 100vw, 242px" width={242} height={242} /></figure><div className="wp-block-media-text__content">
									<p style={{ fontSize: '24px' }}>Drive now, decide later<br />whether you want to buy.</p>
								</div></div>
							</div>
							<div className="is-layout-flow wp-block-column">
								<div className="wp-block-media-text" style={{ gridTemplateColumns: '22% auto' }}><figure className="wp-block-media-text__media"><img decoding="async" loading="lazy" src="/images/newui/check-icon.webp" alt="Check Mark" className="wp-image-101 size-full" srcSet="/images/newui/check-icon.webp 242w, /images/newui/check-icon.webp 150w" sizes="(max-width: 242px) 100vw, 242px" width={242} height={242} /></figure><div className="wp-block-media-text__content">
									<p style={{ fontSize: '24px' }}>Cancel anytime or convert to ownership when it’s right for you!</p>
								</div></div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		)
	}
}
export default Home;