import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Page from "./templates/Page";
import ReactSEO from "react-seo";
import axios from "axios";
 import TagManager from 'react-gtm-module';
//new code added by vinod
const tagManagerArgs = {
    gtmId: 'GTM-W9H83DG'
}

//TagManager.initialize(tagManagerArgs)
//vinod code end here
ReactSEO.startMagic(
  [
    {
      url: "/drivers/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/drivers\/|\/[^\/]+)/g],
    },
    {
      url: "/dealers/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/dealers\/|\/[^\/]+)/g],
    },
    {
      url: "/evprogram/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/evprogram\/|\/[^\/]+)/g],
    },
    {
      url: "/how-it-works/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/how-it-works\/|\/[^\/]+)/g],
    },
    {
      url: "/faq/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/faq\/|\/[^\/]+)/g],
    },
    {
      url: "/contact-us/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/contact-us\/|\/[^\/]+)/g],
    },
    {
      url: "/investor-relations/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/investor-relations\/|\/[^\/]+)/g],
    },
    {
      url: "/cars/*",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/cars\/|\/[^\/]+)/g],
    },
    {
      url: "/car/details/*",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/car\/details\/|\/[^\/]+)/g],
    },
    
    {
      url: "/thank-you/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/thank-you\/|\/[^\/]+)/g],
    },
    {
      url: "/chat/",
      isFullMatch: false,
      ajaxFunction: Page,
      urlParams: [/(.+\/chat\/|\/[^\/]+)/g],
    },
  ],
  renderDOMNew
);

function renderDOMNew() {
  ReactDOM.render(<App />, document.getElementById("root"));
}

function ajaxFunction(param, resolve) {
  axios.get(`/api?param=${param}`).then((response) => {
    this.emit("gameDataIsInDaHouse");
    if (resolve)
      // IMPORTANT! call resolve only if it was passed.
      resolve();
  });
}
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
