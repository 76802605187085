import React, { useState, useMemo } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const BuyNow = ({ carDetail, goNext }) => {
  //   const history = useHistory();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");

  useMemo(() => {
    setFormattedDate(moment(selectedDate._d).format("h:mm a - Do MMMM"));
  }, [selectedDate]);

  const proceedNext = () => {
    const data = {
      list_id: carDetail.vehicle_id,
      startdatetime: selectedDate.toISOString(),
      days: 7,
      owner_id: carDetail.owner_id,
      rental_options: 0,
      initial_fee_options: 0,
      miles_options: 0,
      emf_options: 0,
      program: "rideshare",
      financing: "buy",
    };
    goNext(data);
  };
  const getHighlights = () => {
    if (!carDetail.BuyNowHighlight.length) return null;
    return carDetail.BuyNowHighlight.map((obj, index) => {
      return (
        <li className="blue_text" key={"high-" + index}>
              <i className="fa fa-check fa-2x pink_text" aria-hidden="true" />
              <span>
                {obj.title}
              </span>
            </li>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="work_head pay_usage center_line text-center">
        <h2 className="white_text head_h2 small_h2">Buy Now Reservation</h2>
        <div className="head_line" />
      </div>
      <div className="row">
        <div className="col-md-6">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              value={selectedDate}
              onChange={handleDateChange}
              open={openDatePicker}
              className="d-none"
              disablePast
              onAccept={() => setOpenDatePicker(!openDatePicker)}
              onClose={() => setOpenDatePicker(!openDatePicker)}
              format="hh:mm a mm yyyy"
            />
          </MuiPickersUtilsProvider>
          <div
            className="form-control d-flex align-items-center justify-content-between pick_calendar cursor-pointer mb-3"
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            <span>
              <img src="/images/ic-actions-calendar.svg" alt="" />
              I’II Pickup at :
            </span>
            <span>{formattedDate}</span>
          </div>
          <div className="highlight_box">
            <h2 className="blue_text head_h2 small_h2">Highlights</h2>
            <ul>
              {getHighlights()}
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-8">
              <div className="due_box buld_prog">
                <h3>{carDetail ? carDetail.buynowprice : 0} </h3>
                <p>*Reservation Fee</p>
              </div>
            </div>
            <div className="col-sm-2" />
          </div>

          <div className="center_btn">
            <button className="btn w-100" onClick={proceedNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BuyNow;
