import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export const GoogleAutocomplete = ({ handleChange }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const url = window.location.href;
  const paramsString = url.split('/iframecars')[1];
  const params = new URLSearchParams(paramsString.replace(/&/g, '&').replace(/=/g, '='));
  const lat = params.get('/lat');
  const lng = params.get('lng');
  const isLocal=params.get('islocal');
  
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

   React.useEffect(() => {
    if (window.google && isLocal==='true') {
      let latlng = new window.google.maps.LatLng(lat, lng);
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            setValue(results[0].formatted_address)
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
    }
   }, [isLocal]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue,componentRestrictions: {country: ['us','ca']},types: ['geocode'] }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          console.log('results',results)
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) {
          let geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'address': newValue.description }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
              if (results && results[0]) {
                const location = results[0].geometry.location;
                handleChange({ latitude: location.lat(), longitude: location.lng() })
              } else {
                console.log('No results found');
              }
            } else {
              console.log('lat lng failed due to: ' + status);
            }
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} InputProps={{...params.InputProps, disableUnderline: true}} fullWidth placeholder="Your City..."/>
      )}
      style={{ 'border': "2px solid #2ea3ff", 'borderRadius': "10px", 'padding': '0 10px', 'backgroundColor': "white" }}
    />
  );
};