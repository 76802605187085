import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { login } from "../../services/AuthApi";
import { phoneRegExp } from "../../utils/constants";
import { ErrorDialog } from "../Dialogs";

const LoginSchema = Yup.object().shape({
  phone_number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter mobile number"),
  /*acceptTerms: Yup.bool()
    .required("You must accept the terms and conditions")
    .oneOf([true], "The terms and conditions must be accepted."),*/
});

const LoginOTP = ({ onSuccess }) => {
  const link_container_ref = useRef();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasNoAccount, setHasNoAccount] = useState(false);

  const openLink = (url_link) => {
    if (url_link && link_container_ref.current) {
      const link = document.createElement("a");
      link.href = url_link;
      link.target = "_blank";
      link_container_ref.current.appendChild(link);
      link.click();
      link_container_ref.current.removeChild(link);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const res = await login(values);
      setSubmitting(false);
      if (res && res.status === 200) {
        /* user is registered but not verified */
        if (res.data && res.data.status === 3) {
          openLink(res.data.redirect);
          return;
        }
        if (res.data.status === 2 && res.data.verify_required === 1) {
          onSuccess(3);
        } else if (res.data.status === 2 && res.data.verify_required === 0) {
          setErrorMessage(res.data.message);
          setError(true);
        } else if (res.data.status === 1 && res.data.verify_required === 1) {
          onSuccess(2, res.data.user_id);
        } else if (res.data.status === 0 && res.data.verify_required === 0) {
          setErrorMessage(res.data.message);
          setError(true);
          setHasNoAccount(true);
        }
      }
    } catch (e) {
      setSubmitting(false);
      setError(true);
    }
  };

  const onDialogClose = () => {
    setError(false);
    setErrorMessage("");
    if (hasNoAccount) {
      window.location.href = "/register";
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{ phone_number: "", acceptTerms: false }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ touched, errors, submitForm }) => (
          <div className="position-relative d-flex align-items-center">
            <div className="w-100">
              <div className="account_box" id="show_hide_password">
                <h4 className="blue_text">Welcome Back!</h4>
                <div className="row">
                  <div className="form-group col-md-12 mob_no">
                    <label className="form_lbl">Mobile Number</label>
                    <Field
                      type="text"
                      name="phone_number"
                      className={`form-control ${
                        touched.phone_number && errors.phone_number
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder=""
                      maxLength="10"
                    />
                    <span>
                      <img src="img/india.png" alt="" />
                      +1
                    </span>
                    
                  </div>
                  
                  {errors.phone_number && touched.phone_number ? (
                    <div className="d-block invalid-feedback mt-n4 ml-3">
                      {errors.phone_number}
                    </div>
                  ) : null}
                </div>
                
              </div>
              <div className="agreement_div">
                <p className="text-right">
                      <Link className="text-danger" to="/forgot-password" title="">
                        Forgot Password?
                      </Link>
                  </p>
                {/*
                <div className="custom-control custom-radio">
                  <Field
                    type="checkbox"
                    id="customRadio1"
                    name="acceptTerms"
                    className="custom-control-input login-checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadio1"
                  >
                    I have read and agree to the following
                  </label>
                  <a
                    className="blue_text agreement"
                    href="https://www.driveitaway.com/app-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;&&nbsp;
                  <a
                    className="blue_text agreement"
                    href="https://www.driveitaway.com/app-privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  {errors.acceptTerms && touched.acceptTerms ? (
                    <div className="d-block invalid-feedback ml-1">
                      {errors.acceptTerms}
                    </div>
                  ) : null}
                </div> */}
              </div>
              <div>
                <button
                  type="submit"
                  className="btn update_btn w-100"
                  onClick={submitForm}
                  disabled={submitting}
                >
                  Login
                </button>
              </div>
              <h6 className="text-center login_account">
                Don’t have an account?&nbsp;
                <Link className="blue_text" to="/register" title="">
                  Register
                </Link>
              </h6>
            </div>
          </div>
        )}
      </Formik>
      {submitting && <Loader />}
      {error && <ErrorDialog message={errorMessage} onClose={onDialogClose} />}
      <div ref={link_container_ref} />
    </React.Fragment>
  );
};

export default LoginOTP;
