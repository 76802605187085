import React, { useEffect, useCallback, useRef, useState } from "react";
import  "../App.css"

import IframeCarListing from "../pages/IframeCarListing";
import IframeCarDetails from "../pages/IframeCarDetails";
import Home from "../pages/Home";
import IHeader from "./IHeader";
import IFooter from "./IFooter";
import DownloadAppDialog from "../blocks/Dialogs/DownloadAppDialog";
function IframePage(props) {
  const getPage = () => {
    switch (props.page) {
      case 'homepage':
        return <Home/>;
      case "cars":
        return <IframeCarListing params={props.match.params} />;
      case "cardetails":
        return <IframeCarDetails params={props.match.params} />;
      
      default:
        return <IframeCarListing params={props.match.params}/>;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.darkHeader(false);
  }, []);

  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const toggleSticky = useCallback(
    ({ top, bottom }) => {
      if (top <= -80) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }
    },
    [isSticky]
  );

  useEffect(() => {
    const handleScroll = () => {
      toggleSticky(headerRef.current.getBoundingClientRect());
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);

  return (
    
    <div>
      <IHeader/>
      <div ref={headerRef}>
        {getPage()}
      </div>
      <IFooter/>
      <DownloadAppDialog />
    </div>
  );
}
export default IframePage;
