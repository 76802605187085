import React, { Component } from "react";

class Pagination extends Component {
  state = {
    pages: this.props.pages,
    currentPage: this.props.currentPage,
    records: this.props.records,
    limit: this.props.limit,
  };

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({
      pages: nextProps.pages,
      currentPage: nextProps.currentPage,
      records: nextProps.records,
      limit: nextProps.limit,
    });
  }
  updatePagination = (pagination, event) => {
    event.preventDefault();
    if (pagination === this.state.currentPage) return null;
    this.props.updatePagination(pagination);
  };
  prevPage = (event) => {
    event.preventDefault();
    if (this.state.currentPage > 1)
      this.props.updatePagination(this.state.currentPage - 1);
  };
  nextPage = (event) => {
    event.preventDefault();
    if (this.state.currentPage < this.state.pages)
      this.props.updatePagination(this.state.currentPage + 1);
  };

  genaratePagination = () => {
    var currentPage = parseInt(this.state.currentPage);
    var pageNavs = [];
    // var totalPages = this.state.pages;
    var nextPages = this.state.pages - currentPage;
    let start = 1;
    if (nextPages >= 2) {
      start = currentPage - 2;
    } else {
      start = this.state.pages - 4;
    }
    var end = start + 4 > this.state.pages ? this.state.pages : start + 4;
    for (var i = start; i <= end; i++) {
      if (i > 0) {
        pageNavs.push(
          <li
            key={"page-" + i}
            className={i === currentPage ? "active page-item" : " page-item"}
          >
            <span
              className="page-link cursor-pointer textblue"
              onClick={this.updatePagination.bind(this, i)}
            >
              {i}
            </span>
          </li>
        );
      }
    }
    return pageNavs;
  };
  showingResult = () => {
    var currentPage = parseInt(this.state.currentPage);
    var limit = parseInt(this.state.limit);

    var records = parseInt(this.state.records);
    var pagefrom = parseInt((currentPage - 1) * limit);
    var pageto = parseInt(currentPage * limit);
    pageto = pageto > records ? records : pageto;
    return (
      <label className="textblue">
        {pagefrom?parseInt(pagefrom+1):1} - {pageto} of {records}
      </label>
    );
  };
  render() {
    return (
      <div className="sort_box pagi_nation mt-0">
        <div className="row">
          <div className="col-6">{this.showingResult()}</div>
          <div className="col-6">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end align-items-center">
                <li className="page-item">
                  <span
                    className="page-link cursor-pointer textblue"
                    onClick={this.prevPage.bind(this)}
                  >
                    Previous
                  </span>
                </li>
                {this.genaratePagination()}
                <li className="page-item">
                  <span
                    className="page-link cursor-pointer textblue"
                    onClick={this.nextPage.bind(this)}
                  >
                    Next
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
export default Pagination;
