import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { PayAsYouGo, BuyNow } from "../blocks/RentTownBlocks";
import { LoginModal } from "../blocks/Modals";
import { toggleLoginModal } from "../store/actions/GlobalActions";
import { setRentTownCar, removeRentTownCar } from "../store/actions/CarAction";

const RentTown = ({
  carDetail,
  userLoggedIn,
  toggleLoginModal,
  saveReenTownData,
  unsetReenTownData,
}) => {
  const [isPTO, setIsPTO] = useState(true);

  useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    unsetReenTownData();
  }, []);

  const goPTONext = (data) => {
    if (!userLoggedIn) {
      toggleLoginModal();
      return;
    }
    if (data !== null) {
      saveReenTownData(data);
      window.location.href = "/confirm-reservation";
    } else {
      window.history.back();
    }
  };

  const goBuyNext = (data) => {
    if (!userLoggedIn) {
      toggleLoginModal();
      return;
    }
    if (data !== null) {
      saveReenTownData(data);
      window.location.href = "/confirm-buy-reservation";
    } else {
      window.history.back();
    }
  };

  return (
    <React.Fragment>
      <section className="buildP_sec">
        <div className="container container_1170">
          <h2 className="pink_text head_h2 buildP_head text-center">
            Build Program
          </h2>
          <div className="row mb-3">
            <div className="col-6 d-inline-flex ">
              <div className="custom-control custom-radio customR_box input-checked w-100">
                <input
                  type="checkbox"
                  id="customRadio1"
                  name="customRadio1"
                  className={`custom-control-input`}
                  checked={isPTO}
                  onChange={() => setIsPTO(true)}
                />
                <label
                  className={`custom-control-label ${
                    isPTO ? "input-checked" : ""
                  }`}
                  htmlFor="customRadio1"
                >
                  DriveItAway Pay As You Go
                </label>
              </div>
            </div>
            <div className="col-6 d-inline-flex">
              <div className="custom-control custom-radio customR_box w-100">
                <input
                  type="checkbox"
                  id="customRadio2"
                  name="customRadio2"
                  className="custom-control-input"
                  checked={!isPTO}
                  onChange={() => setIsPTO(false)}
                />
                <label
                  className={` righttext custom-control-label ${
                    !isPTO ? "input-checked" : ""
                  }`}
                  htmlFor="customRadio2"
                >
                  Buy Now
                </label>
              </div>
            </div>
          </div>

          {isPTO ? (
            <PayAsYouGo carDetail={carDetail} goNext={goPTONext} />
          ) : (
            <BuyNow carDetail={carDetail} goNext={goBuyNext} />
          )}
        </div>
      </section>
      <LoginModal />
    </React.Fragment>
  );
};

const mapStateToPops = (state) => {
  const { carDetail } = state.cars;
  const { userLoggedIn } = state.auth;
  return { carDetail, userLoggedIn };
};

const mapDispatchToPops = (dispatch) => {
  return {
    toggleLoginModal: () => dispatch(toggleLoginModal()),
    saveReenTownData: (data) => dispatch(setRentTownCar(data)),
    unsetReenTownData: () => dispatch(removeRentTownCar()),
  };
};

export default connect(
  mapStateToPops,
  mapDispatchToPops
)(RentTown);
