import React from "react";
import { NavLink } from "react-router-dom";
import HomeAppBottom from "../../blocks/HomeAppBottom";

function Carite() {
  return (
    <div>
      <section className="landing_bnr" style={{padding:"55px 0 150px"}}>
        <div className="container">
          <div className="landBnrtxt text-sm-left text-center">
            <h6 className="mt-4" style={{color:"#EC37A0"}}>Feeling Stalled Because You Don’t Have Enough $ Down?</h6>
            <h5 className="mt-4"> We Have the Soution</h5>
            
          </div>
          <div className="landBnr_logo text-sm-left text-center mt-4">
            <a href="#" title="">
              <img className="w-xs-75" src="/images/top-logo.png" alt="" />
            </a>
            <span className="plus">
              <img className="mb-2 mb-sm-0" src="/images/plus.png" alt="" />
            </span>
            <a href="#" title="">
              <img  src="/images/Carite-logo.png" alt="" />
            </a>
          </div>
          <div className="landBnr_txt text-sm-left text-center">
            <h6 className="mt-4" style={{color:"#EC37A0"}}>Have Teamed Up To Help You Buy the Car of Your Dreams</h6>
            <h1 className="head_h1">
            Offering Your Down Payment Assistance Program
            </h1>
            
            <NavLink to="/cars" className="mt-5">
              <button type="button" className="btn pink_btn">
                Get Started
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      

      <section className="hoWork_sec">
        <div className="container">
          <h2 className="blue_text head_h2 text-center">How it Works</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="hoWork_img">
                <img src="/images/work_img.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-sm-0 mb-5 mb-sm-0">
              <div className="hoWork_txt text-sm-left text-center">
                <h2 className="head_h2">No Commitment Pay As You Go</h2>
                <p className="font_18 poppins_font">
                  Start in a rental contract with no commitment and as you drive
                  each payment contributes to a down payment.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-md-6">
              <div className="hoWork_img">
                <img src="/images/work_img2.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-sm-0 mb-5 mb-sm-0">
              <div className="hoWork_txt text-sm-left text-center">
                <h2 className="head_h2">Make Life Easier Today AND Tomorrow </h2>
                <p className="font_18 poppins_font">
                  Capture all of the benefits of rental, while building towards
                  potential ownership with each mile that you drive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="whyRent_sec">
        <div className="container">
          <div className="whyRent_txt  text-center">
            <h2 className="blue_text head_h2">We go the extra mile because we care about helping you buy the car of your dreams</h2>
            
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon.png" alt="" />
                <p className="font_18 poppins_font">
                Pick your dream car with your CARite specialist. The dealer will customize a program to build your down payment as you rent the car.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon2.png" alt="" />
                <p className="font_18 poppins_font">
                  Insurance & maintenance{" "}
                  <span className="d-block">all included during your</span>{" "}
                  rental to purchase period
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="whyRent">
                <img src="/images/rent_icon3.png" alt="" />
                <p className="font_18 poppins_font">
                  One-on-one credit counseling and credit remediation from the
                  national organization, CredEvolv, is included while you drive
                  and build up down payment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="contact_box position-relative">
                <div className="position-relative">
                  <h2 className="blue_text head_h2">Contact Us</h2>
                  <h3 className="font_22 black_text">
                    Special DriveItAway-CARite support
                  </h3>
                  <p className="font_18 black_text">
                    Live Chat through the web or mobile app
                  </p>
                  <p className="font_18 black_text">
                    <span className="pink_text">Hotline -</span> (203)-491-4283
                  </p>
                  <p className="font_18 black_text">
                    <span className="pink_text">Email -</span>{" "}
                    support@driveitaway.com
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-right">
              <img src="/images/contact.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <HomeAppBottom bgclass="bg-gray" />
    </div>
  );
}

export default Carite;
