import React, { Component } from "react";
import ContactForm from "../blocks/ContactForm";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import Title from "../templates/Title";
import HomeAppBottom from "../blocks/HomeAppBottom";
var Constants = require("../config/Constants");

class Contact extends Component {
  state = {
    config: {},
    errorsClasses: {},
    fields: {},
    errors: {},
    loading: true,
    message: "",
  };

  componentDidMount() {
    document.title = Constants.SITE_TITLE + " | Contact";
    window.scrollTo(0, 0);
  }
  renderMeta = () => {
    var metas = {
      metatitle: Constants.DEFAULT_PAGE_TITLE,
      metadescription: "DriveItAway",
    };
    return (
      <MetaTagsInfo
        metas={metas}
        pageTitle={Constants.DEFAULT_PAGE_TITLE}
        action="contactus"
      />
    );
  };

  render() {
    return (
      <section className="contact_sec contact_page">
        {this.renderMeta()}
        <Title title="Contact Us" />
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-xs-12">
              <img
                className="img-responsive"
                src="/images/DSCF1902_final.jpeg"
                alt=""
              />
            </div>
            <div className="col-sm-4 col-xs-12">
              <img
                className="img-responsive"
                src="/images/DSCF0121_final.jpeg"
                alt=""
              />
            </div>
            <div className="col-sm-4 col-xs-12">
              <img
                className="img-responsive"
                src="/images/DSCF1258_final.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="cont_form">
            <div className="row">
              <div className="col-sm-12 mt-5 mb-5">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <HomeAppBottom bgclass="bg-gray" />
      </section>
    );
  }
}
export default Contact;
