import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCarQuote, removeCarQuote } from "../store/actions/CarAction";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import HomeAppBottom from "../blocks/HomeAppBottom";
import {
  getUserSignature,
  getVehicleAgreement,
  bookVehicle,
} from "../services/CarService";
import { FixedLoader, SimpleLoader } from "../blocks/Loaders";
import {
  showIncomeModal,
  showCreditCardModal,
  showDrivingLicenseModal,
} from "../store/actions/GlobalActions";
import { VehicleQuoteErrorDialog, ErrorDialog } from "../blocks/Dialogs";
import {
  PrequalifyIncomeModal,
  CreditCardModal,
  DrivingLicenseModal,
} from "../blocks/Modals";

const ConfirmReservation = ({
  userDetails,
  userLoggedIn,
  rentTownCar,
  carQuote,
  getCarQuotes,
  unsetCarQuote,
  openIncomeModal,
  openCreditCardModal,
  openDrivingLicenseModal,
}) => {
  const link_container_ref = useRef();

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [userSignature, setUserSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFullLoader, setShowFullLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const showReserveBtn = useMemo(() => {
    if (userDetails) {
      if (!userDetails.is_driver || userDetails.is_driver === "0") return false;
      if (!userDetails.income_reported || userDetails.income_reported === "0")
        return false;
      if (!userDetails.is_renter || userDetails.is_renter === "0") return false;
      return true;
    } else return false;
  }, [userDetails]);

  useEffect(() => {
    setFormattedDate(moment(selectedDate._d).format("h:mm a - Do MMMM"));
  }, [selectedDate]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (rentTownCar) {
      handleDateChange(moment(new Date(rentTownCar.startdatetime)));
      getCarQuotes(rentTownCar);
    }
    return () => {
      unsetCarQuote();
    };
  }, [rentTownCar]);

  const confirmReservation = async () => {
    try {
      setShowFullLoader(true);
      const res = await bookVehicle(rentTownCar);
      setShowFullLoader(false);
      if (res && res.status === 200) {
        if (res.data && res.data.status === 1) {
          window.location.href = "/booking-success";
          return;
        }
        setErrorMsg(res.data.message);
        setError(true);
      } else {
        setErrorMsg("Sorry! Failed to book. please try later");
        setError(true);
      }
    } catch (err) {
      setShowFullLoader(false);
      setErrorMsg("Sorry! something went wrong");
      setError(true);
    }
  };

  const getSignature = async () => {
    try {
      setLoading(true);
      const res = await getUserSignature();
      setLoading(false);
      if (res && res.status === 200) {
        if (res.data && res.data.status === 1) {
          setUserSignature(res.data.result.file);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const downloadAgrement = async () => {
    try {
      setShowFullLoader(true);
      const data = { ...rentTownCar };
      data["startdatetime"] = selectedDate.toISOString();
      const res = await getVehicleAgreement(data);
      setShowFullLoader(false);
      if (res && res.status === 200) {
        if (res.data && res.data.status === 1) {
          if (link_container_ref.current) {
            const link = document.createElement("a");
            link.href = res.data.result.file;
            link.target = "_blank";
            link_container_ref.current.appendChild(link);
            link.click();
            link_container_ref.current.removeChild(link);
          }
        }
      }
    } catch (err) {
      setShowFullLoader(false);
      console.log(err);
    }
  };

  const clearErrors = () => {
    setError(false);
    setErrorMsg("");
  };

  const onPrequalifyResult = (message) => {
    setErrorMsg(message);
    setError(true);
  };

  if (!userLoggedIn || rentTownCar === null) {
    window.history.back();
  }

  return (
    <React.Fragment>
      <section className="buildP_sec">
        <div className="container">
          <h2 className="pink_text head_h2 buildP_head text-center small_h2">
            Confirm Reservation
          </h2>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                value={selectedDate}
                onChange={handleDateChange}
                open={openDatePicker}
                className="d-none"
                disablePast
                onAccept={() => setOpenDatePicker(!openDatePicker)}
                onClose={() => setOpenDatePicker(!openDatePicker)}
                format="hh:mm a mm yyyy"
              />
            </MuiPickersUtilsProvider>
            <div
              className="form-control d-flex align-items-center justify-content-between pick_calendar cursor-pointer"
              onClick={() => setOpenDatePicker(!openDatePicker)}
            >
              <span>
                <img src="/images/ic-actions-calendar.svg" alt="" />
                I’II Pickup at :
              </span>
              <span>{formattedDate}</span>
            </div>

            <section className="pricingbreak">
              <div className="container">
                <div className="row">
                  <div className="col-sm-2" />
                  <div className="col-sm-8">
                    <div className="due_box revfee">
                      <h3>${carQuote ? carQuote.buynowprice : 0.0}</h3>
                      <p>*Reservation Fee</p>
                    </div>
                  </div>
                  <div className="col-sm-2" />
                  <p className="ref_pol">
                    Refund Policy: All fees are fully refundable if you do NOT
                    DriveItAway. This reservation holds the vehicle and agreed
                    upon program for you to pick up at the dealership.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-sm-6">
            <section className="servasgn con_res pb-5">
              <div className="container">
                <div className="sign_box position-relative d-flex align-items-end justify-content-center">
                  <img
                    className="position-absolute cursor-pointer"
                    src="/images/down.png"
                    alt=""
                    onClick={getSignature}
                  />
                  {loading ? (
                    <SimpleLoader />
                  ) : userSignature ? (
                    <img
                      className="position-absolute"
                      src={userSignature}
                      alt=""
                      style={{ left: "45%", top: 35 }}
                    />
                  ) : (
                    <span>Signature here</span>
                  )}
                </div>
                <div className="agreement_div text-center">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input mr-2"
                      id="exampleCheck1"
                      value={acceptedTerms}
                      onChange={() => setAcceptedTerms(!acceptedTerms)}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      I have read the agree to the following{" "}
                    </label>
                    <Link
                      className="blue_text agreement"
                      to="#"
                      title=""
                      onClick={downloadAgrement}
                    >
                      Service Agreement
                    </Link>
                  </div>
                  <div className="row justify-content-center">
                    {(!userDetails.is_renter ||
                      userDetails.is_renter === "0") && (
                      <div className="col-lg-4 col-md-6 col-12 mb-2">
                        <button
                          className="btn-sm btn-warning py-2 px-3 w-100"
                          onClick={openCreditCardModal}
                        >
                          Add credit card detail
                        </button>
                      </div>
                    )}
                    {(!userDetails.income_reported ||
                      userDetails.income_reported === "0") && (
                      <div className="col-lg-4 col-md-6 col-12 mb-2">
                        <button
                          className="btn-sm btn-warning py-2 px-3 w-100"
                          onClick={openIncomeModal}
                        >
                          State Income
                        </button>
                      </div>
                    )}
                    {(!userDetails.is_driver ||
                      userDetails.is_driver === "0") && (
                      <div className="col-lg-4 col-md-6 col-12 mb-2">
                        <button
                          className="btn-sm btn-warning py-2 px-3 w-100"
                          onClick={openDrivingLicenseModal}
                        >
                          Add driver’s license
                        </button>
                      </div>
                    )}
                  </div>

                  {showReserveBtn && (
                    <button
                      className="btn"
                      disabled={!acceptedTerms}
                      onClick={confirmReservation}
                    >
                      Reserve
                    </button>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div ref={link_container_ref} />
      <HomeAppBottom bgclass="bg-gray" />
      <VehicleQuoteErrorDialog />
      {isError && <ErrorDialog message={errorMsg} onClose={clearErrors} />}
      {showFullLoader && <FixedLoader />}
      <PrequalifyIncomeModal onResult={onPrequalifyResult} />
      <CreditCardModal onResult={onPrequalifyResult} />
      <DrivingLicenseModal onResult={onPrequalifyResult} />
    </React.Fragment>
  );
};

const mapStateToPops = (state) => {
  const { rentTownCar, carQuote } = state.cars;
  const { userLoggedIn, userDetails } = state.auth;
  return { userLoggedIn, userDetails, rentTownCar, carQuote };
};

const mapDispatchToPops = (dispatch) => {
  return {
    getCarQuotes: (data) => dispatch(getCarQuote(data)),
    unsetCarQuote: () => dispatch(removeCarQuote()),
    openIncomeModal: () => dispatch(showIncomeModal()),
    openCreditCardModal: () => dispatch(showCreditCardModal()),
    openDrivingLicenseModal: () => dispatch(showDrivingLicenseModal()),
  };
};

export default connect(
  mapStateToPops,
  mapDispatchToPops
)(ConfirmReservation);
