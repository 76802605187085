import React,{Component} from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import Title from '../templates/Title';
import FAQ from '../blocks/FAQ';
import {Link} from 'react-router-dom';
var Constants = require("../config/Constants");

class Drivers extends Component{
	componentDidMount(){
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="drivers"/>
		)
	}	
	render(){
		return (
			<div>
				{this.renderMeta()}
				<Title title="Drivers" />
				<section className="product_sec">
					<div className="container">

						<div className="row">
							<div className="col-md-12">
								<p className="pb-2">If down payment, credit, or simply the idea of committing to a car are reasons standing between you
								and car ownership—you’ve come to the right place!</p>
								<p className="pb-2">DriveItAway is a drive before you buy program that requires no down payment or credit to begin. You
								pay for the use of the car and your payments will count towards your down payment if you decide to
								commit.</p>
								<p className="pb-2">Basically, you get to build a down payment while driving and deciding if the car is for you. </p>
								<p className="pb-2">Packages are available for regular use as well as rideshare/delivery.</p>
							</div>
						</div>
						<div className="row mb-5 mt-5">
							<div className="col-md-3 text-center">
							 <Link to="/contact-us" className="btn" title="">More info</Link>
							</div>
							<div className="col-md-3 text-center">
							 	<a href="https://itunes.apple.com/in/app/whip-car-sharing/id1393803514?mt=8" target="_blank" className="btn btn-big btn-pink app-btn" rel="noopener noreferrer"><i className="fa fa-apple"></i> App Store</a>
							</div>
							<div className="col-md-3 text-center">
							 	<a href="https://play.google.com/store/apps/details?id=com.carshare" target="_blank" className="btn btn-big btn-pink app-btn" rel="noopener noreferrer"><i className="fa fa-android"></i> Play Store</a>
							</div>
							<div className="col-md-3 text-center">
							 	<Link to="/account/prequalify" className="btn" title="">Get Prequalified</Link>
							</div>
						</div>
						<div className="text-center">
							<h3>Frequently Asked Questions</h3>
						</div>

						<div className="row">
							 <FAQ />
						</div>
					</div>		
				</section>
				<HomeAppBottom bgclass="bg-gray" />
			</div>
		)
	}
}
 export default Drivers;
 