import React, { useEffect, useMemo, useState } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

// const dateFns = new DateFnsAdapter();

const PayAsYouGo = ({ carDetail, goNext }) => {
  const [mileOptions, setMileOptions] = useState({});
  const [initialFeeOptions, setInitialFeeOptions] = useState([]);
  const [initialFeeOptionsReal, setInitialFeeOptionsReal] = useState([]);
  const [selectedMileOption, setSelectedMileOption] = useState(0);
  const [selectedInitialFee, setSelectedInitialFee] = useState(0);
  const [weeklyRate, setWeeklyRate] = useState(0);
  const [weeklyInsurance, setWeeklyInsurance] = useState(0);
  const [downPaymentCoupon, setDownPaymentCoupon] = useState(0);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState("");
  const [toogleClass, setToogleClass] = useState("fa pull-right fas fa-plus");

  const getMinimunMileOption = () => {
    return Object.keys(mileOptions).length > 0
      ? Object.keys(mileOptions)[0]
      : 0;
  };

  const getMaximunMileOption = () => {
    return Object.keys(mileOptions).length > 0
      ? Object.keys(mileOptions)[Object.keys(mileOptions).length - 1]
      : 0;
  };

  const getMinimunInitialFee = () => {
    return initialFeeOptions.length > 0 ? initialFeeOptions[0] : 0;
  };

  const getMaximunInitialFee = () => {
    return initialFeeOptions.length > 0
      ? initialFeeOptions[initialFeeOptions.length - 1]
      : 0;
  };
  
  const getRealMinimunInitialFee = () => {
    return initialFeeOptions.length > 0 ? initialFeeOptionsReal[initialFeeOptions[0]] : 0;
  };

  const getRealMaximunInitialFee = () => {
    return initialFeeOptions.length > 0
      ? initialFeeOptionsReal[initialFeeOptions[initialFeeOptions.length - 1]]
      : 0;
  };

  useEffect(() => {
    if (carDetail) {
      setMileOptions(carDetail.miles_options);
      setWeeklyRate(carDetail ? carDetail.buynowprice : 0);
      setDownPaymentCoupon(carDetail ? carDetail.buynowprice : 0);
      setInitialFeeOptionsReal(carDetail.initial_fee_options);
      setInitialFeeOptions(Object.keys(carDetail.initial_fee_options));
    }
  }, [carDetail]);

  useMemo(() => {
    setSelectedInitialFee(getMinimunInitialFee());
  }, [initialFeeOptions]);

  useMemo(() => {
    setSelectedMileOption(getMinimunMileOption());
  }, [mileOptions]);

  useMemo(() => {
    if (selectedMileOption) {
      setWeeklyInsurance(mileOptions[selectedMileOption].weekInsurance || 0);
    }
  }, [selectedMileOption]);

  const tire_rent = useMemo(() => {
    if (selectedInitialFee && selectedMileOption) {
      setWeeklyRate(
        carDetail.tier_rental[`${selectedInitialFee}X${selectedMileOption}`]
          .weekkEmfRent
      );
      setDownPaymentCoupon(
        carDetail.tier_rental[`${selectedInitialFee}X${selectedMileOption}`]
          .samevehiclecoupon
      );
      return carDetail.tier_rental[
        `${selectedInitialFee}X${selectedMileOption}`
      ].label;
    } else return "6 Months";
  }, [selectedMileOption, selectedInitialFee]);

  useMemo(() => {
    setFormattedDate(moment(selectedDate._d).format("h:mm a - Do MMMM"));
  }, [selectedDate]);

  const proceedNext = () => {
    const rent =
      carDetail.tier_rental[`${selectedInitialFee}X${selectedMileOption}`]
        .dayRent;
    const data = {
      list_id: carDetail.vehicle_id,
      startdatetime: selectedDate.toISOString(),
      days: 7,
      owner_id: carDetail.owner_id,
      rental_options: rent,
      initial_fee_options: selectedInitialFee,
      miles_options: selectedMileOption,
      emf_options: mileOptions[selectedMileOption].emf,
      program: "rideshare",
      financing: "pto",
    };
    goNext(data);
  };
  const getHighlights = () => {
    if (!carDetail.PTOHighlight.length) return null;
    return carDetail.PTOHighlight.map((obj, index) => {
      return (
        <li className="blue_text" key={"high-" + index}>
              <i className="fa fa-check fa-2x pink_text" aria-hidden="true" />
              <span>
                {obj.title}
              </span>
            </li>
      );
    });
  };
 
  const InitialFeeValue = useMemo(() => {
      if (selectedInitialFee) {
        return initialFeeOptionsReal[selectedInitialFee]?initialFeeOptionsReal[selectedInitialFee]:0;
      }
      return 0;
    }, [initialFeeOptionsReal, selectedInitialFee]);

  const cardClick = () => {
    setToogleClass(toogleClass=='fa pull-right fas fa-plus'?'fa pull-right fas fa-minus':'fa pull-right fas fa-plus');
  };

  return (
    <React.Fragment>
        <div className="month_sec highlight_box col-md6 pt-3 d-block d-sm-none">
          <div className="accordion md-accordion w-100" id="accordionEx1" role="tablist" aria-multiselectable="true">
            <div className="card-header w-100" role="tab" id="headingTwo1">
              <a className="collapsed w-100" data-toggle="collapse" data-parent="#accordionEx1" href="#collapseTwo1"
                aria-expanded="false" aria-controls="collapseTwo1" onClick={cardClick}>
                <h2 className="blue_text head_h2 small_h2 ">Highlights <i className={toogleClass}></i></h2>
              </a>
            </div>

            <div id="collapseTwo1" className="collapse" role="tabpanel" aria-labelledby="headingTwo1"
            data-parent="#accordionEx1">
                <ul>
                  {getHighlights()}
                </ul>
            </div>
            </div>
          </div>
      <div className="work_head pay_usage center_line text-center">
        <h2 className="white_text head_h2 small_h2">
          Pay for usage as you drive
        </h2>
        <div className="head_line" />
      </div>
      <div className="row">
        <div className="col-md-6">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              value={selectedDate}
              onChange={handleDateChange}
              open={openDatePicker}
              className="d-none"
              disablePast
              onAccept={() => setOpenDatePicker(!openDatePicker)}
              onClose={() => setOpenDatePicker(!openDatePicker)}
              format="hh:mm a mm yyyy"
            />
          </MuiPickersUtilsProvider>
          

          <h2 className="head_h2 line_head position-relative small_h2">
            How many miles do you drive per month?
          </h2>
          <div className="amount_box p-0 monthly_mile">
            <div className="range_slider text-center">
              <label className="m-0" htmlFor="customRange">
                <span className="initial_amount">Monthly Miles</span>
                <span className="pink_text head_h2 small_h2 monthlyMile">
                  {selectedMileOption}
                </span>
              </label>
              <input
                type="range"
                value={selectedMileOption}
                className="custom-range"
                min={getMinimunMileOption()}
                max={getMaximunMileOption()}
                id="customRange"
                onChange={(e) => setSelectedMileOption(e.target.value)}
                step={500}
              />
            </div>
            <div className="buy_now blue_text d-flex justify-content-between align-items-center">
              <span>{getMinimunMileOption()}</span>
              <span>{getMaximunMileOption()}</span>
            </div>
          </div>
          <h2 className="head_h2 line_head position-relative small_h2">
            You choose the amount to start?
          </h2>
          <div className="amount_box p-0 monthly_mile">
            <div className="range_slider text-center">
              <label className="m-0" htmlFor="customRange">
                <span className="initial_amount">Initial Fee</span>
                <span className="pink_text head_h2 small_h2 monthlyMile">
                  {InitialFeeValue}
                </span>
              </label>
              <input
                type="range"
                className="custom-range"
                min={getMinimunInitialFee()}
                max={getMaximunInitialFee()}
                id="customRange"
                step={100}
                value={selectedInitialFee}
                onChange={(e) => setSelectedInitialFee(e.target.value)}
              />
            </div>
            <div className="buy_now blue_text d-flex justify-content-between align-items-center">
              <span>{`${getRealMinimunInitialFee()}`}</span>
              <span>{`${getRealMaximunInitialFee()}`}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="rate_sec d-flex justify-content-between">
            <div className="weekly_rate d-flex justify-content-center mr-2 mr-xs-0">
              <div>
                <h4 className="blue_text">Weekly Rate</h4>
                <h3 className="blue_text m-0">
                  {weeklyRate} <small>Usage Rate</small>
                </h3>
              </div>
            </div>
            <div className="weekly_rate d-flex justify-content-center">
              <div>
                <h4 className="blue_text">Weekly Rate</h4>
                <h3 className="blue_text m-0">
                  {`${weeklyInsurance}`} <small>Insurance Rate</small>
                </h3>
              </div>
            </div>
          </div>
          <h2 className="head_h2 line_head position-relative small_h2">
            Receive Cash Back Rewards <i className="pink_text">With Each Payment</i>
          </h2>
          <div className="rate_sec d-flex justify-content-between">
            <div className="weekly_rate  justify-content-center text-center mr-xs-0">
              <div>
                <h5>Weekly</h5>
                <h3 className="pink_text m-2">
                {`${downPaymentCoupon}`} Down Payment Coupon
                </h3>
                <h5>Towards This Vehicle</h5>
              </div>
            </div>
            { /*
            <div className="weekly_rate d-flex justify-content-center">
              <div>
                <h5>Option to buy</h5>
                <h2 className="pink_text m-0">{tire_rent}</h2>
              </div>
            </div>
            */}
          </div>
          { /*
          
          <h2 className="head_h2 line_head position-relative small_h2">
            Usage Fees Build Down Payment
          </h2>
          <div className="rate_sec d-flex justify-content-between">
            <div className="weekly_rate d-flex justify-content-center mr-2 mr-xs-0">
              <div>
                <h5>Down payment built</h5>
                <h6 className="pink_text m-0">
                  {carDetail && carDetail.rent_opt_des
                    ? carDetail.rent_opt_des[0]
                    : "20% Down Payment Coupon"}
                </h6>
              </div>
            </div>
            <div className="weekly_rate d-flex justify-content-center">
              <div>
                <h5>Option to buy</h5>
                <h2 className="pink_text m-0">{tire_rent}</h2>
              </div>
            </div>
          </div>
          
         */ }
         <div
            className="form-control d-flex align-items-center justify-content-between pick_calendar
      cursor-pointer mb-3"
            onClick={() => setOpenDatePicker(!openDatePicker)}
          >
            <span>
              <img src="/images/ic-actions-calendar.svg" alt="" />
              I’II Pickup at :
            </span>
            <span>{formattedDate}</span>
          </div>

          <section className="month_sec mobilepart  pt-3 mb-3 d-block d-sm-none">
          <div className="text-center">
            <div className="accordion md-accordion w-100" id="accordionEx2" role="tablist" aria-multiselectable="true">
              <div className="card-header w-100" role="tab" id="headingTwo2">
                <a className="collapsed w-100" data-toggle="collapse" data-parent="#accordionEx2" href="#collapseTwo2"
                  aria-expanded="false" aria-controls="collapseTwo2" onClick={cardClick}>
                  <h2 className="blue_text head_h2 small_h2 ">After {tire_rent?tire_rent:(carDetail ? carDetail.rent_opt_title : "6 Months")} <i className={toogleClass}></i></h2>
                </a>
              </div>
              <div id="collapseTwo2" className="collapse" role="tabpanel" aria-labelledby="headingTwo2"
            data-parent="#accordionEx2">
            <div className="row">
              <div className="col-4 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                    
                    <p className="black_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[0]
                        : "20% Down Payment Coupon"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                    
                    <p className="black_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[1]
                        : "Continue Renting"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                   
                    <p className="black_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[2]
                        : "Return at Anytime"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </section>
          <button className="btn w-100" onClick={proceedNext}>
            Next
          </button>
        </div>
      </div>
      <div className="row">
        <section className="month_sec col-md-6 pt-3 d-none d-sm-block">
          <div className="container text-center">
            <h2 className="blue_text head_h2 small_h2">
            After {tire_rent?tire_rent:(carDetail ? carDetail.rent_opt_title : "6 Months")}
            </h2>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-6 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                    <img src="/images/month_icon.svg" alt="" />
                    <p className="white_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[0]
                        : "20% Down Payment Coupon"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-6 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                    <img src="/images/month_icon3.svg" alt="" />
                    <p className="white_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[1]
                        : "Continue Renting"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xs-6 pb-2">
                <div className="month_box d-flex align-items-center justify-content-center">
                  <div>
                    <img src="/images/month_icon2.svg" alt="" />
                    <p className="white_text">
                      {carDetail && carDetail.rent_opt_des
                        ? carDetail.rent_opt_des[2]
                        : "Return at Anytime"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="month_sec highlight_box col-md-6 pt-3 d-none d-sm-block">
          <h2 className="blue_text head_h2 small_h2 text-center">Highlights</h2>
          <ul>
            {getHighlights()}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PayAsYouGo;
