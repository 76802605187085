import React from "react";

const BookingSuccess = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ minHeight: 300 }}
    >
      <h3>Thank you for your booking!</h3>
      <p>we will contact you shortly.</p>
    </div>
  );
};

export default BookingSuccess;
