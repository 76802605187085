import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { Redirect } from "react-router";
import Loader from "../blocks/Loader";

class ContactForm extends Component {
  state = {
    config: {},
    errorsClasses: {},
    fields: {
      name: "",
      lname: "",
      phone: "",
      email: "",
      comment: "",
      zip: "",
      state: "",
      city: "",
      address: "",
      iam: "",
    },
    errors: {},
    loading: false,
    message: "",
    redirect: false,
  };
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    let errorsClasses = {};

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
      errorsClasses["name"] = "has-error";
    }
    //Last Name
    if (!fields["lname"]) {
      formIsValid = false;
      errors["lname"] = "Cannot be empty";
      errorsClasses["lname"] = "has-error";
    }

    //Address
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Cannot be empty";
      errorsClasses["address"] = "has-error";
    }
    //City
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "Cannot be empty";
      errorsClasses["city"] = "has-error";
    }

    //State
    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = "Cannot be empty";
      errorsClasses["state"] = "has-error";
    }
    //i am
    if (!fields["iam"]) {
      formIsValid = false;
      errors["iam"] = "Cannot be empty";
      errorsClasses["iam"] = "has-error";
    }
    //i am
    if (!fields["zip"]) {
      formIsValid = false;
      errors["zip"] = "Cannot be empty";
      errorsClasses["zip"] = "has-error";
    }
    //i am
    if (!fields["comment"]) {
      formIsValid = false;
      errors["comment"] = "Cannot be empty";
      errorsClasses["comment"] = "has-error";
    }

    //Phone
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Cannot be empty";
      errorsClasses["phone"] = "has-error";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["phone"] = "Invalid Phone number";
        errorsClasses["phone"] = "has-error";
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
      errorsClasses["email"] = "has-error";
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Invalid Email address";
        errorsClasses["email"] = "has-error";
      }
    }

    this.setState({ errors: errors, errorsClasses: errorsClasses });
    return formIsValid;
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loading: true });
      this.saveContact();
    }
  };
  saveContact = () => {
    var self = this;
    DiaAxios.post("contactFormPost", {
      data: self.state.fields,
    })
      .then(function(response) {
        if (response.data.redirect) {
          self.setState({ redirect: true });
        } else {
          self.setState({
            loading: false,
            message: response.data.result,
            fields: {
              name: "",
              lname: "",
              phone: "",
              email: "",
              comment: "",
              address: "",
              city: "",
              state: "",
              zip: "",
              iam: "",
            },
          });
        }
      })
      .catch(function(error) {
        self.setState({
          loading: false,
          message: "Something went wrong! Please try again.",
        });
        console.log(error);
      });
  };
  loader = () => {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return <span dangerouslySetInnerHTML={{ __html: this.state.message }} />;
    }
  };
  render() {
    const { redirect } = this.state;
    if (redirect) {
      window.hideModals();
      window.history.pushState(
        { urlPath: window.location.href },
        document.title,
        window.location.href
      );
      return <Redirect to="/thank-you" />;
    }
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="row">
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["name"] + " form-group"}>
              <input
                type="text"
                className="form-control"
                placeholder="First name*"
                onChange={this.handleChange.bind(this, "name")}
                value={this.state.fields["name"]}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["lname"] + " form-group"}>
              <input
                type="text"
                className="form-control"
                placeholder="Last name*"
                onChange={this.handleChange.bind(this, "lname")}
                value={this.state.fields["lname"]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["email"] + " form-group"}>
              <input
                type="email"
                className="form-control"
                placeholder="Email*"
                onChange={this.handleChange.bind(this, "email")}
                value={this.state.fields["email"]}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["phone"] + " form-group"}>
              <input
                type="text"
                className="form-control"
                placeholder="Phone*"
                onChange={this.handleChange.bind(this, "phone")}
                value={this.state.fields["phone"]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["iam"] + " form-group"}>
              <select
                className="form-control"
                onChange={this.handleChange.bind(this, "iam")}
              >
                <option value="">Select</option>
                <option value="0121U000000EnwW">Driver</option>
                <option value="0121U000000EnwR">Dealer</option>
                <option value="0121U000000Erzx">Other</option>
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className={this.state.errorsClasses["address"] + " form-group"}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Address*"
                onChange={this.handleChange.bind(this, "address")}
                value={this.state.fields["address"]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["city"] + " form-group"}>
              <input
                type="text"
                className="form-control"
                placeholder="City*"
                onChange={this.handleChange.bind(this, "city")}
                value={this.state.fields["city"]}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["state"] + " form-group"}>
              <select
                className="form-control"
                onChange={this.handleChange.bind(this, "state")}
              >
                <option value="">State</option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District of Columbia">
                  District of Columbia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
                <option value="Armed Forces Americas">
                  Armed Forces Americas
                </option>
                <option value="Armed Forces Europe">Armed Forces Europe</option>
                <option value="Armed Forces Pacific">
                  Armed Forces Pacific
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className={this.state.errorsClasses["zip"] + " form-group"}>
              <input
                type="text"
                className="form-control"
                placeholder="Postal*"
                onChange={this.handleChange.bind(this, "zip")}
                value={this.state.fields["zip"]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 form-group">
            <textarea
              placeholder="Message"
              className="form-control form-grou"
              rows="3"
              onChange={this.handleChange.bind(this, "comment")}
              value={this.state.fields["comment"]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="text-center">
              <button type="submit" className="btn btn-default plus_btn">
                Submit
              </button>
            </div>
            <h4 className="text-center text-white">{this.loader()}</h4>
          </div>
        </div>
      </form>
    );
  }
}
export default ContactForm;
