import React,{Component} from 'react';
import HomeAppBottom from '../blocks/HomeAppBottom';
import MetaTagsInfo from '../blocks/MetaTagsInfo';
import Title from '../templates/Title';
import {Link} from 'react-router-dom';
var Constants = require("../config/Constants");

class Regularuse extends Component{
	componentDidMount(){
		window.scrollTo(0, 0);
		document.title = Constants.DEFAULT_PAGE_TITLE;
	}
	renderMeta = () => {
		var metas = {
			metatitle: Constants.DEFAULT_PAGE_TITLE,
			metadescription: "DriveItAway"
		}
		return(
			<MetaTagsInfo metas={metas} pageTitle={Constants.DEFAULT_PAGE_TITLE} action="regularuse"/>
		)
	}	
	render(){
		return (
			<div>
				{this.renderMeta()}
				<Title title="Regular Use" />
				<section className="product_sec">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<p className="pb-2">Buying a car can be a stressful process. Is this the right car for me? What happens if it’s a lemon or I don’t want it?</p>
								<p className="pb-2">And, in many cases, laying out a hefty down payment upfront can make car buying stressful or unattainable—simply can’t afford it.</p>
								<p className="pb-2">Instead, use DriveItAway as a bridge to owning your car. Build your program with us today.</p>
								
								<p>1. Choose your dream car</p>
								<p>2. Choose your upfront and weekly payment structure</p>
								<p>3. Use your usage payments as potential equity to buy the car!</p>
								<p>4. Get offers from lenders along the way</p>
								<p className="pb-2">5. Commit to buying the car with your payments when it’s right for you!</p>
								<p className="pb-2">It’s truly that simple.</p>
							</div>
						</div>
						<div className="row mb-4 mt-4">
							<div className="col-md-12 text-center">
							 <Link to="/signin" className="btn" title="">Get Started</Link>
							 </div>
						</div>
					</div>
				</section>
				<HomeAppBottom bgclass="bg-gray" />
				
			</div>
		)
	}
}
 export default Regularuse;
