import React, { Component } from "react";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
// import FAQ from '../blocks/FAQ';
import Title from "../templates/Title";
import HomeAppBottom from "../blocks/HomeAppBottom";
var Constants = require("../config/Constants");

class Publications extends Component {
  componentDidMount() {
    document.title =
      Constants.SITE_TITLE + " | Publications, Blog & Industry Videos";
  }
  renderMeta = () => {
    var metas = {
      metatitle: Constants.DEFAULT_PAGE_TITLE,
      metadescription: "DriveItAway",
    };
    return (
      <MetaTagsInfo
        metas={metas}
        pageTitle={Constants.DEFAULT_PAGE_TITLE}
        action="publications"
      />
    );
  };

  render() {
    return (
      <div className="">
        {this.renderMeta()}
        <Title title="Publications, Blog & Industry Videos" />
        <div className="container mt-5 mb-5">
          <p>
            We “Wrote the Book” (or at least a lot of articles and industry
            presentations) on Dealer Focused Mobility as a Service
          </p>
          <p>
            <strong>Industry Association Videos –</strong>
            <br />
            <img
              className="alignnone size-full"
              src="/images/LeadershipandCompanyMissionPic.png"
              alt=""
              width="951"
              height="598"
              sizes="(max-width: 951px) 100vw, 951px"
            />
            <br />
            <a
              target="_blank"
              href="https://slideslive.com/38906705/de9r-mobility-as-a-service-a-threat-or-greatest-opportunity"
              rel="noopener noreferrer"
            >
              March 2018 NADA Convention Workshop – Mobility as a Service:
              Threat or Greatest Opportunity
            </a>
          </p>
          <p>&nbsp;</p>
          <h3>
            <strong>Published articles –</strong>
          </h3>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealers/four-ways-car-dealers-can-survive-thrive"
              rel="noopener noreferrer"
            >
              <strong>
                <u>Four Ways Car Dealers Can Survive, Thrive </u>
              </strong>
            </a>
          </p>
          <p>
            These times may prompt some dealers to venture into new strategies
            and tactics that make them stronger and more prosperous in the long
            run.
            <br />
            Wards Auto, Online July 13, 2020
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.autoremarketing.com/technology/podcast-driveitaway-ceo-accelerated-evolution-innovation?"
              rel="noopener noreferrer"
            >
              <strong>
                <u>
                  AutoRemarketing Podcast: DriveItAway CEO on ‘accelerate
                  evolution of innovation’{" "}
                </u>
              </strong>
            </a>
          </p>
          <p>
            Possumato talks about “an accelerated evolution of innovation”
            spurred by the business impact of COVID-19, his take on Carvana and
            Vroom, and more. Plus, he explores the advantages dealers may have
            over startups and disruptors. Published Online May 20, 2020
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.nxtbook.com/nxtbooks/crain/an9894756324SSFTL/index.php?CSAuthResp=1594058585006%3A0%3A327070%3A20957%3A24%3Asuccess%3A69C05911B336FDFE58CC3E6E407D7D8F&amp;CSAuthToken=327070%7C1594058885006%7CC46DF8A687A73D65F09948D734F74403#/p/14"
              rel="noopener noreferrer"
            >
              <strong>
                <u>A Rental Strategy to Weather the Times</u>
              </strong>
            </a>
          </p>
          <p>
            These frightening times may prompt some dealers to venture into new
            strategies and tactics that make them stronger and more prosperous
            in the long run. Published Online April 2020.
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.automotive-fleet.com/354975/new-methods-and-channels-of-remarketing-needed-now-more-than-ever"
              rel="noopener noreferrer"
            >
              <strong>
                <u>
                  New Methods and Channels of Remarketing Needed Now More Than
                  Ever
                </u>
              </strong>
            </a>
          </p>
          <p>
            The Pandemic is creating massive downward pressure on used vehicle
            residual value projections, new ways to remarket may help. Published
            in Automotive Fleet Magazine, April 2020.
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/industry-voices/car-dealers-should-lead-way-new-mobility"
              rel="noopener noreferrer"
            >
              <strong>
                <u>Car Dealers Should Lead Way to New Mobility</u>
              </strong>
            </a>
          </p>
          <p>
            NADA Chairman Rhett Ricart is right: “Dealers will be more than just
            fine” as the world changes. Wards Auto, Published Online March 3,
            2020
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealers/top-10-automotive-developments-watch"
              rel="noopener noreferrer"
            >
              <strong>
                <u>Top 10 Automotive Developments to Watch</u>
              </strong>
            </a>
          </p>
          <p>
            Some are safe bets. Others are educated predictions. All are
            noteworthy. Wards Auto, Published Online January 2020.
          </p>
          <p>&nbsp;</p>
          <p>
            <a
              target="_blank"
              href="https://www.automotive-fleet.com/342038/can-app-driven-vehicle-subscriptions-match-commercial-fleet-needs"
              rel="noopener noreferrer"
            >
              Finding Whether App Driven Vehicle Subscriptions Match Commercial
              Fleet Needs
            </a>
            <br />
            Is a Car or Truck Subscription Best Suited for the Fleet and
            Commercial Sales Side of the Automotive Business? Lets Look at the
            Fleet Vehicle Needs and Use Cases
            <br />
            Automotive Fleet Magazine, Online Oct 2019, in Print Nov 2019
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.autonews.com/commentary/disrupters-have-familiar-story-sell"
              rel="noopener noreferrer"
            >
              Market disrupters have a story to sell – and not much else
            </a>
            <br />
            Automotive News Opinion Page
            <br />
            Automotive News, Oct 2019
          </p>
          <p>
            <a
              target="_blank"
              href="http://digital.automotive-fleet.com/July2019#&amp;pageSet=20"
              rel="noopener noreferrer"
            >
              Everything Old is New Again
            </a>
            <br />
            With the Advent of Ride Sharing, There is a New Critical Need Afoot
            for the Old Days of Direct Fleet Service to End User Customers at
            the Dealership Level
            <br />
            Automotive Fleet Magazine, July 2019
          </p>
          <p>
            <a
              target="_blank"
              href="http://omagdigital.com/publication/?i=583647#{%22issue_id%22:583647,%22page%22:44}"
              rel="noopener noreferrer"
            >
              Upward Mobility
            </a>
            <br />
            The Rise of Ride Sharing Presents an Opportunity for Dealers to
            Create New Customers and Profits Today while Preparing for the
            Future
            <br />
            NIADA Used Car Dealer, May 2019
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.autonews.com/commentary/drivers-ride-hailing-services-can-be-prime-source-fixed-ops-profits"
              rel="noopener noreferrer"
            >
              Share the Wealth
            </a>
            <br />
            Drivers for ride-hailing services can be a prime source of fixed ops
            profit
            <br />
            Fixed Ops Journal/Automotive News, February 2019
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/industry-voices/are-car-dealers-thin-ice-or-lacing-speed-skates"
              rel="noopener noreferrer"
            >
              Are Car Dealers on Thin Ice or Lacing Up Speed Skates?
            </a>
            <br />
            We face two contrasting viewpoints. Which is to be believed?
            <br />
            WardsAuto, January 2019
          </p>
          <p>
            <a
              target="_blank"
              href="http://www.bhphinfo.com/flipbook_viewer?ds=hMZeqLo3V5faIWBGAswVYyip3luoMi5DIiykh9m97snnzKEjTNOuF2L4-ugY1Qg-HX5XEBTeAQvDFGuuOBb43j_wOtBx0QWBYxeyvRyzBKcdeeCiqgyvl_11dAGzXnKp"
              rel="noopener noreferrer"
            >
              Lyft Some Risk
            </a>
            <br />
            Leveraging Ride Sharing For Your Benefit
            <br />
            Buy Here Pay Here Dealer Magazine, October 2018
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealers/vehicle-subscription-services-car-sharing-another-name-rentals"
              rel="noopener noreferrer"
            >
              Vehicle Subscription Services, Car Sharing, by Another Name:
              Rentals
            </a>
            <br />
            Here’s a primer on all those different mobility services
            <br />
            WardsAuto, July 2018
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.fixedopsbusiness.com/articles/2056-mastering-mobility-as-a-service"
              rel="noopener noreferrer"
            >
              Q &amp; A: Mastering Mobility as a Service
            </a>
            <br />
            An Interview with John F. Possumato
            <br />
            Fixed, July 2018
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealer/notice-silicon-valley-car-dealers-can-adapt-too"
              rel="noopener noreferrer"
            >
              Notice to Silicon Valley: Car Dealers Can Adapt Too
            </a>
            <br />
            Why the smartest people in the room are wrong
            <br />
            WardsAuto, April 2018
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealer/why-car-dealers-should-supply-vehicles-uber-and-lyft-drivers"
              rel="noopener noreferrer"
            >
              Why Car Dealers Should Supply Vehicles to Uber and Lyft Drivers
            </a>
            <br />
            How could Uber’s attempt to lease vehicles to its drivers lose an
            incredible $9.000 per vehicle and shut down, despite overwhelming
            captive demand?
            <br />
            WardsAuto, November 2017
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.automotive-fleet.com/157635/how-mobility-as-a-service-will-change-fleet-management"
              rel="noopener noreferrer"
            >
              How Mobility as a Service Will Change Fleet Management
            </a>
            <br />
            Automotive Fleet Magazine, August 2017
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealer/potential-dealer-sales-pitch-car-will-make-you-bundle"
              rel="noopener noreferrer"
            >
              Potential Dealer Sales Pitch: “That Car Will Make You A Bundle”
            </a>
            <br />
            The idea of being able to convert a purchased vehicle into a
            cash-generating asset is an almost irresistible incentive to buy and
            upgrade
            <br />
            WardsAuto, August 2017
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.wardsauto.com/dealer/potential-dealer-sales-pitch-car-will-make-you-bundle"
              rel="noopener noreferrer"
            >
              Dealers Best-Suited to Handle MobilityServices
            </a>
            <br />
            Yes, new transportation providers have a head start, but dealers can
            provide the best of these services and at the lowest cost
            <br />
            WardsAuto, July 2017
          </p>
          <p>
            <strong>Webinars –</strong>
            <br />
            <img
              className="alignnone size-full"
              src="/images/companypublicationsblog_pic_webinarseries.png"
              alt=""
              width="1035"
              height="471"
              sizes="(max-width: 1035px) 100vw, 1035px"
            />
          </p>
          <p>
            <a
              target="_blank"
              href="https://marketing.nada.org/acton/media/4712/mobilityasaservice"
              rel="noopener noreferrer"
            >
              December 2018 NADA Webinar – How to Profit Today While Preparing
              for the Future
            </a>
            <br />
            <img
              className="alignnone size-full"
              src="/images/companypublicationsblog_pic_webinaraugust9.png"
              alt=""
              width="1154"
              height="657"
              sizes="(max-width: 1154px) 100vw, 1154px"
            />
          </p>
          <p>
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=64kkS1HA2K8&amp;feature=youtu.be"
              rel="noopener noreferrer"
            >
              August 2018 NIADA Webinar – Mobility as a Service: How You can
              Proit by Getting in the Game
            </a>
          </p>
        </div>
        <HomeAppBottom bgclass="bg-gray" />
      </div>
    );
  }
}
export default Publications;
