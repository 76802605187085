var Constants = {};
var token = "dqhJuH1ZI4ItIMOcDoH3Lf7YFWTpeC0j";
var appUrl = process.env.REACT_APP_API_URL; //"http://localhost/TAXITECH/DriveItAwayUpgrade"; // DEVELOPMENT

//var appUrl = "http://app.sylc-export.com/api/v1/index.php"; // STAGING
//var appUrl = "http://localhost/TAXITECH/DriveItAwayUpgrade"; // PRODUCTION

Constants.APP_URL = appUrl;
//Constants.API_ENDPOINT= appUrl + "/web_api/v1.0/?token="+token;
Constants.API_ENDPOINT = appUrl + "/web_api/v1.0/";
Constants.API_AUTH_TOKEN = token;

Constants.SITE_TITLE = "DriveItAway";

Constants.DEFAULT_PAGE_TITLE = "DriveItAway";
Constants.CURRENCY_CODE = "USD";
// Constants.CURRENCY_SYMBLE= "€";
Constants.PREFIX_CURRENCY_SYMBLE = "$";
Constants.CURRENCY_SYMBLE = "USD";

// Listing car minimum price
Constants.MINIMUM_LISTING_PRICE = 0;

// Listing car maximum price
Constants.MAXIMUM_LISTING_PRICE = 200000;

// Listing car price slider steps
Constants.PRICE_RANGE_STEPS = 500;

// Product Search form price dropdown min value
Constants.PRICE_RANGE_STEPS_DROPDOWN = 50000;

// Price Calculation Params
Constants.PRESTATION = 500;
Constants.TRANSPORT = 2000;
Constants.TRANSPORT_USA = 1400;
Constants.BANK = 260;
Constants.FRAIS = 980;
Constants.YEAR_CONDITION = 1986;
Constants.YEAR_CONDITION_LESS_MULTIPLY_BY = 1.055;
Constants.YEAR_CONDITION_MORE_MULTIPLY_BY = 1.1 * 1.2;

// Meta config
Constants.FAQ_PAGE_ID = 46;
Constants.PRIVACY_PAGE_ID = 13;
Constants.TERMS_PAGE_ID = 12;
Constants.LISTING_PAGE_ID = 12;
Constants.SYLC_LISTING_PAGE_ID = 12;
Constants.WISHLIST_PAGE_ID = 12;
Constants.VERSION = 11.15;
module.exports = Constants;
