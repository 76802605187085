import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";

// var Constants = require("../config/Constants");

class SearchForm extends Component {
  state = {
    filters: [],
    makesLoading: true,
    makes: [],
    selectedMake: null,
    modelsLoading: false,
    models: [],
    selectedModel: null,
    fromYears: [],
    selectedFromYear: null,
    toYears: [],
    selectedToYear: null,
    showSuggetionList: "none",
    loading: false,
    cityLoading: false,
    selectedCity: null,
    cities: [],
    selectedCarType: null,
    carTypes: [],
    intColors: [],
    colors: [],
    allmodels: [],
  };
  componentWillMount() {
    /*var d = new Date();
		var years = [];
		var year = d.getFullYear();
		for(var i=year;i >= 1900; i--){
			years.push({value: i, label:i})
		}
		this.setState({fromYears: years});*/
  }
  componentDidMount() {
    var searchList = {
      make: null,
      model: null,
      year: [],
      city: null,
      cab_type: null,
    };
    if (
      this.props.params !== undefined &&
      this.props.params.query !== undefined
    ) {
      var query = this.props.params.query;
      var search = query.split("&");
      search.forEach((s, i) => {
        var temp = s.split("=");
        searchList[temp[0]] = temp[1];
      });
    }

    this.setState({
      selectedCarType: searchList["cab_type"],
      selectedMake: searchList["make"],
      selectedModel: searchList["model"],
      selectedColor: searchList["color"] || null,
      year: {
        min: searchList["from_year"] ? searchList["from_year"] : null,
        max: searchList["to_year"] ? searchList["to_year"] : null,
      },
      selectedCity: searchList["city"],
    });

    this.getFilters();
  }
  

  componentDidUpdate() {}
  getFilters = () => {
    var self = this;
    DiaAxios.post("getVehicleFilters")
      .then(function(response) {
        //self.setState({cityLoading: false});
        if (response.data.status) {
          self.setState({
            filters: response.data.result,
          });

          self.sortFilters();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  sortFilters = () => {
    var self = this;
    self.setState({ cityLoading: false, makesLoading: false });
    var city = [],
      make = [],
      cab_type = [],
      // int_color = [],
      color = [],
      model = [],
      minyear = 2010;
    self.state.filters.forEach((m, indx) => {
      if (m.name === "city") {
        city = Object.entries(m.options);
      }
      if (m.name === "make") {
        make = Object.entries(m.options);
      }
      if (m.name === "cab_type") {
        cab_type = Object.entries(m.options);
      }
      // if (m.name === "int_color") {
      //   int_color = Object.entries(m.options);
      // }
      if (m.name === "color") {
        color = Object.entries(m.options);
      }
      if (m.name === "model") {
        model = Object.entries(m.options);
      }
      if (m.name === "model") {
        model = Object.entries(m.options);
      }
      if (m.name === "year") {
        minyear = m.options.min;
      }
    });

    var cities = [],
      makes = [],
      cab_types = [],
      int_colors = [],
      colors = [];
    city.forEach((val, i) => {
      if (i === 0 && !this.state.selectedCity) {
        this.setState({ selectedCity: { label: val[1], value: val[0] } });
      } else if (
        this.state.selectedCity &&
        val[0] === this.state.selectedCity
      ) {
        this.setState({ selectedCity: { label: val[1], value: val[0] } });
      }
      cities.push({ label: val[1], value: val[0] });
    });
    make.forEach((val, i) => {
      if (this.state.selectedMake && val[0] === this.state.selectedMake) {
        this.setState({ selectedMake: { label: val[1], value: val[0] } });
      }
      makes.push({ label: val[1], value: val[0] });
    });
    cab_type.forEach((val, i) => {
      if (this.state.selectedCarType && val[0] === this.state.selectedCarType) {
        this.setState({ selectedCarType: { label: val[1], value: val[0] } });
      }
      cab_types.push({ label: val[1], value: val[0] });
    });
    
    color.forEach((val, i) => {
      if (this.state.selectedColor && val[0] === this.state.selectedColor) {
        this.setState({ selectedColor: { label: val[1], value: val[0] } });
      }
      colors.push({ label: val[1], value: val[0] });
    });

    var d = new Date();
    var years = [];
    var year = d.getFullYear();
    for (var i = year; i >= minyear; i--) {
      if (this.state.year.min === i) {
        this.setState({ selectedFromYear: { value: i, label: i } });
      }
      years.push({ value: i, label: i });
    }
    this.setState({ fromYears: years });

    self.setState({
      cities: cities,
      makes: makes,
      allmodels: model,
      carTypes: cab_types,
      intColors: int_colors,
      colors: colors,
    });
    // console.log(this.props.params.query)
    if (this.props.params.query === undefined) {
      this.searchButton.click();
    } else {
      const items = this.props.params.query
        .split("&")
        .filter((el) => el.includes("city"));
      if (items && items.length > 0) {
        this.searchButton.click();
      }
    }
  };

  getModels = (make) => {
    var self = this;
    self.setState({ modelsLoading: true, models: [], selectedModel: null });
    if (make === null) {
      self.setState({ modelsLoading: false });
      return;
    }
    var modl = [],
      modls = [];
    self.state.allmodels.forEach((m, indx) => {
      if (m[0] === make.value) {
        modl = Object.entries(m[1]);
      }
    });
    modl.forEach((val, i) => {
      modls.push({ label: val[1], value: val[0] });
    });
    self.setState({ modelsLoading: false });
    self.setState({ models: modls });
  };
  citySelect = () => {
    return (
      <Select
        isLoading={this.state.cityLoading}
        value={this.state.selectedCity}
        onChange={this.handleCityChange}
        options={this.state.cities}
        placeholder="Select"
        resetValue={null}
        clearable={true}
        searchable={false}
        noResultsText={""}
      />
    );
  };
  makesSelect = () => {
    return (
      <Select
        isLoading={this.state.makesLoading}
        value={this.state.selectedMake}
        onChange={this.handleMakeChange}
        options={this.state.makes}
        placeholder="Select"
        resetValue={null}
        clearable={true}
        searchable={false}
        noResultsText={""}
      />
    );
  };

  handleMakeChange = (selectedMake) => {
    this.setState({ selectedMake });
    this.getModels(selectedMake);
  };

  modelsSelect = () => {
    return (
      <Select
        isLoading={this.state.modelsLoading}
        value={this.state.selectedModel}
        onChange={this.handleModelChange}
        options={this.state.models}
        placeholder="Select"
        clearable={true}
        searchable={false}
        noResultsText={"Select Make first"}
      />
    );
  };
  handleModelChange = (selectedModel) => {
    this.setState({ selectedModel });
  };
  fromYearsSelect = () => {
    return (
      <Select
        value={this.state.selectedFromYear}
        onChange={this.handleFromYearChange}
        options={this.state.fromYears}
        placeholder="Select"
        resetValue={null}
        clearable={true}
        searchable={false}
      />
    );
  };
  handleFromYearChange = (selectedFromYear) => {
    this.setState({ selectedFromYear });
    this.setState({ selectedToYear: null });
    var years = [];
    if (selectedFromYear !== null) {
      var d = new Date();
      var year = d.getFullYear();
      for (var i = year; i >= selectedFromYear.value; i--) {
        years.push({ value: i, label: i });
      }
    }
    this.setState({ toYears: years });
  };

  toYearsSelect = () => {
    return (
      <Select
        value={this.state.selectedToYear}
        onChange={this.handleToYearChange}
        options={this.state.toYears}
        placeholder="Select"
        clearable={true}
        searchable={false}
        noResultsText={"Select From Year"}
      />
    );
  };
  handleToYearChange = (selectedToYear) => {
    this.setState({ selectedToYear });
  };

  colorSelect = () => {
    return (
      <Select
        value={this.state.selectedColor}
        onChange={this.handleColorChange}
        options={this.state.colors}
        placeholder="Select"
        clearable={true}
        searchable={false}
      />
    );
  };
  handleColorChange = (selectedColor) => {
    this.setState({ selectedColor });
  };
  handleCityChange = (selectedCity) => {
    this.setState({ selectedCity });
    this.getModels(selectedCity);
  };

  getQuery = (exclude) => {
    var query = "";
    if (this.state.selectedCity && exclude !== "city") {
      query += "&city=" + this.state.selectedCity.value;
    }
    if (this.state.selectedMake && exclude !== "make") {
      query += "&make=" + this.state.selectedMake.value;
    }
    if (this.state.selectedModel && exclude !== "model") {
      query += "&model=" + this.state.selectedModel.value;
    }
    if (this.state.selectedFromYear && exclude !== "fromyear") {
      query += "&from_year=" + this.state.selectedFromYear.value;
    }
    if (this.state.selectedToYear && exclude !== "toyear") {
      query += "&to_year=" + this.state.selectedToYear.value;
    }
    if (this.state.selectedCarType && exclude !== "cab_type") {
      query += "&cab_type=" + this.state.selectedCarType.value;
    }
    if (this.state.selectedColor && exclude !== "color") {
      query += "&color=" + this.state.selectedColor.value;
    }
    if (exclude !== "") return query;
    return query.replace("&", "");
  };
  keyPressed = (e) => {
    if (e.keyCode !== 13) return;
    var query = this.getQuery("");
    var url = window.location.origin + "/listing/" + query;
    window.location.href = url;
  };
  render() {
    return (
      <div className="select_div location_form">
        <div className="row">
          <div className="col-md-3">
            <div className="search_div autocomplete">
              <div className="form-group">
                <label>City</label>
                <div className="customselect">{this.citySelect()}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-sm-6">
                <label>Make</label>
                <div className="customselect">{this.makesSelect()}</div>
              </div>
              <div className="col-sm-6">
                <label>Model</label>
                <div className="customselect">{this.modelsSelect()}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="row">
              <div className="col-sm-6">
                <label>Year From</label>
                <div className="customselect">{this.fromYearsSelect()}</div>
              </div>
              <div className="col-sm-6">
                <label>To</label>
                <div className="customselect">{this.toYearsSelect()}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <label>Color</label>
            <div className="customselect">{this.colorSelect()}</div>
          </div>
          <div className="col-sm-1 ">
            <label>&nbsp;</label>
            <Link to={"/cars/" + this.getQuery("")}>
              <button
                className="btn"
                ref={(searchButton) => (this.searchButton = searchButton)}
                type="button"
              >
                GO!
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default SearchForm;
