import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import Loader from "../blocks/Loader";

class FAQ extends Component {
  state = {
    faqs: {},
    categories: {},
    s_text: "",
    loading: true,
  };
  componentDidMount() {
    this.getBlockInfo();
    window.scrollTo(0, 0);
  }
  getBlockInfo = async () => {
    var self = this;

    DiaAxios.get("getFaq", {})
      .then(function(response) {
        if (response.data.status) {
          var faqs = response.data.result.faq;
          var categories = response.data.result.categories;

          self.setState({
            faqs: faqs,
            categories: categories,
          });
        }
        self.setState({ loading: false });
        window.resetFaqTab();
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  getFaqCategories = () => {
    if (!Object.keys(this.state.categories).length) return null;
    return this.state.categories.map((obj, index) => {
      return (
        <li key={"cat-" + index} className={index === 0 ? "active " : ""}>
          <a
            href={"#category" + obj.id}
            data-toggle="tab"
            dangerouslySetInnerHTML={{ __html: obj.category_name }}
            className="btn ml-3 mr-3"
          />
        </li>
      );
    });
  };

  listFaq = (category) => {
    let cat = category;
    let firstFaq = true;

    if (this.state.loading) return <Loader />;

    if (!Object.keys(this.state.faqs).length)
      return <h3 className="text-center">No Faq Found.</h3>;
    var arr = this.state.faqs.filter((o) => {
      return cat === o.category_id;
    });
    if (!arr.length) return <h3 className="text-center">No Faq Found</h3>;
    return this.state.faqs.map((obj, index) => {
      if (cat !== obj.category_id) return null;
      var openClass = firstFaq ? " show " : "";
      firstFaq = false;
      return (
        <div className="card" key={"card-" + index}>
          <div className="card-header p-2" id={"heading" + obj.id}>
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target={"#collapse" + obj.id}
                aria-expanded="true"
                aria-controls={"collapse" + obj.id}
              >
                Q: {obj.question}
              </button>
            </h5>
          </div>

          <div
            id={"collapse" + obj.id}
            className={"panel-collapse collapse " + openClass}
            aria-labelledby={"heading" + obj.id}
            data-parent="#faqExample"
          >
            <div
              className="card-body"
              dangerouslySetInnerHTML={{ __html: obj.answer }}
            />
          </div>
        </div>
      );
    });
  };

  keyPressed = (e) => {
    if (e.keyCode !== 13) return;
    this.setState({ faqs: {}, loading: true, categories: {} });
    this.getBlockInfo();
  };
  render() {
    return (
      <section className="Product_tabs faq-page-tab" id="faqExample">
        <div className="container">
          <div className="text-center mb-4">
            <ul
              id="myTabs"
              className="nav nav-pills nav-justified justify-content-center"
              role="tablist"
              data-tabs="tabs"
            >
              {this.getFaqCategories()}
            </ul>
          </div>
          <div className="tab-content faqlist">
            <div
              role="tabpanel"
              className="tab-pane fade in active show"
              id="category1"
            >
              <div className="accordion_demo">
                <div
                  className="panel-group"
                  id="accordion-cat-1"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {this.listFaq(1)}
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane fade" id="category2">
              <div className="accordion_demo">
                <div
                  className="panel-group"
                  id="accordion-cat-2"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {this.listFaq(2)}
                </div>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane fade" id="category3">
              <div className="accordion_demo">
                <div
                  className="panel-group"
                  id="accordion-cat-3"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {this.listFaq(3)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default FAQ;
