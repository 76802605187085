import React, { Component } from "react";
import HomeAppBottom from "../blocks/HomeAppBottom";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import Title from "../templates/Title";
var Constants = require("../config/Constants");

class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = Constants.DEFAULT_PAGE_TITLE;
  }
  renderMeta = () => {
    var metas = {
      metatitle: Constants.DEFAULT_PAGE_TITLE,
      metadescription: "DriveItAway",
    };
    return (
      <MetaTagsInfo
        metas={metas}
        pageTitle={Constants.DEFAULT_PAGE_TITLE}
        action="aboutus"
      />
    );
  };
  render() {
    return (
      <div>
        {this.renderMeta()}
        <Title title="Company" />
        <section className="product_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="pb-2">
                  DriveItAway was created to satisfy needs identified by long
                  time automotive retailing veterans.
                </p>
                <p className="pb-2">
                  First, DriveItAway enables Lyft and Uber drivers with the
                  ability to use ride share driving as a means to own a new or
                  used vehicle, without being locked into an endless cycle of
                  high cost temporary rentals or short term leases.
                </p>
                <p className="pb-2">
                  Second, DriveItAway enables franchise and independent car
                  dealers to quickly and profitably enter the rapidly emerging
                  Mobility as a Service business (car sharing). Our turn-key
                  program that includes a customized software and hardware
                  platform, insurance, and training will lead to more vehicle
                  sales. A first in the industry, DriveItAway represents a win
                  for all parties!
                </p>
                <p className="pb-2">Join Us Today.</p>
              </div>
            </div>
          </div>
        </section>
        <HomeAppBottom bgclass="bg-gray" />
      </div>
    );
  }
}
export default AboutUs;
