import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideDownloadAppModal } from "../../store/actions/GlobalActions";
const DownloadAppDialog = () => {
  const dispatch = useDispatch();

  const { showDownloadApp } = useSelector((state) => {
    return {
      showDownloadApp: state.global.showDownloadApp,
    };
  });
  if(!showDownloadApp) return null;
  return (<>
    
    <div className="dialog-wrapper">
      <div className="align-items-center justify-content-center dialog-downloadpp-container position-relative">
        <button
          type="button"
          className="modal-close-btn close"
          onClick={()=>{dispatch(hideDownloadAppModal())}}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="dialog-body py-3 px-3">
          
            <div className="w-100 pull-left border-bottom mb-4 mt-2 text-center">
						  <h5 className="modal-title text-secondary pb-4 text-center" >Download our <strong><em>NEW</em></strong> Mobile App</h5>
						</div>
						<p className="mb-3 mb-4 mt-2">Browse available vehicles from wherever you are. Find your car, build your subscription, and drive with just a few taps.</p>
						<div className="row g-3 app-buttons mb-4 mt-2">
							<div className="col-6">
								<a href="https://apps.apple.com/us/app/driveitaway/id1393803514" target="_blank" rel="noreferrer noopener" title="Download on the App Store">
                <img src="/images/newui/app-store.png" alt="" />
								</a>
							</div>
							<div className="col-6">
								<a href="https://play.google.com/store/apps/details?id=com.carshare" target="_blank" rel="noreferrer noopener" title="Get it on Google Play">
                <img src="/images/newui/play-store.png" alt="" />
								</a>
							</div>
						</div>
         
        </div>
      </div>
    </div>
    </>
  );
};

export default DownloadAppDialog;
