import React, { Component } from "react";
import "react-input-range/lib/css/index.css";

class ListingCarFilters extends Component {
  state = {
    filters: this.props.filters,
    search_records: null,
  };

  componentDidMount = () => {
    // window.initPriceRangeSlider();
  };

  handleSort = (event) => {
    this.props.updateFilter(event);
  };

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({
      filters: nextProps.filters,
      search_records: nextProps.search_records,
    });
  }

  recordsCount = () => {
    if (this.props.search_records)
      return <span>{this.props.search_records}</span>;
    return 0;
  };

  render() {
    return (
      <div className="sort_box">
        <div className="row">
          <div className="col-md-3">
            <label className="textblue">{this.recordsCount()} Cars Found</label>
          </div>
          <div className="col-md-9">
            <form className="form-inline  w-15 justify-content-end pull-right">
              <label className="mr-2">Show</label>
              <select
                id="limit"
                value={this.state.filters.limit}
                onChange={this.handleSort.bind(this)}
                className="form-control"
              >
                <option value="8"> 8</option>
                <option value="16"> 16</option>
                <option value="32"> 32</option>
                <option value="64"> 64</option>
                <option value="100"> 100</option>
              </select>
              {/*<button type="button" className="sort_btn active">
                <i className="fa fa-th-large" aria-hidden="true" />
              </button>
              <button type="button" className="sort_btn">
                <i className="fa fa-bars" aria-hidden="true" />
              </button>*/}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ListingCarFilters;
