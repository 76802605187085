import React, { Component } from "react";
import DiaAxios from "../config/DiaAxios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ListingCarFilters from "../blocks/ListingCarFilters";
import Pagination from "../blocks/Pagination";
import IframeSearchForm from "../blocks/IframeSearchForm";
import MetaTagsInfo from "../blocks/MetaTagsInfo";
import { FixedLoader } from "../blocks/Loaders";
import {showDownloadAppModal} from "../store/actions/GlobalActions";
import { store } from "../store/store";
var Constants = require("../config/Constants");

class IframeCarListing extends Component {
  state = {
    cars: {},
    filters: { city: null, limit: 16, orderby: "id" },
    loading: true,
    pagination: 1,
    pages: 1,
    make: null,
    model: null,
    from_year: null,
    to_year: null,
    city: null,
    cab_type: null,
    search_records: null,
    color: null,
    message: "",
    isUserLoggedIn: this.props.isUserLoggedIn,
    showDownloadApp:false
  };
  componentWillMount() {
    //if(this.props.params.query === undefined) return;
    var query = ""; // this.props.params.query;
    var search = query.split("&");
    var searchList = {
      make: null,
      model: null,
      from_year: null,
      to_year: null,
      city: null,
      lat: null,
      lng:null,
      page: 1,
      cab_type: null,
      color: null,
    };
    search.forEach((s, i) => {
      var temp = s.split("=");
      searchList[temp[0]] = temp[1];
    });
    var filters = this.state.filters;

    if (searchList["orderby"] === "random") {
      filters["orderby"] = "random";
    }
    this.setState({
      make: searchList["make"],
      model: searchList["model"],
      from_year: searchList["from_year"],
      to_year: searchList["to_year"],
      city: searchList["city"],
      lat: searchList["lat"],
      lng: searchList["lng"],
      pagination: searchList["page"],
      cab_type: searchList["cab_type"],
      color: searchList["color"],
    });
  }

  caching= ()=> {
    let version = localStorage.getItem('diaversion');
        if(version!=Constants.VERSION)
        {
            if('caches' in window){
                    caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                      console.log(name);
                        caches.delete(name);
                    })
                });
            
                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
    
          localStorage.clear();
          localStorage.setItem('diaversion',Constants.VERSION);
        }
    };
  componentDidMount() {
    document.title = Constants.SITE_TITLE;
    // this.getCars();
    this.caching();
  }
  componentDidUpdate(prevProps, prevState) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (this.state.loading && !Object.keys(this.state.cars).length)
      this.getCars();
  }
  componentWillReceiveProps(nextProps, prevState) {
    var searchList = {
      make: null,
      model: null,
      from_year: null,
      to_year: null,
      city: null,
      page: 1,
      cab_type: null,
      color: null,
    };
    if (
      nextProps.params !== undefined &&
      nextProps.params.query !== undefined
    ) {
      var query = nextProps.params.query;
      var search = query.split("&");
      search.forEach(function(s, i) {
        var temp = s.split("=");
        searchList[temp[0]] = temp[1];
      });
    }
    var filters = this.state.filters;

    this.setState({
      make: searchList["make"],
      model: searchList["model"],
      from_year: searchList["from_year"],
      to_year: searchList["to_year"],
      city: searchList["city"],
      lat: searchList["lat"],
      lng: searchList["lng"],
      filters: filters,
      loading: true,
      cars: {},
      pagination: searchList["page"],
      cab_type: searchList["cab_type"],
      color: searchList["color"],
      accord: "fa pull-right fas fa-plus",
    });
  }

  getCars = async () => {
    var self = this;
    // Get Cars
    var url = "searchPreAuthVehicles";
    if (this.state.isUserLoggedIn) {
      url = "searchVehicles";
    }
    DiaAxios.post(url, {
      page: self.state.pagination,
      orderby: self.state.filters.orderby,
      limit: self.state.filters.limit,
      totol_count: true,
      pagination: self.state.pagination,
      year: { min: self.state.from_year, max: self.state.to_year },
      make: self.state.make,
      model: self.state.model,
      from_year: self.state.from_year,
      to_year: self.state.to_year,
      city: self.state.city,
      lat: self.state.lat,
      lng: self.state.lng,
      cab_type: self.state.cab_type,
      color: self.state.color,
    })
      .then(function(response) {
        var search_records = null,
          cars = null,
          pages = null;
        var limit = self.state.filters.limit;
        if (response.data.status) {
          if (
            self.props.params.query &&
            self.props.params.query !== undefined
          ) {
            if (
              self.props.params.query.indexOf("city") > -1 ||
              self.props.params.query.indexOf("make") > -1 ||
              self.props.params.query.indexOf("model") > -1 ||
              self.props.params.query.indexOf("from_year") > -1 ||
              self.props.params.query.indexOf("to_year") > -1 ||
              self.props.params.query.indexOf("price_range") > -1
            )
              search_records = response.data.records;
          }
          search_records = response.data.records;
          cars = response.data.result;
          pages = Math.ceil(response.data.records / limit);
        } else {
          search_records = null;
          cars = [];
          pages = 0;
        }
        self.setState({
          message: response.data.message,
          search_records: search_records,
          cars: cars,
          pages: pages,
          loading: false,
        });
      })
      .catch(function(error) {
        self.setState({
          loading: false,
        });
        console.log(error);
      });
  };

 




  featuredText = (featured_text) => {
    return featured_text.map((ob, index) => {
      return <li key={"fet=" + index} className="d-flex"><i className="fa fa-check-circle" aria-hidden="true"></i> {ob}</li>;
    });
  };
  formatPrice = (price) => {
    return Constants.PREFIX_CURRENCY_SYMBLE + price; //new Intl.NumberFormat('en-US', { syle: 'decimal'}).format(price);
  };
  calculatePrice = (car) => {
    return Math.ceil(car.msrp);
  };
  

  renderCars = () => {
    // var self = this;
    if (this.state.loading) return <FixedLoader />;
    if (!Object.keys(this.state.cars).length)
      return <h3 className="text-center">{this.state.message}</h3>;
    let cars = [];
    let row = [];
    let count = 1;
    this.state.cars.forEach((obj, index) => {
      row.push(
        <div
          key={"car-" + index}
          className="col-sm-6 col-sx-12 col-lg-4 col-md-6">
          <div className="car_box">
            <div className="car_list">
              <div className=" w-100 pull-left ilargetext textblue text-truncate">
                {obj.year} {obj.make}
              </div>
              <div className="d-block-inline w-100 mt-2">
                <div className="w-75 pull-left ilargetext font-weight-bold textblue text-truncate">
                  {obj.model}
                </div>
                <div className="w-25 pull-right textblue locationtext text-right">
                  <i className="fa fa-2x fa-map-marker textblue location-icon" />
                  {obj.city} {obj.state}
                </div>
              </div>
            </div>
            <div className="car_img text-center position-relative">
              <Link
                to={
                  "/iframecars/details/" +
                  obj.id +
                  "/" +
                  obj.vehicle_name
                    .split(" ")
                    .join("-")
                    .toLowerCase()
                }
                className="linktodetails">
                <span
                  className="litsing-car-image"
                  style={{ background: `url(${obj.image}) #cccccc` }}
                />
              </Link>
            </div>
            {/*<div className="car_list">
              <ul className="list-unstyled d-inline-flex featuredtext">
                {this.featuredText(obj.featured_text)}
              </ul>
              <br />
              </div>*/}
            <div className="car_price d-flex justify-content-between pl-0 pr-0">
              <div className="col-sm-6 priceborderright pl-0">
                <span>{obj.rent_label}</span>
                <h5 className="">
                  <sup>$</sup>
                  {obj.rent.match(/[0-9,.]+/)}
                </h5>
                <span>per day</span>
              </div>
              <div className="col-sm-6 carlistattrblock pr-0">
                <dd className="w-100 pull-left">
                  <span className="pull-left font-weight-bold">
                    Engine:
                  </span>
                  <span className="pull-right text-truncate">
                    {obj.engine ? obj.engine : "N/A"}
                  </span>
                </dd>
                <dd className="w-100 pull-left">
                  <span className="pull-left font-weight-bold">
                    Mileage:
                  </span>
                  <span className="pull-right text-truncate">
                    {obj.odometer ? obj.odometer : "N/A"}
                  </span>
                </dd>
                <dd className="w-100 pull-left">
                  <span className="pull-left font-weight-bold">
                    Body:
                  </span>
                  <span className="pull-right text-truncate">
                    {obj.body ? obj.body : "N/A"}
                  </span>
                </dd>
                <dd className="w-100 pull-left">
                  <span className="pull-left font-weight-bold">
                    Color:
                  </span>
                  <span
                    style={{ width: "40px" }}
                    className="pull-right text-truncate">
                    {obj.color ? obj.color : "N/A"}
                  </span>
                </dd>
              </div>
            </div>
            <div className="main-btns d-flex justify-content-between">
              <button
                className="btn btn-downloadapp"
                onClick={() => {
                  store.dispatch(showDownloadAppModal());
                }}>
                Download App To Book
              </button>
              <Link
                className="btn btn-findoutmore"
                to={
                  "/iframecars/details/" +
                  obj.id +
                  "/" +
                  obj.vehicle_name
                    .split(" ")
                    .join("-")
                    .toLowerCase()
                }>
                Find Out More
              </Link>
            </div>
          </div>
        </div>
      );
      // if (count === 3) {
      //   cars.push(this.formatCarRow(row, index));
      //   row = [];
      //   count = 0;
      // }
      count++;
    });
    // if (count > 1) {
    //   cars.push(this.formatCarRow(row));
    // }
    cars.push(this.formatCarRow(row));
    return cars;
  };
  formatCarRow = (row, index) => {
    return (
      <div key={"car-row-" + index} className={"row displayFlex"}>
        {row}
      </div>
    );
  };

  updatePagination = (pagination) => {
    var self = this;
    var params = this.getUrl(pagination);
    window.history.pushState({ urlPath: params }, document.title, params);
    self.setState({
      pagination: pagination,
      cars: {},
      loading: true,
    });
    window.scrollTo(0, 150);
  };

  getUrl = (page) => {
    var url = window.location.href.split("/");
    var lastParam = url[url.length - 1];
    var params = "";
    if (lastParam.indexOf("=") > -1) {
      url.splice(url.length - 1, 1);
      if (lastParam.indexOf("page") > -1) {
        var arr = lastParam.split("&");
        params = window.location.href.replace(
          arr[arr.length - 1],
          "page=" + page
        );
      } else {
        params = window.location.href + "&page=" + page;
      }
    } else {
      params = window.location.href + "/page=" + page;
    }
    return params;
  };
  updateFilter = (event) => {
    var filters = this.state.filters;
    filters[event.target.id] = event.target.value;
    this.setState({ filters: filters, loading: true, pagination: 1, cars: {} });
  };

  toggleClass = () => {
		this.setState({accord: this.state.accord=='fa pull-right fas fa-plus'?'fa pull-right fas fa-minus':'fa pull-right fas fa-plus'});	
	}
  renderPagination = () => {
    if (this.state.pages < 2) return null;
    return (
      <Pagination
        records={this.state.search_records}
        limit={this.state.filters.limit}
        pages={this.state.pages}
        currentPage={this.state.pagination}
        updatePagination={this.updatePagination}
      />
    );
  };
  render() {
    return (
      <div className="mt-3">
        <section className="search_sec">
          <MetaTagsInfo
            pageTitle="Find Your Dream Car | DriveItAway"
            action="getPageMetas"
            id={Constants.LISTING_PAGE_ID}
          />
          <div className="container-fluid searchtop pb-3 pb-sm-0">
            <div className="container">
              <div
                className="accordion md-accordion w-100"
                id="accordionEx3"
                role="tablist"
                aria-multiselectable="true">
                <div
                  className="w-100  d-block d-sm-none card-header"
                  role="tab"
                  id="headingTwo3">
                  <a
                    className="collapsed w-100"
                    data-toggle="collapse"
                    data-parent="#accordionEx3"
                    href="#collapseTwo3"
                    aria-expanded="false"
                    aria-controls="collapseTwo3"
                    onClick={() => this.toggleClass()}>
                    <h2 className="blue_text head_h2 small_h2 ">
                      Filter <i className={this.state.accord} />
                    </h2>
                  </a>
                </div>
                <div
                  id="collapseTwo3"
                  className="collapse d-none d-sm-block"
                  role="tabpanel"
                  aria-labelledby="headingTwo3"
                  data-parent="#accordionEx3">
                  <div className="select_sec">
                    <IframeSearchForm params={this.props.params} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid ibgblueimg">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-lg-3 d-flex pl-md-0 mb-md-0 mb-2">
                  <img src="/images/newui/icon-1.svg" className="Cancelimg" />
                  <p>
                    Cancel anytime or convert to ownership when it’s right
                    for you!
                  </p>
                </div>
                <div className="col-sm-6 col-lg-3 d-flex mb-md-0 mb-2">
                  <img src="/images/newui/icon-2.svg" />
                  <p className="newuiml">
                    Insurance, full maintenance & roadside assistance
                    included.
                  </p>
                </div>
                <div className="col-sm-6 col-lg-3 d-flex mb-md-0 mb-2 pl-sm-0">
                  <img src="/images/newui/icon-3.svg" />
                  <p>Drive now, decide later whether you want to buy.</p>
                </div>
                <div className="col-sm-6 col-lg-3 d-flex pr-md-0 mb-md-0 mb-2">
                  <img src="/images/newui/icon-4.svg" />
                  <p>
                    All usage fees can contribute to a down payment if you
                    choose to buy the car
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="sort_box">
              <ListingCarFilters
                search_records={this.state.search_records}
                updateFilter={this.updateFilter}
                pages={this.state.pages}
                currentPage={this.state.pagination}
                updatePagination={this.updatePagination}
                filters={this.state.filters}
              />
            </div>
            <div className="car_sec">{this.renderCars()}</div>
          </div>
          <div className="container">
            <div className="page_next">{this.renderPagination()}</div>
          </div>
          <div className="container">
            <h2>Here&rsquo;s How It Works</h2>

            <p>
              Buying a car should be simple. You see a car you like, you
              drive it, and you buy it when (or if) you&rsquo;re ready. We
              bring this simplicity to life with our Drive it. Want it. Buy
              it.
            </p>
            <p>Process:</p>

            <ol>
              <li>Download the App</li>
              <li>Select Your Car</li>
              <li>Create Your Subscription</li>
              <li>Drive Your Car</li>
              <li>Grow Down Payment</li>
              <li>Purchase, Keep Driving, or Return</li>
            </ol>

            <p>
              <button
                className="btn pricing_btn mt-3 bgblue"
                onClick={() => {
                  store.dispatch(showDownloadAppModal());
                }}>
                Get Started
              </button>
            </p>

            <h2>Drive Now, Decide Later</h2>

            <p>
              Flexibility is the future of car buying. With DriveItAway, you
              choose what you drive, how long you drive it, and when to buy
              it or return it. Start your subscription by choosing your car.
              We&rsquo;ll walk you through the rest of the process.
              We&rsquo;ll even include all maintenance, insurance, and a
              full warranty with every vehicle. You can drive a car with
              DriveItAway and know that everything is taken care
              of&mdash;you just should focus on if you love the drive.&nbsp;
            </p>

            <p>
              <em>Psst&hellip; it&rsquo;s even easier on the app! </em>😉
            </p>

            <h2>
              Buy a Car With No Down Payment&mdash;Build $ While You Drive
            </h2>

            <p>
              At DriveItAway, we make buying a car accessible. Need a car
              but haven&rsquo;t built a down payment yet? We&rsquo;ve got
              the solution.
            </p>

            <p>
              With our subscribe to own program, a percentage of your usage
              fees build dollars toward a future down payment if you decide
              to purchase the vehicle later on. You can build your down
              payment at your own pace. You&rsquo;ll receive financing
              offers as you go that are perfectly curated for you. You can
              purchase your vehicle when it&rsquo;s right for you.
            </p>

            <h2>
              Try an Electric Vehicle With No Commitment or Hefty Upfront
              Payments
            </h2>

            <p>
              Who isn&rsquo;t a little curious about EVs? We say, give it a
              try! We stock a variety of popular EVs that are eco-friendly
              and wallet-friendly (goodbye gas guzzlers!). Drive it for a
              while&mdash;you may be surprised just how well it suits your
              lifestyle. If you love it, buy it! If not, return it. Talk
              about a win-win!
            </p>

            <h2>Buy a Car, No Credit Score Required</h2>

            <p>
              Barriers to entry are a thing of the past. We believe that
              everyone should be able to buy a car, even if they&rsquo;re
              still working to build their credit. That&rsquo;s why we take
              a new approach. DriveItAway uses other metrics to determine
              eligibility, including income and reputation.&nbsp;
            </p>

            <h2>Drive With DriveItAway Today</h2>

            <p>
              Are you ready to begin driving with DriveItAway? Check out a
              sampling of the vehicles currently available in your area.
              Don&rsquo;t see your area on the list? We&rsquo;re adding more
              locations every day! If you have questions about any of the
              cars in our inventory, speak to our automotive
              enthusiasts.&nbsp;
            </p>

            <p>
              Download the app to claim a vehicle and begin your DriveItAway
              subscription today!
            </p>

            <p>&nbsp;</p>

            <p>
              <button
                className="btn pricing_btn mt-3 bgblue"
                onClick={() => {
                  store.dispatch(showDownloadAppModal());
                }}>
                Download the app
              </button>
            </p>
          </div>
        </section>
      </div>
    );
  }
}



export default connect(
)(IframeCarListing);
